/* eslint-disable react/prop-types */
import React from 'react';
import { Fragment } from 'react';

import { createGlobalStyle } from 'styled-components';

export default function Primary() {
  // const [wrap, setWrap] = React.useState(null);

  React.useEffect(() => {
    const btnPrint = document.querySelector('#btn-print');
    btnPrint.addEventListener('click', function () {
      window.print();
    });

    const products = JSON.parse(localStorage.getItem('@products'));
    if (!products) return false;
    const root = document.getElementById('root');
    root.style.backgroundColor = 'rgb(82, 86, 89)';
    let wrap = document.querySelector('.wrap');
    async function delay(item) {
      const wait = new Promise((resolve) => {
        setTimeout(function () {
          const html = `
                    <div class='container-print'>
                      <div class='content'>
                        <div class='tab_header'><figure class='logo'><img src='/images/logochequer.png'/></figure></div>
                          <div class='tab_main'>
                            <div class='panel'>
                              <div class='panel__info'>
                                <p class='ref'>REF: ${item.codigo} / ${item.padrao}</p>
                                <p class='name'>${item.nome}</p>
                              </div>
                              <div class='panel__description'>
                                <div class='col1'>
                                  <div class='plano'>${item.plano}</div>
                                  <div class='cifrao'>R$</div>
                                </div>
                                <div class='col2'>${item.valor_15}<sup>,99</sup></div>
                              </div>
                              <div class='panel__footer'>10X ${item.valor_10} OU ${item.avista} ÀVISTA</div>
                            </div>
                          </div>
                          <div class='tab_footer'>
                            <figure class='prazo'>
                              <img src='/images/60dias.png'/>
                            </figure>
                          </div>
                      </div>
                    </div>`;
          wrap.innerHTML += html.trim();
          resolve();
        }, 200);
      });

      return await wait;
    }

    async function processArraySequence(array) {
      let n = 1;
      let count = 1;
      for (const item of array) {
        await delay(item);
        if (array.length === count) return false;
        if (n === 2) {
          wrap = create();
          n = 0;
        }
        n++;
        count++;
      }
    }

    function create() {
      const wrapAll = document.querySelectorAll('.wrap');
      wrap = wrapAll[wrapAll.length - 1].cloneNode(true);
      wrap.classList.remove('first');
      wrap.innerHTML = "<div class='guia'><div class='guiaX'></div></div>";
      root.appendChild(wrap);
      return wrap;
    }

    processArraySequence(products);
  }, []);

  return (
    <Fragment>
      <GlobalStyle />
      <FixedHeader className="fixed-header print" />
      <Wrap className="wrap first" />
    </Fragment>
  );
}

const FixedHeader = ({ className, children }) => (
  <div className={className}>
    <div className="fixed-title">
      <h6>TABELA PRIMÁRIA 2X2</h6>
    </div>
    <div className="buttons">
      <a href="/tabelas">
        <img src="/images/buttons/button-mini-back.svg" alt="Boltão voltar" />
      </a>
      <button id="btn-print">
        <img src="/images/buttons/button-mini-print.svg" alt="Boltão voltar" />
      </button>
    </div>
    {children}
  </div>
);

const Wrap = ({ className, children }) => (
  <Fragment>
    <div className={className}>
      <div className="guia">
        <div className="guiaX"></div>
      </div>
    </div>
    {children}
  </Fragment>
);

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(82, 86, 89) !important;
}

img {
    display: block;
    width: 100%;
}

.back {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    font-size: 10pt;
    transform: translate(-35px,-1px);
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 15mm;
    background-color: rgb(50, 54, 57);
    box-shadow: 1px 1px 5px #000;
    color: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 10000;
}
.buttons {
    display: flex;
    align-items: center;
}

.fixed-header button {
    transform: all;
    background-color: transparent;
    box-shadow: none;
    border: none
}

.fixed-header button img {
    width: 35px;
}

.fixed-header button img:hover {
    transform-origin: center;
    transform: scale(1.1);
    cursor: pointer;
    transition: all .2s;
}

.buttons a {
    margin: 5px;
}
.buttons a img{
    width: 35px;
}

.fixed-header .wrap-center {
    width: 210mm;
    margin: 0 auto;
}

.wrap{
    position: relative;
    margin: 0 auto;
    width: 297mm;
    height: 210mm;
    color: #444;
    background-color: #fff;
    margin-bottom: 5mm;
}

.wrap.first {
    margin-top: 17mm;
}

.guia {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.guia .guiaY {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: rgb(192, 192, 192);
    z-index: 2;
}

.guia .guiaX {
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: rgb(192, 192, 192);
    z-index: 2;
}

.container-print {
    position: relative;
    width: calc((100% - 1px) / 2);
    height: calc((100% - 1px) / 1);
    /* background-color: red; */
    z-index: 1;

    display: flex;
    float: left;
    padding: 20px;

}

.content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 6px solid #000051;
    border-radius: 10px;
}

.tab_header {
    width: 100%;
}

.tab_header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.tab_header img {
    width: 80%;
}

.tab_main {
    flex: 1;
    width: 100%;
    margin: 0 10px;
    padding: 10px;
    border: 6px solid #710000;
    border-radius: 20px;
}

.tab_footer {
    width: 100%;
}

@media only screen and (max-width: 37.5em) {
    .wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    .container-print {
        display: flex;
        margin: 0 auto;
        float: none;
    }

    .content {
        transform: scale(1);
        padding: 5rem 0;
    }

    .tab_header img {
        width: 70%;
        transform: translateY(-30%);
    }

    .tab_main {
        margin-bottom: 20px;
    }

    .guia .guiaY,
    .guia .guiaX {
        display: none;
    }
}

.tab_footer .prazo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.panel__info {
    font-family: "Roboto", sans-serif;
    font-size: 14pt;
    font-style: italic;
    font-weight: bolder;
    color: #000051;
}

.panel__info .name {
    font-size: 18pt;
    font-weight: bolder;
    line-height: 1;
}

.panel__description {
    flex: 1;
    display: flex;
    align-items: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    border-bottom: 3px solid #000051;
    margin-bottom: 5px;
}

.panel__description .col1 {
    display: flex;
    flex-direction: column;
    padding-right: 2mm;
    color: #000051;
}


.panel__description .col1 .plano {
    font-size: 42pt;
    color: #710000;
    border: 2px solid #000051;
    border-radius: 2mm;
    padding: 0 2mm;
}

.panel__description .col1 .cifrao {
    text-align: right;
    font-size: 30pt;
}

.panel__description .col2 {
    flex: 1;
    font-size: 100pt;
    display: flex;
    justify-content: center;
    color: #000051;
}

.panel__description .col2 sup {
    height: 20mm;
    font-size: 50pt;
    transform: translateY(3mm);
}

.panel__footer {
    color: #000051;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 23pt;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: bolder;
    letter-spacing: -1px;
}

@page :left {
    margin: 1mm;
}

@page :right {
    margin: 1mm;
}

@page {
    /* size: A4;     */
    size: auto;
    margin: 0;
}

@media print {
    .fixed-header.print {
        display: none;
    }

    .wrap.first {
        margin: 0 !important;
    }

    .wrap{
        position: relative;
        margin: 0 auto;
        width: 297mm;
        height: 209mm;
        color: #444;
        background-color: #fff;
        margin: 0.1mm;
    }

    .container-print {
        position: relative !important;
        width: calc((297mm - 1px) / 2) !important;
        height: calc((209mm - 1px) / 1) !important;
        /* background-color: red; */
        z-index: 1;

        display: flex !important;
        float: left !important;
        padding: 20px !important;
    }

    .page-break {
        page-break-before: always;
    }

    .guia .guiaY {

    }

    .guia .guiaX {
        position: absolute;
        left: 50%;
        height: 100%;
        width: 2px;
        background-color: rgb(192, 192, 192);
        z-index: 1000;
    }
}
`;
