import React from "react";
// import styled from "styled-components";
// import Utils from "../../../services/utils";

import {Table, Thead, Tbody, Flex} from '../../../components/Table';
import Moment from "../../../services/Moment";

import {FiTrash2, FiEdit2} from 'react-icons/fi';
import { HiOutlineCalendar } from "react-icons/hi";
// import { IoAlarmOutline } from "react-icons/io5";
// import { AiOutlineUser } from "react-icons/ai";

import {formatDataRegex} from '../../../services/utils';


export default function TableRender({dataSet, editAction, removeAction}) {
    // const {splitName} = Utils();      
    const {converteToTime} = Moment();

    // function Svg(props) {
    //     var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
    //     return <svg className={props.className} dangerouslySetInnerHTML={{__html: useTag }} />;
    // }   

    return(
        <Table>
            <Thead>
                <tr className="">
                    <th scope="col">Descrição</th>
                    <th scope="col">Horas a trabalhar</th> 
                    <th scope="col">Vai Trabalhar?</th> 
                    <th scope="col">Data</th> 
                    <th scope="col">Criado por:</th> 
                    <th scope="col">Action</th> 
                </tr>
            </Thead>
            <Tbody> 
                {dataSet && dataSet.rows.map((item) => (
                    <tr key={item.id} data-item={item.id}>
                       <td><span className="mob">Descrição</span><p>{item.name}</p></td>                        
                       <td><span className="mob">Horas a trabalhar</span><p>{converteToTime(item.workload)}</p></td>                        
                       <td>
                           <span className="mob">Vai Trabalhar?</span>
                           <span className="badge" style={{backgroundColor: item.status ? '#5cb85c' : 'rgb(240, 80, 80)'}}>{item.status ? "Sim" : "Não"}</span>                           
                        </td>                       
                        <td data-label="Cadastrado" className="center"><span className="mob">Criado</span><Flex className="center xs-left"><HiOutlineCalendar size={22}/>{formatDataRegex(item.date_at)}</Flex></td> 
                       <td><span className="mob">Criado por:</span><p>{item.user_created}</p></td>
                       <td data-label="#" className="nowrap">
                            <span className="mob">Action</span>
                            <a onClick={removeAction} data-id={item.id} href={`/feriados/destroy/${item.id}`} className="action" style={{backgroundColor: 'rgb(240, 80, 80)'}}><FiTrash2 color='#fff' size={17}/></a>
                            <a href={`/feriados/update/${item.id}`} className="action" style={{backgroundColor: '#f6a935'}}><FiEdit2 color='#fff' size={17}/></a>
                        </td>
                    </tr>
                ))}                                                                            
            </Tbody>
        </Table>
    )
}
// #3583f6 #f6a935
// const TdFlex = styled.div`
//     display: flex;
//     align-items: center;

//     &.center {
//         justify-content: center;
//         @media only screen and (max-width: 50em) {
//             justify-content: initial;
//         }
//     }

//     svg {        
//         max-width: 1.6rem;
//         max-height: 1.6rem;
//         margin-right: 5px;
//     }
// `;