import React from 'react';
import Content from '../../components/Content';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormSelect, FormInput } from '../../components/FormRef/Form';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';
//**Icons */
import { FaProductHunt } from "react-icons/fa";
// import { FiFileText } from 'react-icons/fi'; //FiFileText
import { FormContext } from '../../components/FormRef/FormContext'
// import { GlobalContext } from '../../contexts/global';
import { ajax, serialize } from '../../api/chequer';
import { toast } from 'react-toastify';
import Mask from '../../services/mask';

//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
//const MySwal = withReactContent(Swal);

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {
  const { fields } = React.useContext(FormContext);
  // const { roleScan } = React.useContext(GlobalContext);
  const [stateButton, setStateButton] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  //**Permissões */
  // const aDmin = React.useMemo(() => roleScan(), [roleScan]);
  // const cr_Register = React.useMemo(() => roleScan(['cr_products']), [roleScan]);
 
  // function checkOnly() {
  //   if(cr_Register) return false;    
  //   return true
  // }

  const handleReset = React.useCallback((e)=> {
    e!== undefined ? e.preventDefault() : e = window.event;
    fields.codigo.value = "";
    fields.name.value = "";
    fields.price.value = "0,00";
    //fields.retention.value = "0,00";
    //fields.no_retention.value = "0,00";
    fields.status.value = true;
    fields.product_category_id.value = ""
  }, [fields])

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);

    const params = [
      { name: 'codigo', value: fields.codigo.value },
      { name: 'name', value: fields.name.value },
      { name: 'price', value: fields.price.value },
      {name: 'unit_of_measurement', value: fields.unit_of_measurement.value},
      // { name: 'retention', value: fields.retention.value },
      // { name: 'no_retention', value: fields.no_retention.value },
      { name: 'status', value: fields.status.value },     
      { name: 'product_category_id', value: fields.product_category_id.value }, 
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/product/store', method: 'post', body, json: true });      
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message);
      handleReset()

    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, handleReset]);

  React.useEffect(() => {
    if(fields.price !== undefined) {
      Mask.money(fields['price']);
      //Mask.money(fields['retention']);
      //Mask.money(fields['no_retention']);
    }

    async function fetchProductCategory() {   
      const response = await ajax({ url: `/product/category` });      
      const options = [];
      response.rows.map((item) => options.push({value: item.id, name: item.name}))
      setCategoryOptions(options);         
    }
    fetchProductCategory();

  }, [fields]);

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}>
        <RowTitle text="REGISTRAR PRODUTO">
          <FaProductHunt size={25} />
        </RowTitle>
        <Panel>
          <FormInput
            required={true}
            inputMode="numeric"
            text="Código.."
            col={4}
            name="codigo"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Descrição.."
            col={8}
            name="name"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Preço.."
            col={3}
            name="price"
            value="0,00"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Unidade de medida.."
            col={3}
            name="unit_of_measurement"
            //readOnly={checkOnly()}
          />                     
          <FormSelect
            label="Categoria.."
            firstText={"Selecione..."}
            options={categoryOptions}
            col={6}
            name="product_category_id"
            //onChange={handleChange}
          />
          <FormSelect
            label="Status.."
            options={[{ name: 'Ativo', value: 'true' }, { name: 'Inativo', value: 'false' },]}
            col={3}
            name="status"
            //onChange={handleChange}
          />          

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />
            <ButtonAction src={buttonReset} alt="mini" disabled={stateButton} onClick={handleReset} />
          </Row>
          
        </Panel>
      </form>
    </Container>
  );
}