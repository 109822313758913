import { createGlobalStyle } from 'styled-components';
const GridStyled = createGlobalStyle`
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &.mobjcenter {
      @media only screen and (max-width: 37.5em) {
          justify-content: center;
      }
  }

  &.subtitle {
      color: #7999d7; // #7999d7 #d77979
      padding: 5px 10px;
      margin-bottom: 1rem;
      background-color: #d7d7d71c;
      border: 2px solid rgba(#c1d4fa, 0.3);
      font-size: 1.2rem;
      border-radius: 5px;

      h5 {
          display: flex;
          align-items: center;
          svg {
              margin-right: 5px;
          }
      }
  }
}

.panel-row {
  display: block;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 2rem;
  overflow-y: auto;
  margin-bottom: 2rem;
  scrollbar-width: thin;
  height: 100%;

  @media only screen and (max-width: 37.5em) {
      padding: 1rem 0;
      border: 0;
  }
}

.col {
  flex: 1;
}
.col-1 {
  width: calc(100% / 12 * 1);
}
.col-2 {
  width: calc(100% / 12 * 2);
}
.col-3 {
  width: calc(100% / 12 * 3);
}
.col-4 {
  width: calc(100% / 12 * 4);
}
.col-5 {
  width: calc(100% / 12 * 5);
}
.col-6 {
  width: calc(100% / 12 * 6);
}
.col-7 {
  width: calc(100% / 12 * 7);
}
.col-8 {
  width: calc(100% / 12 * 8);
}
.col-9 {
  width: calc(100% / 12 * 9);
}
.col-10 {
  width: calc(100% / 12 * 10);
}
.col-11 {
  width: calc(100% / 12 * 11);
}
.col-12 {
  width: calc(100% / 12 * 12);
}

@media only screen and (max-width: 86.25em) {
  .col-lg-1 {
    width: calc(100% / 12 * 1);
  }
  .col-lg-2 {
    width: calc(100% / 12 * 2);
  }
  .col-lg-3 {
    width: calc(100% / 12 * 3);
  }
  .col-lg-4 {
    width: calc(100% / 12 * 4);
  }
  .col-lg-5 {
    width: calc(100% / 12 * 5);
  }
  .col-lg-6 {
    width: calc(100% / 12 * 6);
  }
  .col-lg-7 {
    width: calc(100% / 12 * 7);
  }
  .col-lg-8 {
    width: calc(100% / 12 * 8);
  }
  .col-lg-9 {
    width: calc(100% / 12 * 9);
  }
  .col-lg-10 {
    width: calc(100% / 12 * 10);
  }
  .col-lg-11 {
    width: calc(100% / 12 * 11);
  }
  .col-lg-12 {
    width: calc(100% / 12 * 12);
  }
}

@media only screen and (max-width: 75em) {
  .col-md-1 {
    width: calc(100% / 12 * 1);
  }
  .col-md-2 {
    width: calc(100% / 12 * 2);
  }
  .col-md-3 {
    width: calc(100% / 12 * 3);
  }
  .col-md-4 {
    width: calc(100% / 12 * 4);
  }
  .col-md-5 {
    width: calc(100% / 12 * 5);
  }
  .col-md-6 {
    width: calc(100% / 12 * 6);
  }
  .col-md-7 {
    width: calc(100% / 12 * 7);
  }
  .col-md-8 {
    width: calc(100% / 12 * 8);
  }
  .col-md-9 {
    width: calc(100% / 12 * 9);
  }
  .col-md-10 {
    width: calc(100% / 12 * 10);
  }
  .col-md-11 {
    width: calc(100% / 12 * 11);
  }
  .col-md-12 {
    width: calc(100% / 12 * 12);
  }
}

// @media only screen and (max-width: $media-800) {
//     @include grid-loop(".col-xs", $col);
// }

@media only screen and (max-width: 37.5em) {
  .col-xs-1 {
    width: calc(100% / 12 * 1);
  }
  .col-xs-2 {
    width: calc(100% / 12 * 2);
  }
  .col-xs-3 {
    width: calc(100% / 12 * 3);
  }
  .col-xs-4 {
    width: calc(100% / 12 * 4);
  }
  .col-xs-5 {
    width: calc(100% / 12 * 5);
  }
  .col-xs-6 {
    width: calc(100% / 12 * 6);
  }
  .col-xs-7 {
    width: calc(100% / 12 * 7);
  }
  .col-xs-8 {
    width: calc(100% / 12 * 8);
  }
  .col-xs-9 {
    width: calc(100% / 12 * 9);
  }
  .col-xs-10 {
    width: calc(100% / 12 * 10);
  }
  .col-xs-11 {
    width: calc(100% / 12 * 11);
  }
  .col-xs-12 {
    width: calc(100% / 12 * 12);
  }
}
`;

export default GridStyled;
