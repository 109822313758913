// https://www.youtube.com/watch?v=d2uqo6PhdM4 => Video sobre regex muto bom
import React from "react";

//**Converte yyyy-mm-ddT00:00:00.000Z para dd/mm/yyyy */
export function formatDataRegex(str){
    return str.replace(/T.*/g, "").replace(/([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})/, "$3/$2/$1")
}

export default function Utils() {
    const splitName = React.useCallback((str, dec=1) => {
        const ext = ["de", "da", "dos"];
        const name = str.split(" ");
        if(dec === 1) {
            return name[0];
        }else if(dec === 2) {
            if(ext.indexOf(name[1]) > -1) {
                return `${name[0]} ${name[2]}..`;
            }else{
                return `${name[0]} ${name[1]}..`;
            }
            
        }
    }, []);

    return {
        splitName
    }
}

