import React from 'react';
import { ajax } from '../../api/chequer';
import usePaginate from '../../hooks/usePaginate';

import AuthContext from '../../contexts/auth';

import { Table, Thead, Tbody, Paginate, Flex } from '../../components/Table';

import {FaEye, FaPrint, FaCloudDownloadAlt } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi'; //FiEdit2
import { HiOutlineCalendar } from 'react-icons/hi';
//import { MdPictureAsPdf } from 'react-icons/md';

import { formatDataRegex } from '../../services/utils';
import { GlobalContext } from '../../contexts/global';


// import Mask from '../../services/mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
const baseURL = process.env.REACT_APP_API_KEY;

export default function useList(url, limit) {
  const { user } = React.useContext(AuthContext);
  const { roleScan } = React.useContext(GlobalContext);
  const [stage, setStage] = React.useState(0);
  const [conditions, setConditions] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('start_date');
  const [dataSet, setDataSet] = React.useState([]);
  const [min, setMin] = React.useState(1);
  const [max, setMax] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const { currentPage, cbCurrentPage } = usePaginate();
  const [limitCount, setLimitCount] = React.useState(0);
  // const limitCount = Math.ceil(paginate / limit);

  const handlePaginate = React.useCallback(
    (e) => {
      setStage(0);
      const el = e.target;
      if (el.matches('.prev')) return currentPage >= 2 ? cbCurrentPage(currentPage - 1) : cbCurrentPage(1);
      if (el.matches('.next'))
        return currentPage <= limitCount - 1 ? cbCurrentPage(currentPage + 1) : cbCurrentPage(limitCount);
      const spanActive = document.querySelector('.content span.active');
      if (spanActive && spanActive.classList.contains('active')) spanActive.classList.remove('active');
      el.classList.add('active');
      cbCurrentPage(parseInt(el.innerText, 10));
    },
    [currentPage, limitCount, cbCurrentPage],
  );

  const handleEditItem = React.useCallback((e) => {
    const el = e.target;
    const id = el.closest('tr').dataset.id;
    window.location.href = '/project/' + id;
  }, []);

  const handlePrintItem = React.useCallback((e) => {
    const el = e.target;
    const id = el.closest('tr').dataset.id;
    window.location.href = '/project/print/' + id;
  }, []);

  const handleDeleteUser = React.useCallback((e) => {    
      MySwal.fire({
        title: "Deseja remover?",
        text: "Essa ação não tera mais volta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Deletar!"
      }).then( async (result) => {
        if (result.isConfirmed) {  
          const el = e.target;
          const id = el.closest('tr').dataset.id;
          const response = await ajax({ url: '/order-service/' + id + '/destroy', method: 'delete' });
          if(response.message) {            
            MySwal.fire(response.message);
            return false;
          }

          if(response.success) {
            MySwal.fire(response.message);            
          }

          MySwal.fire({
            title: "Removido com sucesso.",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setStage(0);
          });         
          
        }
      });      
  }, []);

  function handleStatus(status) {    
    if(status === "EM VIABILIDADE TÉCNICA") return '#979797'; //#979797 | 33b9fb
    if(status === "EM APROVAÇÃO TÉCNICA") return '#6189ff';
    if(status === "EM REVISÃO") return '#a033fb';
    if(status === "AGUARDANDO REVISÃO") return '#fb8733';
    if(status === "RECUSADO") return '#fb3333';
    if(status === "AGUARDANDO O.S") return '#33b9fb';
    if(status === "EM APROVAÇÃO ADM") return '#3365fb';
    if(status === "APROVADO") return "#47a85b";
  }

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  const memoPaginate = React.useMemo(() => {
    const array = [];
    for (let i = 0; i < limitCount + 1; i++) {
      array.push(i);
    }

    if (currentPage + 1 > max) {
      setMax(max + 4);
      setMin(min + 4);
      return array.splice(min, min).map((item) => (
        <span className={currentPage === item ? 'active' : ''} key={item}>
          {item}
        </span>
      ));
    }

    if (currentPage < min) {
      setMin(min - 4);
      setMax(max - 4);
    }
    return array.splice(min, 5).map((item) => (
      <span className={currentPage === item ? 'active' : ''} key={item}>
        {item}
      </span>
    ));
  }, [limitCount, currentPage, min, max]);

  //**Permissões */ 
  //const permissionRemove = React.useMemo(() => roleScan(['rm_products']), [roleScan]);  

  const TableRender = React.useCallback(() => {
    return (
      <Table>
        <Thead>
          <tr className="">
          <th scope="col">ID</th>
            <th scope="col">Local do evento</th>
            <th scope="col">Ação/Metodo/Evento</th>
            <th scope="col">Início evento</th>
            <th scope="col">Encerramento evento</th>
            <th scope="col" className="center">Status</th>
            <th scope="col" className="center">Criado em</th>
            <th scope="col" className="center">Por..</th>
            <th scope="col">Action</th>
          </tr>
        </Thead>
        <Tbody>
          {dataSet.rows.map((item) => (
            <tr data-id={item.id} key={item.id} className="" onDoubleClick={handleEditItem}>
              <td data-label="OS">
                <span className="mob">OS</span>
                <p>{item.id}</p>
              </td>
              <td data-label="event_place">
                <span className="mob">Local do evento</span>
                <p>{item.event_place}</p>
              </td>
              <td data-label="event_name">
                <span className="mob">Ação/Metodo/Evento</span>
                <p>{item.event_name}</p>
              </td>
              <td data-label="start_date">
                <span className="mob">Início evento</span>
                {formatDataRegex(item.start_date)}
              </td>
              <td data-label="end_date">
                <span className="mob">Encerramento evento</span>
                {formatDataRegex(item.end_date)}
              </td>
              {/* <td data-label="no_retention">
                <span className="mob">Sem Retenção</span>
                {Mask.setMoney(item.no_retention)}
              </td>                             */}
              <td data-label="status" className="center">
                <span className="mob">Status</span>
                <span className="badge" style={{backgroundColor: `${handleStatus(item.status)}`}}> {/* style={{ backgroundColor: item.status === true ? '#5cb85c' : '#FF4444' }} */}
                {item.status}
                  {/* {item.status ? 'Ativo' : 'Inativo'} */}
                </span>
              </td>
              <td data-label="created" className="center">
                <span className="mob">Criado</span>
                <Flex className="center xs-left">
                  <HiOutlineCalendar size={22} />
                  {formatDataRegex(item.createdAt)}
                </Flex>
              </td>
              <td data-label="user_name">
                <span className="mob">Por..</span>
                {item.user_name.split(" ")[0]}
              </td>
              <td data-label="#" className="nowrap">
                <span className="mob">Action</span>                
                <button className="action" data-id={item.id} style={{ backgroundColor: '#7400ff' }} onClick={handlePrintItem}>
                  <FaPrint color="#fff" size={17} />
                </button>
                <button className="action" data-id={item.id} style={{ backgroundColor: '#3583f6' }} onClick={handleEditItem}>
                  <FaEye color="#fff" size={17} />
                </button>
                {scanPermission(['rm_project']) && (
                  <button className="action" data-id={item.id} style={{ backgroundColor: '#FF4444' }} onClick={handleDeleteUser}>
                  <FiTrash2 color="#fff" size={17} />
                </button>
                )}
                {item.upload_archive && (
                    <a
                      href={`${baseURL}/docs/${item.upload_archive}`}
                      target="_blank"
                      className="action"
                      style={{ backgroundColor: 'rgb(86, 196, 170)' }}
                      rel="noreferrer"
                    >
                      <FaCloudDownloadAlt color="#fff" size={20} />
                    </a>
                  )}
              </td>
            </tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [dataSet, handleEditItem, handleDeleteUser, handlePrintItem, scanPermission]);

  const PaginateRender = React.useCallback(() => {
    return (
      limitCount >= 2 && (
        <Paginate onClick={handlePaginate}>
          <div className="first active">
            <span className="firstpage">1</span>
            <span className="prev">Prev</span>
          </div>
          <div className="content">{memoPaginate}</div>
          <div className="last active">
            <span className="next">Next</span>
            <span className="lastpage">{limitCount}</span>
          </div>
        </Paginate>
      )
    );
  }, [handlePaginate, memoPaginate, limitCount]);

  React.useEffect(() => {
    if(stage === 0 ) {
      async function fetchUsers() {   
        let response;

        if(user.user_function === "Tecnico_regional") {
          response = await ajax({ url: `${url}/?page=${currentPage}&orderBy=${orderBy}&limit=${limit}&user_region=${user.user_region}&user_id=${user.id}${conditions}` });        
        }else{
          response = await ajax({ url: `${url}/?page=${currentPage}&orderBy=${orderBy}&limit=${limit}&user_region=${user.user_region}${conditions}` });
        }
                
        setDataSet(response);
        setLimitCount(Math.ceil(+response.count / +limit));
        setLoading(false);
      }
      fetchUsers();
      setStage(1);
    }
    
    
  }, [user, url, limit, currentPage, conditions, stage]);

  return {
    TableRender,
    PaginateRender,
    setConditions,
    setStage,
    setOrderBy,
    loading,
  };
}
