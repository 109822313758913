import React from 'react';
import { ajax } from '../../api/chequer';
import usePaginate from '../../hooks/usePaginate';

import { Table, Thead, Tbody, Paginate, Flex } from '../../components/Table';

import { FiTrash2 , FiEdit2 } from 'react-icons/fi';
import { HiOutlineCalendar } from 'react-icons/hi';

import { formatDataRegex } from '../../services/utils';
import { GlobalContext } from '../../contexts/global';

import Mask from '../../services/mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export default function useList(url, limit) {
  const { roleScan } = React.useContext(GlobalContext);
  const [stage, setStage] = React.useState(0);
  const [conditions, setConditions] = React.useState('');
  const [dataSet, setDataSet] = React.useState([]);
  const [min, setMin] = React.useState(1);
  const [max, setMax] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const { currentPage, cbCurrentPage } = usePaginate();
  const [limitCount, setLimitCount] = React.useState(0);
  // const limitCount = Math.ceil(paginate / limit);

  const handlePaginate = React.useCallback(
    (e) => {
      setStage(0);
      const el = e.target;
      if (el.matches('.prev')) return currentPage >= 2 ? cbCurrentPage(currentPage - 1) : cbCurrentPage(1);
      if (el.matches('.next'))
        return currentPage <= limitCount - 1 ? cbCurrentPage(currentPage + 1) : cbCurrentPage(limitCount);
      const spanActive = document.querySelector('.content span.active');
      if (spanActive && spanActive.classList.contains('active')) spanActive.classList.remove('active');
      el.classList.add('active');
      cbCurrentPage(parseInt(el.innerText, 10));
    },
    [currentPage, limitCount, cbCurrentPage],
  );

  const handleEditItem = React.useCallback((e) => {
    const el = e.target;
    const id = el.closest('tr').dataset.id;
    window.location.href = '/product/' + id;
  }, []);

  const handleDeleteUser = React.useCallback((e) => {    
      MySwal.fire({
        title: "Deseja remover?",
        text: "Essa ação não tera mais volta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Deletar!"
      }).then( async (result) => {
        if (result.isConfirmed) {  
          const el = e.target;
          const id = el.closest('tr').dataset.id;
          const response = await ajax({ url: '/product/' + id + '/destroy', method: 'delete' });
          if(response.message) {            
            MySwal.fire(response.message);
            return false;
          }

          if(response.success) {
            MySwal.fire(response.message);            
          }

          MySwal.fire({
            title: "Usuário removido com sucesso.",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            
            async function fetchUsers() {      
              const response = await ajax({ url: `${url}/?page=${currentPage}&limit=${limit}${conditions}` });      
              setDataSet(response);
              setLimitCount(Math.ceil(+response.count / +limit));
              setLoading(false);
            }

            if (result.isConfirmed) {              
              fetchUsers();
            } else if (result.isDenied) {
              fetchUsers();
            }
          });

          // MySwal.fire({
          //   title: "Removido!",
          //   text: "Usuário removido com sucesso.",
          //   icon: "success"
          // });
          
        }
      });      
  }, [url, limit, currentPage, conditions])

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  const memoPaginate = React.useMemo(() => {
    const array = [];
    for (let i = 0; i < limitCount + 1; i++) {
      array.push(i);
    }

    if (currentPage + 1 > max) {
      setMax(max + 4);
      setMin(min + 4);
      return array.splice(min, min).map((item) => (
        <span className={currentPage === item ? 'active' : ''} key={item}>
          {item}
        </span>
      ));
    }

    if (currentPage < min) {
      setMin(min - 4);
      setMax(max - 4);
    }
    return array.splice(min, 5).map((item) => (
      <span className={currentPage === item ? 'active' : ''} key={item}>
        {item}
      </span>
    ));
  }, [limitCount, currentPage, min, max]);

  //**Permissões */ 
  //const permissionRemove = React.useMemo(() => roleScan(['rm_products']), [roleScan]); 
  

  const TableRender = React.useCallback(() => {
    return (
      <Table>
        <Thead>
          <tr className="">
            <th scope="col">Código</th>
            <th scope="col">Descrição</th>
            <th scope="col">Preço</th>            
            {/* <th scope="col">Retenção</th>            
            <th scope="col">Sem Retenção</th>             */}
            <th scope="col" className="center">Status</th>
            <th scope="col" className="center">Criado em</th>
            <th scope="col">Action</th>
          </tr>
        </Thead>
        <Tbody>
          {dataSet.rows.map((item) => (
            <tr data-id={item.id} key={item.id} className="" onDoubleClick={handleEditItem}>
              <td data-label="codigo">
                <span className="mob">Código</span>
                <p>{item.codigo}</p>
              </td>
              <td data-label="name">
                <span className="mob">Descrição</span>
                <p>{item.name}</p>
              </td>
              <td data-label="price">
                <span className="mob">Preço</span>
                {Mask.setMoney(item.price)}
              </td>
              {/* <td data-label="retention">
                <span className="mob">Retenção</span>
                {Mask.setMoney(item.retention)}
              </td>
              <td data-label="no_retention">
                <span className="mob">Sem Retenção</span>
                {Mask.setMoney(item.no_retention)}
              </td>                             */}
              <td data-label="status" className="center">
                <span className="mob">Status</span>
                <span className="badge" style={{ backgroundColor: item.status === true ? '#5cb85c' : '#FF4444' }}>
                  {item.status ? 'Ativo' : 'Inativo'}
                </span>
              </td>
              <td data-label="created" className="center">
                <span className="mob">Criado</span>
                <Flex className="center xs-left">
                  <HiOutlineCalendar size={22} />
                  {formatDataRegex(item.createdAt)}
                </Flex>
              </td>
              <td data-label="#" className="nowrap">
                <span className="mob">Action</span>
                {scanPermission(['up_products']) && (
                <button className="action" data-id={item.id} style={{ backgroundColor: '#3583f6' }} onClick={handleEditItem}>
                  <FiEdit2 color="#fff" size={17} />
                </button>
                )}
                {scanPermission(['rm_products']) && (
                  <button className="action" data-id={item.id} style={{ backgroundColor: '#FF4444' }} onClick={handleDeleteUser}>
                  <FiTrash2 color="#fff" size={17} />
                </button>
                )}       
              </td>
            </tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [dataSet, handleEditItem, handleDeleteUser, scanPermission]);

  const PaginateRender = React.useCallback(() => {    
    return (
      limitCount > 2 && (
        <Paginate onClick={handlePaginate}>
          <div className="first active">
            <span className="firstpage">1</span>
            <span className="prev">Prev</span>
          </div>
          <div className="content">{memoPaginate}</div>
          <div className="last active">
            <span className="next">Next</span>
            <span className="lastpage">{limitCount}</span>
          </div>
        </Paginate>
      )
    );
  }, [handlePaginate, memoPaginate, limitCount]);

  React.useEffect(() => {
    if(stage === 0 ) {
      async function fetchUsers() {   
        const response = await ajax({ url: `${url}/?page=${currentPage}&limit=${limit}${conditions}` });        
        setDataSet(response);
        setLimitCount(Math.ceil(+response.count / +limit));
        setLoading(false);
      }
      fetchUsers();
      setStage(1);
    }
  }, [url, limit, currentPage, conditions, stage]);

  return {
    TableRender,
    PaginateRender,
    setConditions,
    loading,
  };
}
