import React from 'react';
import Content from '../../components/Content';
import { useParams } from 'react-router-dom';

import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormSelect, FormInput, FormCheckbox } from '../../components/FormRef/Form';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import { FiUsers } from 'react-icons/fi';

import { ajax, serialize } from '../../api/chequer';
import useStore from '../../hooks/useStore';
import { toast } from 'react-toastify';
import { FormContext } from '../../components/FormRef/FormContext'
import { GlobalContext } from '../../contexts/global';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
// const MySwal = withReactContent(Swal);

export default function EditUser() {  
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {  
  const [stage, setStage] = React.useState(0);  
  const { fields } = React.useContext(FormContext);
  const { can, roleScan, socket } = React.useContext(GlobalContext);
  const { id } = useParams();
  const [roles, setRoles] = React.useState([]);  
  const [permissions, setPermissions] = React.useState([]);  
  const [stateButton, setStateButton] = React.useState(false);
  const { options } = useStore();

  //**Permissões */  
  const memoCan = React.useMemo(() => can(['super_admin']), [can]);  

  //**Cria um array com checbox marcados query ex: '#roles input[type=checkbox]:checked'*/
  function handleCheckedBox(query) {
    const array = document.querySelectorAll(query);    
    const arrayParams = [...array].length >= 1 ? [...array].map((item) => item.value) : [];
    return arrayParams
  }

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);
    const rolesParam = handleCheckedBox('#roles input[type=checkbox]:checked');
    const permissionsParam = handleCheckedBox('#permissions input[type=checkbox]:checked');  
    const regionParam = handleCheckedBox('#region input[type=checkbox]:checked');     

    const params = [
      { name: 'name', value: fields.name.value },      
      { name: 'user_function', value: fields.user_function.value },
      { name: 'user_region', value: regionParam },
      { name: 'email', value: fields.email.value },      
      { name: 'password', value: fields.password.value },      
      { name: 'status', value: fields.status.value },      
      { name: 'roles', value: rolesParam },      
      { name: 'permissions', value: permissionsParam },      
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/users/' + id, method: 'put', body, json: true }); 
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) {
        setTimeout(async () => {          
          const upUsers = await ajax({ url: '/users/' + response.user.id }); 
          socket.emit("update_user", upUsers) 
        }, 5000);
        toast.success(response.message);
      }

    setTimeout(() => {
      setStateButton(false);
    }, 1000);
  
  }, [id, fields, socket]); 

  //**Faz um check nas nas permissões e Roles do usuário */
  const checkedBox = React.useCallback((array, query='')=> {
    const indexed = array.map((item) => +item.id);    
    const domRoles = document.querySelectorAll(query);    
    [...domRoles].forEach((item) => { 
      if (indexed.indexOf(+item.value) !== -1) {  
        if(fields[item.name] !== undefined) fields[item.name].checked = true 
      }
    });
  }, [fields])

  const checkedRegion = React.useCallback((arrays, query='')=> {
    if(stage === 1) {          
      const array = arrays.split(",");
      const indexed = array.map((item) => item);    
      const domRoles = document.querySelectorAll(query);    
      [...domRoles].forEach((item) => {
        if (indexed.indexOf(item.name) !== -1) {  
          if(fields[item.name] !== undefined) fields[item.name].checked = true 
        }
      });
      setStage(2);
    }   
  }, [fields, stage])

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  React.useEffect(() => {
    if(stage === 0) {
      async function loadRoles() {
        return new Promise(async (resolve)=> {
          async function start() {
            const wait = new Promise(async(resolve) => {
              const resR = await ajax({ url: '/roles' });
              const resP = await ajax({ url: '/permissions' });
              if (resR.rows) setRoles(resR.rows);
              if (resP.rows) setPermissions(resP.rows);         
              resolve(true)
            })
    
            return await wait;
          }
          await start();
          resolve(true);
        })
      }
  
      (async function() {
        await loadRoles();
        setStage(1)
      })();
      
    }    
  }, [stage])

  React.useEffect(() => {
    if(stage === 1) {    
      async function loadUser() {
        return new Promise(async (resolve)=> {
          const response = await ajax({ url: '/users/' + id });     
        fields.name.value = response.name 
        fields.email.value = response.email 
        fields.user_function.value = response.user_function.toLowerCase();      
        // if(response.user_region !== null) fields.user_region.value = response.user_region.toLowerCase();      
        fields.status.value = response.status  
        checkedBox(response.roles, '#roles input');
        checkedBox(response.permissions, '#permissions input');
        checkedRegion(response.user_region, '#region input')
        resolve(true);
        })
      }
  
      (async function() {
        if (id && fields.name !== undefined) {         
          await loadUser();         
        }            
      })();      
    }
    
  }, [id, fields, stage, checkedBox, checkedRegion]);

  return (
    <Container>
      <form id="formUser" onSubmit={handleSubmit}>
        <RowTitle text="ATUALIZAR USUÁRIO">
          <FiUsers size={25} />
        </RowTitle>
        <Panel>      
          <FormInput 
            required={true}
            text="Nome.."
            col={6}
            name="name"            
            //readOnly={checkOnly()}
          /> 
          <FormInput 
            required={true}
            text="Email.."
            col={6}
            name="email"            
            //readOnly={checkOnly()}
          /> 
          <FormInput 
            text="Senha.."
            col={4}
            name="password"            
            //readOnly={checkOnly()}
          /> 
          <FormSelect 
            label="Função.."
            options={options.user_functions} 
            col={4}            
            name="user_function"
            //onChange={handleChange}
          />
          {/* <FormSelect 
            label="Região.."
            options={options.user_regions} 
            col={4}            
            name="user_region"
            firstText="Selecione..."
            //onChange={handleChange}
          /> */}
          <FormSelect 
            label="Status.."
            options={options.status} 
            col={4}            
            name="status"
            //onChange={handleChange}
          /> 

          <div>
            <RowTitle text="Região" />
            <Row id="region">
              <FormCheckbox              
                md={4}
                xs={6}
                lg={3}
                name={"norte"}
                value={"norte"}
                text={"Norte"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"noroeste"}
                value={"noroeste"}
                text={"Noroeste"}                        
              />                
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"sul"}
                value={"sul"}
                text={"Sul"}                        
              />
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"centro_oeste"}
                value={"centro_oeste"}
                text={"Centro"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"serrana"}
                value={"serrana"}
                text={"Serrana"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"escritorio"}
                value={"escritorio"}
                text={"Escritório"}                        
              /> 
              </Row>
          </div>          

          {scanPermission('up_roles') && (
            <div>
              <RowTitle text="Funções" />
              <Row id="roles">
                {/* {memoCan && <span>can sim</span>} */}
                {roles &&
                  roles.map((role) =>
                    memoCan ? (
                      <FormCheckbox 
                        key={role.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={role.name}
                        value={role.id}
                        text={role.guard_name}                        
                      />                      
                    ) : (
                      role.name !== 'super admin' &&
                      role.name !== 'admin' && (
                        <FormCheckbox 
                        key={role.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={role.name}
                        value={role.id}
                        text={role.guard_name}                        
                      />  
                      )
                    ),
                  )}
              </Row>
            </div>
          )}
          

          {scanPermission('up_permissions') && (
            <div>
            <RowTitle text="Permissões" />
            <Row id="permissions">
              {/* {memoCan && <span>can sim</span>} */}
              {permissions &&
                permissions.map((permission) =>
                  memoCan ? (
                      <FormCheckbox 
                        key={permission.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={permission.name}
                        value={permission.id}
                        text={permission.guard_name}
                        
                      />                    
                  ) : (
                    permission.name !== 'super admin' &&
                    permission.name !== 'admin' && (
                      <FormCheckbox 
                        key={permission.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={permission.name}
                        value={permission.id}
                        text={permission.guard_name}                       
                      /> 
                    )
                  ),
                )}
            </Row> 
            </div>
          )}   

          <Row>
            <ButtonAction type="submit" data-id={id} src={buttonSend} alt="mini" disabled={stateButton} />                
          </Row>           
          
        </Panel>       
      </form>
    </Container>
  );
}