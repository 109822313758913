import React from "react";
import Nav from "../components/Nav";
import {GlobalContext} from '../contexts/global';
import Loader from "../components/Loading";
import Header from "../components/Header";

export default function Content({children}){
    const {loading} = React.useContext(GlobalContext);   
    React.useEffect(() => {
        if (/iP(hone|ad)/.test(window.navigator.userAgent)) {                    
            document.body.addEventListener("touchstart", function () {}, false);
        }
    }, []) 
    return(
       <>
       <Loader status={loading}/> 
        <Header/>
        <div className="root-content">
            <div className="root-content__aside">
                <Nav/>
            </div>
            <div className="root-content__main">
                {children}
            </div>
        </div>
       </>
    )
}