import React from 'react';
import Content from '../../components/Content';

import { ajax, serialize } from '../../api/chequer';

import { FiFilter, FiXSquare, FiEdit2, FiTrash2 } from 'react-icons/fi';
//import {FiFileText, FiXSquare, FiFilter, FiPlusSquare} from 'react-icons/fi';
//import { HiOutlineCalendar } from "react-icons/hi";

import MiniPrint from '../../assets/images/buttons/button-mini-print.svg';
import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';
import MiniAlter from '../../assets/images/buttons/button-mini-alter.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { Row, Column, ButtonAction } from '../../components/Form';
import { Input, ButtonRow, Select, FilterLabel, MultSelect } from '../../components/FormRef/Form';
import { Flex } from '../../components/Table';
import Moment from '../../services/Moment';
import styled from 'styled-components';

import { formatDataRegex } from '../../services/utils';
import { FormContext } from '../../components/FormRef/FormContext';
import { toast } from 'react-toastify';

import { Modal } from '../../components/Modal';
import AuthContext from '../../contexts/auth';

export default function PlanilhaList() {
  const { user } = React.useContext(AuthContext);
  const { fields } = React.useContext(FormContext);
  const [result, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [motorista, setMotorista] = React.useState([]);
  const [disPrint, setDisPrint] = React.useState(true);
  const [disTroca, setDisTroca] = React.useState(true);
  const [troca, setTroca] = React.useState([]);
  const [isModal, setIsModal] = React.useState(false);
  const [wal, setWal] = React.useState('closed');
  const [swalData, setSwalData] = React.useState(null);
  const { today, todayAddDay, unFormat } = Moment();

  function handlePrint(e) {
    e.preventDefault();
    let search;

    if (fields['search'].value !== '') {
      search = `?page=1&limit=100&pedido=${fields['search'].value}`;
    } else if (fields['ficha'].checked === true) {
      search = `?page=1&limit=100&ficha=${fields['ficha_search'].value}`;
    } else {
      const dataPlanilha = unFormat({ date: fields['data_planilha'].value });
      search = `?page=1&limit=${fields['limit'].value}&day=${dataPlanilha}`;
      if (fields['motorista'].value !== '') search = search + `&motorista=${fields['motorista'].value}`;
    }

    window.location.href = '/planilhas/report' + search;
  }

  const handleFilter = React.useCallback(() => {
    const filter = document.getElementById('filter');
    filter.classList.toggle('open');
  }, []);

  async function handleSetFilter(btn = true) {
    // e.preventDefault();
    if (fields['ficha'].checked === true) {
      const search = `?page=1&limit=100&ficha=${fields['ficha_search'].value}`;
      const response = await ajax({ url: '/planilhas' + search });

      if (response.rows && response.count > 0) {
        setResult(response.rows);
        setDisPrint(false);
        if (btn) handleFilter();
        return false;
      }

      toast.warning('Ficha não encontrado..');
      return false;
    }

    const dataPlanilha = unFormat({ date: fields['data_planilha'].value });
    const response = await ajax({
      url: `/planilhas?page=1&limit=10&day=${dataPlanilha}&motorista=${fields['motorista'].value}`,
    });
    localStorage.setItem(
      '@planilhaFilter',
      JSON.stringify({
        data: fields.data_planilha.value,
        motorista: fields.motorista.value,
        limit: fields.limit.value,
      }),
    );

    if (response.rows) {
      setResult(response.rows);
      if (btn) handleFilter();
      setDisPrint(false);
    }
  }

  async function handleSearch(e) {
    e.preventDefault();
    if (fields['search'].value.trim() === '') return false;
    const search = `?page=1&limit=100&pedido=${fields['search'].value}`;
    const response = await ajax({ url: '/planilhas' + search });

    if (response.rows && response.count > 0) {
      setResult(response.rows);
      setDisPrint(false);
      return false;
    }

    toast.warning('Pedido não encontrado..');
  }

  function handleReset(e) {
    e.preventDefault();
    localStorage.removeItem('@planilhaFilter');
    fields['data_planilha'].value = todayAddDay({ date: today(), days: 0, format: 'dd/mm/yyyy' });
    fields['ficha'].checked = false;
    fields['ficha_search'].value = '';
    fields['motorista'].selectedIndex = '0';
  }

  function handleTroca(e) {
    const el = e.target;
    if (el.checked) {
      setTroca((old) => {
        return [...old, el.value];
      });
      setDisTroca(false);
    } else {
      const items = troca.filter((item) => item !== el.value);
      if (items.length === 0) setDisTroca(true);
      setTroca(items);
    }
  }

  async function handleTrocaPlanilha(e) {
    e.preventDefault();
    setIsModal(true);
  }

  async function handleSubmitTroca() {
    const params = [
      { name: 'user_created', value: user.name.split(' ')[0] },
      { name: 'data_entrega', value: unFormat({ date: fields['data_troca'].value }) },
      { name: 'motorista', value: fields['troca_motorista'].value },
      { name: 'montador', value: fields['troca_montador'].value },
      { name: 'planilhas', value: troca },
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/planilhas/troca', method: 'post', body, json: true });
    if (response.success) {
      toast.success('Planilha alterado com sucesso!');
      handleSetFilter(false);
      handleClose();
      setDisTroca(true);
      setTroca([]);
    }
  }

  const setSwalCb = React.useCallback(() => {
    return new Promise((resolve) => {
      if (swalData !== undefined) {
        resolve(true);
      }
    });
  }, [swalData]);

  const handleRemovePlanilha = React.useCallback(
    async (e) => {
      e.preventDefault();
      const id = e.target.dataset.id;
      setSwalData(id);
      await setSwalCb();
      setWal('open');
    },
    [setSwalCb],
  );

  async function handleSubmitDestroyPlanilha() {
    if (swalData && swalData !== undefined) {
      const response = await ajax({ url: '/planilhas/' + swalData, method: 'delete' });
      if (response.success && response.message) toast.success(response.message);
      handleSetFilter(false);
    }
    handleSwalClosed();
  }

  function handleSwalClosed() {
    setSwalData(null);
    setWal('closed');
  }

  function handleClose() {
    // e.preventDefault();
    setIsModal(false);
  }

  React.useEffect(() => {
    setLoading(false);

    async function loadpage() {
      // const res = await ajax({url: "/planilhas"});
      const resMt = await ajax({ url: '/users?user_function=deposito' });
      // if(res.rows) setResult(res.rows);
      if (resMt) setMotorista(resMt.rows);

      if (fields.limit !== undefined) {
        const localPlanilhaFilterSettings = JSON.parse(localStorage.getItem('@planilhaFilter')) || null;
        if (localPlanilhaFilterSettings) {
          fields.data_planilha.value = localPlanilhaFilterSettings.data;
          fields.motorista.value = localPlanilhaFilterSettings.motorista;
          fields.limit.value = localPlanilhaFilterSettings.limit;
        }
      }
    }

    // loadpage();

    if (fields.limit !== undefined) loadpage();
  }, [fields]);

  if (loading) return <div></div>;

  return (
    <>
      <Content>
        <ContainerContent>
          <div className="container__header">
            <Flex className="shadow">
              <Input col={3} xs={9} text="Pedido.." name="search" />
              <Column col={3} xs={3}>
                <Row>
                  <ButtonRow name="btnSearch" src={MiniSearch} onClick={handleSearch} alt="mini" />
                  <ButtonRow className="mini" src={MiniAlter} onClick={handleTrocaPlanilha} disabled={disTroca} />
                  <ButtonRow className="mini" src={MiniPrint} onClick={handlePrint} disabled={disPrint} />
                  <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter} />
                </Row>
              </Column>
            </Flex>
          </div>
          <div className="container__content">
            {result.length > 0 &&
              result.map((item) => {
                return (
                  <TableDiv key={item.id} className="_animated-easy-out--left">
                    <TableHead>
                      <Div col={6}>
                        <Paragraph>
                          Motorista: <span>{item.motorista}</span>
                        </Paragraph>
                        <Paragraph>
                          Montador: <span>{item.montador}</span>
                        </Paragraph>
                      </Div>
                      <Div col={6} className="flexEnd">
                        <Paragraph>
                          Data: <span>{formatDataRegex(item.data_entrega)}</span>
                        </Paragraph>
                      </Div>
                    </TableHead>
                    {item.planilhas.map((planilha) => {
                      return (
                        <TableBody key={planilha.id}>
                          <Div col={5}>
                            <Div className="row nopad">
                              <Paragraph>
                                Ficha: <span>{planilha.ficha}</span>
                              </Paragraph>
                              <Paragraph>
                                Pedido: <span>{planilha.pedido}</span>
                              </Paragraph>
                              <RowTableItem>
                                <input type="checkbox" defaultValue={planilha.id} onClick={handleTroca} />
                                <a href={`/planilhas/registro/${item.id}/${planilha.id}`}>
                                  <FiEdit2 size={17} />
                                </a>
                                <span data-id={planilha.id} onClick={handleRemovePlanilha}>
                                  <FiTrash2 size={17} />
                                </span>
                              </RowTableItem>
                              {/* <Flex col={6}>
                                                        <FilterLabel className="list" col={1}  type="checkbox" defaultValue={planilha.id} onClick={handleTroca}/>
                                                        <a href={`/planilhas/registro/${item.id}/${planilha.id}`} className="action"><FiEdit size={17} /></a>
                                                        <a href={`/planilhas/registro/${item.id}/${planilha.id}`} className="action"><FiTrash2 size={17} /></a>
                                                    </Flex>                                                                   */}
                            </Div>
                            <Paragraph>
                              Cliente: <span>{planilha.client_name}</span>
                            </Paragraph>
                            <Paragraph>
                              Endereço: <span>{planilha.endereco}</span>
                            </Paragraph>
                            <Paragraph>
                              Cidade: <span>{planilha.cidade}</span>
                            </Paragraph>
                            <Paragraph>
                              Bairro: <span>{planilha.bairro}</span>
                            </Paragraph>
                          </Div>
                          <Div col={7}>
                            {planilha.planilhaProduct.map((prod) => {
                              return (
                                <Div className="row between" key={prod.id}>
                                  <Paragraph wd={'90px'}>
                                    Ref: <span>{prod.product_id}</span>
                                  </Paragraph>
                                  <Paragraph className="flexOne">
                                    Produto: <span>{prod.product}</span>
                                  </Paragraph>
                                  <Paragraph>
                                    Deposito: <span>{prod.deposit}</span>
                                  </Paragraph>
                                </Div>
                              );
                            })}
                          </Div>
                          <Div>
                            <Paragraph className="notOverFlow textArea">{planilha.observations}</Paragraph>
                          </Div>
                        </TableBody>
                      );
                    })}
                  </TableDiv>
                );
              })}
          </div>
          <Filter>
            <header>
              <span>
                <FiFilter size={22} /> Filtros
              </span>
              <FiXSquare size={30} onClick={handleFilter} />
            </header>
            <div style={{ marginTop: '1rem' }}>
              <Input
                col={12}
                defaultValue={todayAddDay({ date: today(), days: 0, format: 'dd/mm/yyyy' })}
                text="Data planilha"
                name="data_planilha"
              />
              <Select label="Motorista.." col={12} name="motorista">
                <option value="" style={{ display: 'none' }}>
                  Selecione...
                </option>
                {motorista &&
                  motorista.map((item) => {
                    return (
                      <option key={item.id} value={item.name.split(' ')[0]}>
                        {item.name.split(' ')[0]}
                      </option>
                    );
                  })}
              </Select>
              <Select label="Itens por pagina.." col={12} name="limit">
                <option value={100} style={{ display: 'none' }}>
                  100
                </option>
                <option value={20}>20</option>
                <option value={40}>40</option>
                <option value={80}>50</option>
              </Select>
            </div>
            <main className="filter-inputs">
              <GroupList>
                <h2>Filtrar por ficha..</h2>
                <FilterLabel col={6} type="radio" name="ficha" value={true} text="Ficha" />
                <Input col={8} text="" name="ficha_search" />
              </GroupList>
              <button onClick={handleSetFilter}>
                <FiFilter size={22} />
                <span>Aplicar filtro</span>
              </button>
              <button onClick={handleReset} className="reset">
                <FiFilter size={22} />
                <span>Resetar filtro</span>
              </button>
            </main>
          </Filter>
        </ContainerContent>
      </Content>
      <Modal
        title="Alterações de entrega"
        close={handleClose}
        is={isModal}
        lgW="30%"
        lgH="60%"
        mdW="40%"
        mdH="60%"
        xsW="90%"
        xsH="80%"
      >
        <div className="content__main">
          <ContentModal>
            <Row>
              <Input
                col={12}
                defaultValue={todayAddDay({ date: today(), days: 0, format: 'dd/mm/yyyy' })}
                text="Data planilha"
                name="data_troca"
              />
              <Select label="Motorista.." col={12} name="troca_motorista">
                <option value="" style={{ display: 'none' }}>
                  Selecione...
                </option>
                {motorista &&
                  motorista.map((item) => {
                    return (
                      <option key={item.id} value={item.name.split(' ')[0]}>
                        {item.name.split(' ')[0]}
                      </option>
                    );
                  })}
              </Select>
              <MultSelect label="Montador.." col={12} name="troca_montador">
                <option value="" style={{ display: 'none' }}>
                  Selecione...
                </option>
                {motorista &&
                  motorista.map((item) => {
                    return (
                      <option key={item.id} value={item.name.split(' ')[0]}>
                        {item.name.split(' ')[0]}
                      </option>
                    );
                  })}
              </MultSelect>
            </Row>
            <Row>
              <ButtonAction type="submit" src={buttonSend} alt="button send" onClick={handleSubmitTroca} />
              <ButtonAction type="reset" src={buttonReset} alt="button Reset" />
            </Row>
          </ContentModal>
        </div>
      </Modal>

      <Wal className={wal}>
        <div className="content">
          <h2>Deseja remover essa planilha?</h2>
          <div className="action">
            <button className="success" onClick={handleSubmitDestroyPlanilha}>
              Sim
            </button>
            <button className="cancel" onClick={handleSwalClosed}>
              Cancelar
            </button>
          </div>
        </div>
      </Wal>
    </>
  );
}

const Wal = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;

  &.open {
    // rgb(158,155,238);
    z-index: 100;
    opacity: 1;
  }

  & .content {
    padding: 3rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: -1px 2px 10px rgba(0, 0, 100, 0.3);

    h2 {
      color: #666;
      margin-bottom: 5px;
    }
  }

  & .action {
    display: flex;
    align-items: center;

    button {
      margin-right: 5px;
      padding: 0.5rem 2rem;
      border: none;
      border-radius: 3px;
      color: #fff;

      &.success {
        background-color: green;
      }

      &.cancel {
        background-color: red;
      }
    }
  }
`;

const ContentModal = styled.div`
  padding: 30px 10px;
`;

// const Label = styled.label`
// width: calc(100% / 12 * ${props => props["col"]});

// @media only screen and (min-width: 75em) {
//     width: calc(100% / 12 * ${props => props["lg"]});
// }

// @media only screen and (max-width: 75em) {
//     width: calc(100% / 12 * ${props => props["md"]});
// }

// @media only screen and (max-width: 50em) {
//     width: 100%;
//     width: calc(100% / 12 * ${props => props["xs"]});
// }
// `;

const RowTableItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 5px;
  & input {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 2px;
    cursor: pointer;
  }

  & span,
  & a {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      background-color: #d8ddf9;
      border-radius: 2px;
    }

    svg {
      stroke: #777;
      pointer-events: none;
    }
  }
`;

const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    color: #7483be;
  }
  label {
    width: auto !important;
  }
`;

const TableDiv = styled.div`
  box-shadow: 0 0 10px #436d932e;
  border-radius: 5px;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const TableHead = styled.div`
  background-color: rgb(158, 155, 238); //#7663ff
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  color: #fff;
`;

const TableBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  color: #7b7b7b;
  &:not(:last-child) {
    border-bottom: 4px solid #edf1f8;
  }
`;

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  margin-right: 5px;
  .container__header {
    padding: 5px;
  }
  .container__content {
    flex: 1;
    padding: 5px;
    overflow: auto;
    scrollbar-width: thin;
  }
  .container__footer {
  }
`;

const Div = styled.div`
  width: calc(100% / 12 * ${(props) => props['col']});

  &.flexEnd {
    display: flex;
    justify-content: flex-end;
  }

  &.row {
    display: flex;
    align-items: center;

    &.between {
      justify-content: space-between;
    }
  }

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${(props) => props['xs']});
  }
`;

const Paragraph = styled.p`
  width: ${(props) => props['wd']};
  font-weight: 600;
  padding: 1px 5px;
  color: currentcolor; //718096;
  font-size: 0.8rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: initial;
  }

  &.flexOne {
    flex: 1;
  }

  &.notOverFlow {
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
  }

  &.textArea {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #9b9b9b;
    background-color: #f4f4f4;
    padding: 7px 1rem 7px 5px;
    border-radius: 3px;
  }
`;

const Filter = styled(({ children, ...props }) => (
  <div id="filter" className={props.className} onClick={props.onClick}>
    {children}
  </div>
))`
  position: absolute;
  top: 8px;
  bottom: 8px;
  width: 22rem;
  background-color: #fff;
  box-shadow: -1px 2px 10px rgba(0, 0, 100, 0.3);
  border-radius: 8px;
  right: -30rem;
  transition: right 0.4s ease-in-out;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #7f808a;
    color: #fff;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.2rem;
    user-select: none;
    & span {
      display: flex;
      align-items: center;
    }
    & svg {
      margin-right: 1rem;
    }
  }

  main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    overflow: auto;

    & label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 5px 1rem;
      cursor: pointer;
      font-size: 0.9rem;
      user-select: none;

      &.column {
        flex-direction: column;
        align-items: flex-start;
      }

      & input[type='text'] {
        width: 100%;
        height: 2.5rem;
        border-radius: 4px;
        border: 1px solid #cecece;
        padding: 0 1rem;
        font-size: 1.1rem;
        font-family: inherit;
      }

      & input[type='radio'] {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      & span {
        margin-left: 5px;
      }
    }

    & button {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      color: #f6f8ff;
      background-color: #2885fd;

      & span {
        flex: 1;
      }

      &.reset {
        background-color: #ff5f5f;
      }
    }
  }

  &.open {
    right: 8px;
  }
`;
