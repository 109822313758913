import React from "react";

export const FormContext = React.createContext({});

export default function FormProvider({children}) {
    
    const [fields, setFields] = React.useState({});

    const registerField = React.useCallback((name, ref) => {    
        // console.log(name, ref)     
        setFields((oldInputs) => {return  {...oldInputs, [name]: ref}});        
    }, []);

    return(
        <FormContext.Provider value={{registerField, fields}}> 
            {children}
        </FormContext.Provider>
    )
};
