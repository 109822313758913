import React from "react";


//**Toastfy alert */
import { toast } from 'react-toastify';
import {GlobalContext} from "./global";

import {ajax, formData} from '../api/chequer';

const AuthContext = React.createContext({});


export const AuthProvider = ({children, socket}) => {
    const {setButtonName, setProcessText} = React.useContext(GlobalContext);
    
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const updateRolePermission = React.useCallback((socket_user)=> {
        const roleString = []; 
        const storageUser =  JSON.parse(localStorage.getItem("systemUser")) || false;
        
        if(storageUser.id === socket_user.id) {            
            localStorage.removeItem("user@roles");
            socket_user.roles && socket_user.roles.forEach(role => { 
                roleString.push(role.name.toLowerCase().replace(/\s/g, ''));
            });

            socket_user.permissions && socket_user.permissions.forEach(permission => { 
                roleString.push(permission.name.toLowerCase().replace(/\s/g, ''));
            });
            
            localStorage.setItem("user@roles", roleString.toString());
        }       
         
    }, [])

    React.useEffect(()=>{
        socket.on("client:update_user", (data)=> {
            updateRolePermission(data);        
        })
      }, [socket, updateRolePermission]);

    React.useEffect(()=>{
        async function loadStorageData() {
            const storageUser = await JSON.parse(localStorage.getItem("systemUser")) || null;
            if(storageUser) setUser(storageUser);
            setLoading(false);
        }
        // socket.on("user_update", (data) => {
        //     console.log(data)
        // })
        loadStorageData();
    }, []); 

    function storageUser(user) {
        return new Promise(resolve => {
            localStorage.setItem("systemUser", JSON.stringify(user));
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }     

    async function login(email, password){  
        const interval = startWait("Aguarde");
        const params = [
            {name: "email", value: email},
            {name: "password", value: password},
        ];
        const body = await formData(params);

        const response = await ajax({url: "/login", method: "post", body});

        if(response.success && response.user) {  
            await storageUser(response.user);              
            const roleString = []; 

            response.user.roles && response.user.roles.forEach(role => { 
                roleString.push(role.name.toLowerCase().replace(/\s/g, ''));
            });

            response.user.permissions && response.user.permissions.forEach(permission => { 
                roleString.push(permission.name.toLowerCase().replace(/\s/g, ''));
            });
           
            localStorage.setItem("user@roles", roleString.toString());            

            setTimeout(() => {
                setButtonName("Success!"); 
            }, 2000);

            setTimeout(() => {
                setUser(response.user);                
                clearInterval(interval); 
                toast.success(`Bem-vindo ${response.user.name.split(" ")[0]}`);
            }, 3000);

            return false;
        }   

        if(response.error) {
            toast.error(response.error);
            clearInterval(interval); 
            setButtonName("Error!"); 
        }

        if(response.message) {
            toast.error(response.message);
            clearInterval(interval); 
            setButtonName("Error!");           
        }

        setTimeout(() => {                
            setButtonName("Entrar");
        }, 3000);             
    } 

    //** Blob image canvas to save mysql importante para o upload */
    const dataURItoBlob = (dataURI, mime = "image/png") => {
        let byteString = atob(dataURI.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {
            type: mime
        });
    }

    //Update profile user
    async function userUpdate(data){ 
        setProcessText(true); 
        const params = []; 

        function setFileUpload() {
            return new Promise(resolve => {
                var canvas = document.createElement("canvas");
                var context = canvas.getContext("2d");
                const image = new Image();       
                image.src = URL.createObjectURL(data.file);
                image.onload = async () => {  
                    const imageWidth = image.width       
                    const imageHeight = image.height 
                    canvas.width = 200;

                    // set size proportional to image
                    canvas.height = canvas.width * (imageHeight / imageWidth); // aspect ratio to image

                    context.drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, canvas.width, canvas.height);
                    const mime = "image/png";
                    const fileURL = canvas.toDataURL(mime, 1.0);  
                    params.push({name: "file", value: dataURItoBlob(fileURL, mime)});            
                    // Create download click
                    // const link = document.createElement("a");
                    // link.href = fileURL;
                    // link.download = "resize";
                    // link.click();
                    resolve();                    
                }
            })
        }

        if(data.file) await setFileUpload();
        if(data.password) params.push({name: "password", value: data.password});

        //**Serializa os dados */
        const body = await formData(params);
        
        //**Envai dos dados para a api */
        const response = await ajax({url: `/users/profile/${user.id}`, method: "put", body}); 
        
        if(response.success && response.user) {                 
            const {token} = JSON.parse(localStorage.getItem("systemUser")); 
            await storageUser({...response.user, token});
            setUser(response.user);
            setTimeout(() => {
                setProcessText(false); 
                toast.success("Profile atualizado com sucesso!"); 
            }, 2000);           
        } 
    }

    /*Use ex:
    * var ratio = (16/9);
    * height = getHeight(200,ratio);
    * var width = getWidth(height,ratio);
    */
    // function getHeight(length, ratio) {
    //     var height = ((length)/(Math.sqrt((Math.pow(ratio, 2)+1))));
    //     return Math.round(height);
    // }

    // function getWidth(length, ratio) {
    //     var width = ((length)/(Math.sqrt((1)/(Math.pow(ratio, 2)+1))));
    //     return Math.round(width);
    // }

    function logout(){
        setUser(null);        
        localStorage.clear();
        // caches.delete(/*name*/);
        // caches.keys().then(function(names) {
        //     for (let name of names)
        //         caches.delete(name);
        // });
    }
    
    //**Start animate button ... */
    function startWait(text="Aguarde.") {
        let ponto = ".";
        setButtonName(text);  
        return setInterval(() => {
            setButtonName(`${text}${ponto}`);            
            ponto += ".";
            if(ponto === ".....") ponto = "";
        }, 1000);
    }

    if(loading) return(
        <div></div>
    )

    return(
        <AuthContext.Provider value={{signed: !!user, user, login, logout, userUpdate}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;