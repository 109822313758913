/* eslint-disable no-undef */
import { toast } from 'react-toastify';

let baseURL = process.env.REACT_APP_API_KEY;

const selector = (el, all = false) => {
  if (all) return document.querySelectorAll(el);
  return document.querySelector(el);
};

const formData = async (params) => {
  const formData = new FormData();
  const types = {
    file(input) {
      if (input.files.length > 1) {
        [...input.files].forEach((file) => {
          console.log(file);
        });
      } else {
        formData.append(input.name, input.files[0]);
      }
    },
    text(input) {
      formData.append(input.name, input.value);
    },
    radio(input) {
      formData.append(input.name, input.value);
    },
    checkbox(input) {
      formData.append(input.name, input.value);
    },
    password(input) {
      formData.append(input.name, input.value);
    },
    date(input) {
      formData.append(input.name, `${input.value}T03:00:00.000Z`);
    },
  };

  const res = await new Promise((resolve) => {
    [...params].forEach((field) => {
      if (field.tagName && field.tagName.toLowerCase() === 'input') {
        console.log(field);
        if (field.name && field.type !== 'file' && field.value.trim() !== '') {
          types[field.type](field);
        } else {
          types[field.type](field);
        }
      } else if (field.type === 'file') {
        const files = field.value;
        formData.append(field.name, files[0]);
      } else {
        formData.append(field.name, field.value);
      }
    });

    resolve(formData);
  });

  return await res;
};

const serialize = async (params) => {
  const data = {};
  const duplicate = [];
  let pushed = [];
  const types = {
    checkbox(input) {
      const inc = input.name.trim().includes('[]');
      if (inc) {
        if (duplicate.indexOf(input.name) === -1) {
          duplicate.push(input.name);
          const name = input.name.substring(0, input.name.length - 2);
          const query = `input[name='${input.name}']`;
          const lists = selector(query, true);
          for (const list of lists) {
            if (list.type === 'checkbox') {
              if (list.checked) {
                pushed.push(list.value);
              }
            } else {
              pushed.push(list.value);
            }
          }
          data[name] = pushed;
          pushed = [];
        }
      } else {
        data[input.name] = input.value;
      }
    },
    radio(input) {
      if (input.checked) {
        data[input.name] = input.value;
      }
    },
    date(input) {
      if (input.checked) {
        data[input.name] = `${input.value}T03:00:00.000Z`;
      }
    },
    text(input) {
      data[input.name] = input.value;
    },
    password(input) {
      data[input.name] = input.value;
    },
    hidden(input) {
      data[input.name] = input.value;
    },
    file(input) {
      data[input.name] = input.value;
    },
  };
  const res = await new Promise((resolve) => {
    [...params].forEach((field) => {
      if (field.name && field.name.trim() !== '' && field.type === 'file') {
        types[field.type](field);
      } else if (field.name && !field.type) {
        if (field.name && field.name.trim() !== '' && field.value !== '') {
          types.text(field);
        }
      } else {
        if (field.name && field.name.trim() !== '' && field.value !== '') {
          types[field.type](field);
        }
      }
    });
    resolve(JSON.stringify(data));
  });

  return await res;
};

const ajax = async (obj) => {
  let { url, method, body } = obj;
  if (body === undefined || body === '') body = null;

  if (method === undefined || Object.keys(method).length === 0) {
    method = 'GET';
  } else {
    method = method.toUpperCase();
  }

  const headers = new Headers();

  if (obj.json) {
    headers.append('Content-Type', 'application/json;charset=utf-8');
    headers.append('Accept', 'application/json');
  }

  async function getStorageToken() {
    return new Promise((resolve) => {
      const storageUser = JSON.parse(localStorage.getItem('systemUser')) || null;
      resolve(storageUser);
    });
  }

  const user = await getStorageToken();

  if (user) {
    // headers.set("Authorization", `Bearer ${user.token}`);
    headers.append('X-Token', `Bearer ${user.token}`);
  }

  // if (method === "POST") headers.set('Content-Type', 'application/json; charset=utf-8');

  if (method === 'GET') {
    headers.append('Content-Type', 'application/json;charset=utf-8');
    headers.append('Accept', 'application/json');
  }

  return new Promise((resolve) => {
    async function fetchPromise() {
      fetch(`${baseURL}${url}`, {
        method,
        mode: 'cors',
        headers,
        body: body,
      })
        .then(async (res) => {
          const json = await res.json();
          // console.log(res, "api response result");
          // console.log(json, "json status error da api");

          //console.log(res);

          if (res.status === 200 || res.status === 201) {
            resolve(json);
          }

          if (res.status === 500) {
            //**JWT TOKEN EXPIRED */
            if (!json.auth && json.message === 'Failed to authenticate token.') {
              localStorage.removeItem('systemUser');
              toast.info('Sessão expirada..');
              setTimeout(() => {
                window.location.href = '/login';
              }, 4000);

              return false;
            }

            if (res.statusText === 'Internal Server Error') {
              resolve({ message: 'Erro no servidor contactar o administrador..' });
            }

            return false;
          }

          //**ERROR BAD REQUEST */
          if (res.status === 400 || res.status === 404) {
            return resolve(json);
          }

          if (res.status === 401) {
            setTimeout(() => {
              window.location.href = '/login';
            }, 2000);
          }
        })
        .catch((error) => {
          if (error) {
            // admin@gmail.com
            if (String(error).match(/NetworkError when attempting to fetch resource/)) {
              resolve({ error: 'Servidor offline' });
            }
          }
        });
    }
    fetchPromise();
  });
};

export { ajax, serialize, formData };
