import React from 'react';
import styled from 'styled-components';

//**Components */
import Content from "../../components/Content";

export default function Error401(){
    return(
        <Content>
            <DivError401>
                <div>
                    <h2>401</h2>
                    <span>Você não tem permissão para acessar essa pagina =(</span>
                </div>
            </DivError401>
        </Content>
    )
}


const StyleError = ({className, children}) => (
    <div className={className}>        
        {children}
    </div>
)

const DivError401 = styled(StyleError)`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
font-weight: 700;
font-size: 10rem;
color: #1c3faa;
div{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #9fbbff;
    padding: 3rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px #9fbbff;

    h2 {
        line-height: 0.8;
    }

    span{
        font-size: 1rem;
        font-weight: 400;
    }

    @media only screen and (max-width: 50em) {
        border: none;
        box-shadow: none;
        h2{
            font-size: 8rem;
        }

        span{
            font-size: 0.7rem;
        }
    }
}
`;
