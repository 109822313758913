import React, {Fragment} from "react";
import styled from 'styled-components';

const LabelStyle = ({className, children}) => (
    <label className={className}>        
        {children}
    </label>
)

export const Img = ({...props}) => (
    <Fragment>
        <img {...props} alt={props.alt}/> 
    </Fragment>  
)

export const Label = styled(LabelStyle)`
    /* background: url('../../assets/cover.png');
    background-color: #181c2e;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    position: relative;
    width: 14rem;
    height: 14rem;
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;

    div {
        color: #fff;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

    }
    
    img {
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        -webkit-filter: drop-shadow(0 0 6px #1d2aec);
        filter: drop-shadow(0 0 6px #1d2aec);
        object-fit: cover;      
    }

    input {
        display: none;
    }

    svg {
        position: absolute; 
        top: 29%;       
        opacity: 0.5; 
        transition: transform 0.2s ease-in-out;  
        &:hover {
            transform: scale(1.2);
        }    
    }

    div h3{
        color: #fff;
        font-size: 1rem;
        margin-left: 1rem;
        width: 100%;
    }

    div span {
        margin-left: 1rem;
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 7rem;
    }    
`;