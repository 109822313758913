/* eslint-disable no-unused-vars */
import React from 'react';

export default function useStore() {
  const [name, setName] = React.useState('');
  const [status, setStatus] = React.useState(true); 
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [user_function, setUserFunction] = React.useState('');
  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  const [userPermissions, setUserPermissions] = React.useState([]);

  const submit = [
    { name: 'status', value: status },
    { name: 'password', value: password },    
    { name: 'name', value: name },
    { name: 'email', value: email },    
    { name: 'user_function', value: user_function },    
    { name: 'roles', value: roles },
    { name: 'permissions', value: permissions },
  ];

  const form = {
    name,
    password,   
    status,    
    email,    
    user_function,    
    userRoles,
    userPermissions,
  };

  function setState(data) {  
    setName(data.name || '');
    setPassword(data.password);
    setStatus(data.status || true);   
    setEmail(data.email || '');   
    setUserFunction(data.user_function || '');   
    setUserRoles(data.roles ? data.roles.map((item) => item.id) : []);
    setUserPermissions(data.permissions ? data.permissions.map((item) => item.id) : []);
  }

  const state = {
    name: setName,
    password: setPassword,
    status: setStatus,  
    email: setEmail,     
    user_function: setUserFunction,   
    roles: setRoles,
    permissions: setPermissions,
  };

  const options = {
    workStatus: [
      { name: 'Trabalhando', value: 'null' },
      { name: 'Atestado', value: 'atestado' },
      { name: 'Férias', value: 'férias' },
      { name: 'Folga', value: 'folga' },
      { name: 'Licença', value: 'licença' },
      { name: 'Liberado', value: 'liberado' },
    ],

    resetPassword: [
      { name: 'Não', value: false },
      { name: 'Sim', value: true },
    ],

    status: [      
      { name: 'Ativo', value: 'true' },
      { name: 'Inativo', value: 'false' },
    ],   

    user_functions: [
      // { name: 'Técnico Regional', value: 'tecnico_regional' },
      { name: 'Supervisor / Coordenador Regional', value: 'supervisor_regional' },
      { name: 'Diretor Técnico', value: 'diretor_técnico' },
      { name: 'Diretor Financeiro', value: 'diretor_financeiro' },
      { name: 'Produção Técnica', value: 'produçao_técnica' },
      { name: 'Produção Executiva', value: 'produçao_executiva' },
      { name: 'Gestor', value: 'gestor' },
    ],

    user_regions: [
      { name: 'Norte', value: 'norte' },
      { name: 'Noroeste', value: 'noroeste' },
      { name: 'Sul', value: 'sul' },
      { name: 'Centro', value: 'centro_oeste' },
      { name: 'Serrana', value: 'serrana' },
      { name: 'Escritório', value: 'escritorio' },
    ],
  };

  const setCallbackRoles = React.useCallback((data) => {
    setState(data);
  }, []);

  const handleCheckRole = React.useCallback(async () => {  
    const wait = new Promise((resolve) => {
      const domRoles = document.querySelectorAll('#roles input');
        [...domRoles].forEach((item) => {
          if (userRoles.indexOf(+item.value) !== -1) {
            item.checked = true;
            setRoles((oldValue) => [...oldValue, +item.value]);       
          }
        }); 
        resolve(true)      
      })
    return await wait; 
  }, [userRoles]);

  const handleCheckPermissions = React.useCallback(async() => { 
    const wait = new Promise((resolve) => {
      const domP = document.querySelectorAll('#permissions input');
      [...domP].forEach((item) => {
        if (userPermissions.indexOf(+item.value) !== -1) {
          item.checked = true;
          setPermissions((oldValue) => [...oldValue, +item.value]);       
        }
      });  
      resolve(true)       
    })  
    return await wait; 
    
  }, [userPermissions]);  

  const reset = React.useCallback(()=> {
    setName('');
    setPassword('')
    setStatus(true);    
    setEmail('');    
    setUserFunction('');
  }, []);  

  return {
    form,
    submit,
    state,
    options,
    setCallbackRoles,
    handleCheckRole,
    handleCheckPermissions,
    reset,
  };
}
