// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/boxlogin2.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leftbox {\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-size: 100% 100%;\r\n}", "",{"version":3,"sources":["webpack://src/views/login/style.css"],"names":[],"mappings":"AAAA;IACI,mDAAoD;IACpD,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".leftbox {\r\n    background: url(\"../../assets/images/boxlogin2.png\");\r\n    background-repeat: no-repeat;\r\n    background-size: 100% 100%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
