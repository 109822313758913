import React from "react";
import styled from "styled-components";
import Moment from "../../../services/Moment";
import Utils from "../../../services/utils";

import {Table, Thead, Tbody} from '../../../components/Table';

import {FiEdit2} from 'react-icons/fi'; //FiSearch

export default function TableRender({dataSet, editAction}) {
    const {splitName} = Utils();
    const {stringWeekDayMonth} = Moment();  

    function Svg(props) {
        var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
        return <svg className={props.className} dangerouslySetInnerHTML={{__html: useTag }} />;
    }

    return(
        <Table>
            <Thead>
                <tr className="">
                    <th scope="col">Nome</th>
                    <th scope="col" className="center">Data</th>                                                    
                    <th scope="col" className="center">Entrada</th>                                                    
                    <th scope="col" className="center">Inicio Int.</th>                                                    
                    <th scope="col" className="center">Volta Int.</th> 
                    <th scope="col" className="center">Saida</th> 
                    <th scope="col" className="center">Trabalhou</th> 
                    <th scope="col" className="center">Seconds</th> 
                    <th scope="col" className="center">Extra</th> 
                    <th scope="col" className="center">#</th> 
                </tr>
            </Thead>
            <Tbody> 
                {dataSet && dataSet.rows.map((item) => (
                    <tr key={item.id} data-item={item.id}>
                        <td data-label="cliente"><span className="mob">Pedido</span><p>{splitName(item.user.name, 2)}</p></td>
                        <td data-date={item.data} className="center" data-label="Assunto"><span className="mob">Ficha</span>{stringWeekDayMonth(item.data)}</td>
                        <td data-item={item.entry} className="green-light">
                            <span className="mob">Entrada</span>
                            <TdFlex className="center">
                                <Svg icon="icon-entrada"/>
                                <p>{item.entry}</p>
                            </TdFlex>                            
                        </td>  
                        <td data-item={item.interval_start ? item.interval_start : "00:00:00"} className="color-indigo">
                            <span className="mob">Inicio Int</span>
                            <TdFlex className="center">
                                <Svg icon="icon-intsaida"/>
                                <p>{item.interval_start ? item.interval_start : "00:00"}</p>
                            </TdFlex>   
                        </td> 
                        <td data-item={item.interval_back ? item.interval_back : "00:00:00"} className="color-orange">
                            <span className="mob">Volta Int</span>
                            <TdFlex className="center">
                                <Svg icon="icon-intvolta"/>
                                <p>{item.interval_back ? item.interval_back : "00:00"}</p>
                            </TdFlex>
                        </td> 
                        <td data-item={item.output ? item.output : "00:00:00"} className="color-teal">
                            <span className="mob">Saida</span>
                            <TdFlex className="center">
                                <Svg icon="icon-saida"/>
                                <p>{item.output ? item.output : "00:00"}</p>
                            </TdFlex>
                        </td> 
                        <td className="center" data-label="Cliente"><span className="mob">Trabalhou</span>{item.workend_hour ? item.workend_hour : "00:00"}</td> 
                        <td className="center" data-label="Cliente"><span className="mob">Seconds</span>{item.extra_in_seconds ? item.extra_in_seconds : "00:00"}</td>                            
                        <td className="center" data-label="Cliente"><span className="mob">Extra</span>{item.extra_hour ? item.extra_hour : "00:00"}</td>  
                        <td className="nowrap center">
                                <span className="mob">Action</span>
                                <button className="action" style={{backgroundColor: '#f6a935'}} onClick={editAction}><FiEdit2 color='#fff' size={17}/></button>
                                {/* <button className="action" style={{backgroundColor: '#3583f6'}}><FiSearch color='#fff' size={17}/></button> */}                                
                        </td>                         
                    </tr>
                ))}                                                                            
            </Tbody>
        </Table>
    )
}

const TdFlex = styled.div`
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;
        @media only screen and (max-width: 50em) {
            justify-content: initial;
        }
    }

    svg {        
        max-width: 1.6rem;
        max-height: 1.6rem;
        margin-right: 5px;
    }
`;