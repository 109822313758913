import {isMobile} from 'react-device-detect';

export default function handleToggle(e){  
    const el = e.target;   
    if(el.matches(".item") || el.matches(".link")) {
        const dataLink = el.dataset.link;
        localStorage.setItem("@navigateLink", dataLink)       
    }
    if (isMobile) {            
        const root = document.getElementById("root");
        root.classList.remove("active");           
    }   
}