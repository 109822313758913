import React, { Component } from 'react';
import {GlobalContext} from '../contexts/global';

class Loading extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);            
        this.state = {            
            status: props.status || false
        };

        this.loading = null;
    }    

    change() {
        const {state} = this;
        this.setState(state);
        return state;
    }

    down(){
        const {loading} = this;
        let op = 1.5; // initial opacity
        let timer = setInterval( function () {
            if (op <= 0.1) { clearInterval(timer); loading.classList.remove("open"); return false;}
            loading.style.opacity = op;                   
            op -= op * 0.2;           
        }, 50 );
    }

    up(){        
        const {loading} = this;
        let op = 0.5; // initial opacity
        let timer = setInterval( function () {
            if (op <= 1.5) { clearInterval(timer); return false;}
            loading.style.opacity = op;                   
            op += op * 0.2;           
        }, 50 );
    }

    componentDidMount(){ 
        this.loading = document.querySelector(".loading");
        if(this.state.status === "start")  this.down();
    }
    
    render() {
        return(
            <div>            
                <div className="loading open">
                    <div className="lds-ripple">
                        <div></div>                            
                        <div></div>                            
                    </div>                            
                </div>
            </div>
        )
    }
}

export default Loading;