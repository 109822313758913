import React from 'react';
import Content from '../../components/Content';
import {Label, Img} from "./Stylesheets";

import buttonPrint from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';

import AuthContext from '../../contexts/auth';

import Avatar from "../../assets/images/profilePic.jpeg";
import {FiSettings, FiUpload} from 'react-icons/fi';

// import {ajax, serialize} from '../api/chequer';

// import Mask from '../services/mask';

import { toast } from 'react-toastify';

export default function Profile(){    
    return(
        <Content>
            <ProdileContent/>
        </Content>
    )
}

function ProdileContent(){ 
    const {user, userUpdate} = React.useContext(AuthContext);

    const [name, setName] = React.useState(user.name);
    const [email, setEmail] = React.useState(user.email);
    const [password, setPassword] = React.useState("");
    const [passwordConfirm, setPasswordConfirm] = React.useState("");

    // const [text, setText] = React.useState("");

    const [uploadAvatar, setUploadAvatar] = React.useState(null);
    const [avatarUrl, setAvatarUrl] = React.useState(!user.avatar ? null : user.avatar);

    const handle = {
        name: setName,
        email: setEmail,
        password: setPassword,
        passwordConfirm: setPasswordConfirm
    }

    function InputTextChange(e){
        const name = e.target.name;
        const value = e.target.value;
        handle[name](value);
    }

    function handleFile(e){
        e.preventDefault();
        const file = e.target.files[0];        

        if(file && (file.type === "image/jpeg" || file.type === "image/png")) {
            setUploadAvatar(file);
            setAvatarUrl(URL.createObjectURL(file)); 
        }else{
            toast.warn("Envie uma imagem do tipo PNG ou JPEG");
            setAvatarUrl(null);
            return false;
        }     
    }

    function handleSubmit(e){
        e.preventDefault();  
        const sending = {};    
        if(password && passwordConfirm) {
            if(password !== passwordConfirm) return toast.warn("Senha não confere..")
            sending.password = password;
            setPassword("");
            setPasswordConfirm("");
        }

        if(uploadAvatar !== null) {
            sending.file = uploadAvatar;  
            setUploadAvatar(null);          
        }   
        if(sending.file || sending.password) {
            userUpdate(sending);
        }        
    }

    return(
        <div className="container _animated-easy-out--left">
            <form id="mask-products" className="panel-row" onSubmit={handleSubmit}>  
                <Label>
                    <Img src={!avatarUrl ? Avatar : avatarUrl} alt="User Avatar"/>
                    <FiUpload color="#fff" size={55}/>
                    <input type="file" name="file" accept="image/*" onChange={handleFile}/>                    
                </Label>
                <div className="row subtitle"> 
                    <h5><FiSettings />Meus dados</h5>
                </div>                             
                <div className="row">   
                    <Group cols="col-6" name="name" value={name} text="Nome.." onChange={InputTextChange} readOnly/>
                </div> 
                <div className="row">   
                    <Group cols="col-6" name="email" value={email} text="Email.." onChange={InputTextChange} readOnly/>
                </div> 
                <div className="row">   
                    <Group cols="col-6" text="Senha.." type="password" name="password" value={password} onChange={InputTextChange} />                    
                </div>
                <div className="row">
                    <Group cols="col-6" text="Confirme a senha.." type="password" name="passwordConfirm" value={passwordConfirm} onChange={InputTextChange} />                   
                </div>
                
                <div className="row">
                    <div className="btn-row col-12">
                        <button className="btn-row__btn">
                            <img src={buttonPrint} alt="Button add mini"/>
                        </button>  
                        <button className="btn-row__btn" style={{marginLeft: "5px"}}>
                            <img src={buttonReset} alt="Button add mini"/>
                        </button>                                              
                    </div>
                </div>                                
            </form>
        </div>
    )
}

const Group = ({...props}) => {
    return(
        <div className={`group ${props.cols}`}>
            <input readOnly={props.readOnly} type={props.type} className={props.className ? props.className : "group__input"} value={props.value} name={props.name} placeholder=" " onChange={props.onChange} />                       
            <span className="group__highlight"/>
            <span className="group__bar"/>
            <label className="group__label">{props.text}</label>
        </div>
    )
}