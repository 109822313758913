import React from "react";
import styled from "styled-components";
import {useHistory} from 'react-router-dom';

import {FiFilter, FiXSquare} from 'react-icons/fi';
// import {FaSave, FaCalendarAlt, FaUserCheck} from 'react-icons/fa';

import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';

import Content from "../../components/Content";
import {Container, Row} from '../../components/Form';
import {Flex, Input, ButtonRow, Select} from '../../components/FormRef/Form'; //FilterLabel || Select

// import useList from "./useList";
import Moment from "../../services/Moment";
import { FormContext } from "../../components/FormRef/FormContext";
import { toast } from "react-toastify";

import { ajax } from "../../api/chequer";

import TableRender from "./components/TableFeriado";
import Paginate from "./components/Paginate";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export default function FeriadoList(){  
    const history = useHistory();
    const {todayAddDay} = Moment();    
    const {fields} = React.useContext(FormContext);

    const [loading, setLoading] = React.useState(false);    
    const [pageActive, setPageActive] = React.useState(1);
    const [dataSet, setDataSet] = React.useState(null);    
    const [count, setCount] = React.useState(0);
    const [limitCount, setLimitCount] = React.useState(0); 

    //**RENDER SVG ICON */
    //function Svg(props) {
        //var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
        //return <svg className={props.className} dangerouslySetInnerHTML={{__html: useTag }} />;
    //}
    
    //**FUNÇÃO PARA VERIFICAR STRING VAZIA */
    //const isEmpty = React.useCallback((str)=>{
        //return (str.length === 0 || !str.trim());
    //}, []);    

    //**TOGGLE MODAL FILTER */
    function handleFilter() {
        const filter = document.getElementById("filter");
        filter.classList.toggle("open");
    }    

    //**CONFIGURA OS FILTERS */
    const getFilters = React.useCallback(()=>{
        return new Promise(resolve => {
            const filter = {};
            filter.page = parseInt(fields['page'].value, 10);
            filter.limit = parseInt(fields['limit'].value, 10);
            filter.dataInicial = fields["data_inicio"].value;
            filter.dataFinal = fields["data_final"].value;
            if(filter.dataInicial === "NaN-NaN-NaN" || filter.dataFinal === "NaN-NaN-NaN") return toast.error("Data invalida..");

            filter.search = `?page=${filter.page}&limit=${filter.limit}&datainicial=${filter.dataInicial}&datafinal=${filter.dataFinal}`; 
            resolve(filter);
        });        
    }, [fields]); 

    //**EXECUTA UMA REQUISIÇÃO PARA API  */
    const GetUrl = React.useCallback(async () => {        
        const query = await getFilters();        
        const response = await ajax({url: "/feriados" + query.search});
        if(response.count > 0) {           
            setDataSet(response); 
            setCount(response.count); 
            setPageActive(query.page); 
            setLimitCount(Math.ceil(+response.count / query.limit));
        }else{
            setDataSet(null); 
            setCount(0); 
            setPageActive(1); 
            setLimitCount(0);
        }     
    },[getFilters]);

    //**EXECUTA OS FILTERS */
    const handleSetFilter = React.useCallback(async ()=> {     
        const filter = document.getElementById("filter");        
        await GetUrl();
        filter.classList.toggle("open"); 
    }, [GetUrl]);     

    //**RESETA OS FILTERS */
    const handleFilterReset = React.useCallback(()=> {
        fields["data_inicio"].value = todayAddDay({format: "yyyy/mm/dd"});
        fields["data_final"].value = todayAddDay({format: "yyyy/mm/dd"});
        fields["setor"].value = "";
        fields["status"].checked = true;
        fields["total_horas"].checked = false;
        fields["colaborador"].selectedIndex = "0";
        fields["limit"].selectedIndex = "0";
    }, [fields, todayAddDay]);    

    const pageHistory = React.useCallback((value)=> {
        history.push({
            search: `?page=${value}`,
            page: value
        });
    }, [history])

    //**Paginate onClick */
    const handlePaginate = React.useCallback(async (e)=> {
        const el = e.target;     

        if(el.matches(".next")) {  
            const nextPage = parseInt(fields['page'].value + 1, 10);  
            fields['page'].value = nextPage >= limitCount ? limitCount : nextPage;
            pageHistory(fields['page'].value);         
            await GetUrl();
            return false;
        }

        if(el.matches(".prev")) {
            const prevPage = parseInt(fields['page'].value - 1, 10);
            fields['page'].value = prevPage < 2 ? 1 : prevPage;
            pageHistory(fields['page'].value); 
            await GetUrl();
            return false;
        }

        if(el.matches(".firstpage")) {
            fields['page'].value = 1; 
            pageHistory(1); 
            await GetUrl();
            return false;
        }

        if(el.matches(".lastpage")) {
            fields['page'].value = parseInt(e.target.innerText, 10);
            pageHistory(parseInt(e.target.innerText, 10)); 
            await GetUrl();
            return false;
        }        
        
        fields['page'].value = parseInt(e.target.innerText, 10); 
        pageHistory(parseInt(e.target.innerText, 10)); 
        await GetUrl();       
         
    }, [fields, limitCount, pageHistory, GetUrl]); 

    function handlerRemoveItem(e){
        e.preventDefault();

        MySwal.fire({
            title: 'Remove este item?',
            text: "Você não podera reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, delete isso!'
          }).then((result) => {
            if (result.isConfirmed) {
                async function removeItem() {
                    const result = await ajax({url: "/feriados/" + e.target.dataset.id, method: "delete"});
                    if(result.success) await GetUrl();                  
                    MySwal.fire(
                    'Deleted!',
                    'Registro foi excluído.',
                    'success'
                  )
                }
                removeItem();                
            }
          })
    }

    React.useEffect(()=> {
        setLoading(true);

        async function LoadingFields() {            
            if(fields["page"] !== undefined) {                
                pageHistory(fields['page'].value);
                await GetUrl();
            }
        }        

        if(loading) {
            setTimeout(() => {
                LoadingFields();
            }, 1000);
        }
    }, [fields, loading, pageHistory, GetUrl]);   
    
    if(!loading) return(<div></div>);
   
    return(   
        <>         
        <Content>            
            <ContentContainer>
                <Row>
                    <Container className="height-auto">
                        <Flex className="shadow">
                            <GroupButton>                                
                                <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter}/> 
                            </GroupButton>                                                                                                                                      
                        </Flex>                        
                    </Container>
                </Row>        
                <TableContainer>
                    <TableRender dataSet={dataSet} removeAction={handlerRemoveItem}/>                       
                </TableContainer> 
                {limitCount > 1 && <Paginate count={count} page={pageActive} limitCount={limitCount} onClick={handlePaginate}/>}                
                <Filter>                    
                    <header><span><FiFilter size={22}/> Filtros</span><FiXSquare size={30} onClick={handleFilter}/></header>
                    <div style={{marginTop: "1rem"}}> 
                        <Input className="hidden" type="hidden" name="page" value={1}/>
                        <Input type="date" col={12} defaultValue={todayAddDay({format: "yyyy/mm/dd"})} text="Data inicial" name="data_inicio" />
                        <Input type="date" col={12} defaultValue={todayAddDay({format: "yyyy/mm/dd"})} text="Data final" name="data_final" />                        

                        <Select label="Itens por pagina.." col={12} name="limit"> 
                            <option value={100} style={{display: "none"}}>100</option>                           
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={80}>50</option>                            
                            <option value={100}>100</option>                            
                            <option value={1000}>1000</option>                            
                        </Select>                                     
                    </div>
                    <main className="filter-inputs">                                                                                       
                        <button style={{marginTop: "1rem"}} onClick={handleSetFilter}><FiFilter size={22}/><span>Aplicar filtro</span></button>
                        <button className="reset" onClick={handleFilterReset}><FiFilter size={22}/><span>Resetar filtro</span></button>
                    </main>
                </Filter>
            </ContentContainer>            
        </Content>
        </>  
    )
}

const TableContainer = styled.div`
    flex: 1;
    overflow: auto;
    scrollbar-width: thin;
    padding: 0.5rem;
`;

const GroupButton = styled.div`   
    padding: 0 1rem;
    display: flex;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
`;

const Filter = styled(({children, ...props})=>(
    <div id="filter" className={props.className} onClick={props.onClick}>
        {children}
    </div>
))`
position: absolute;
top: 8px;
bottom: 8px;
width: 22rem;
background-color: #fff;
box-shadow: -1px 2px 10px rgba(0,0,100, 0.3);
border-radius: 8px;
right: -30rem;
transition: right 0.4s ease-in-out;

@media only screen and (max-width: 37.5em) {
    width: calc(100% - 18px);
}

display: flex;
flex-direction: column;
overflow: hidden;

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #7f808a;
    color: #fff;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.2rem;
    user-select: none;
    & span {
        display: flex;
        align-items: center;
    }
    & svg {
        /* margin-right: 1rem; */
    }
    
}

main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    overflow: auto;
    

    & label {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;       
        padding: 5px 1rem;
        cursor: pointer;
        font-size: 0.9rem;
        user-select: none;

        &.column {
            flex-direction: column;
            align-items: flex-start;
        }

        & input[type=text] {
            width: 100%;
            height: 2.5rem;
            border-radius: 4px;
            border: 1px solid #cecece;
            padding: 0 1rem;
            font-size: 1.1rem;
            font-family: inherit;
        }

        & input[type=radio]{
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem
        }

        & span {
            margin-left: 5px;
        }
    }

    & button {
        width: 100%;
        display: flex;
        align-items: center;        
        margin-bottom: 1rem;        
        padding: 1rem;
        cursor: pointer;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: 500;
        color: #f6f8ff;
        background-color: #2885fd;

        & span {
            flex: 1;
        }

        &.reset {
            background-color: #ff5f5f;
        }
    }
    
}

&.open {
    right: 8px;
}
`;