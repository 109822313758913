import React from 'react';
import styled from 'styled-components';

//**Container */
const Container = styled.div`
display: block;
width: 100%;
height: 100%;
overflow: auto;
scrollbar-width: thin;
padding: 1rem; 
@media only screen and (max-width: 37em) {
    padding: 8px;
}
`;

//**Panel */
const Panel = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
border: 1px solid #cecece;
border-radius: 10px;
padding: 2rem;
margin-bottom: 1rem;
width: 100%;
@media only screen and (max-width: 50em) {
    padding: 1rem 0;
    border: none;
}

&.hidden {
    display: none;
}
`;

//**Paragraph */
// eslint-disable-next-line no-unused-vars
const Paragraph = styled.p`
display: block;
line-height: 1.5;
width: 100%;
margin-bottom: 1rem;
white-space: pre-wrap; 
text-indent: 10px;
`;

//**Col */
const Col = styled.div`
    @media only screen and (min-width: 75em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }    

    width: calc(100% / 12 * ${props => props["col"]});

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
    }
`;
//**Row */
const Row = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    overflow: unset;
`;

//**Row Title */
const RowTitle = styled(({children, ...props})=> (
    <div className={props.className} id={props.id}>        
        <h5>{children} {props.text}</h5>
    </div>
))`
position: relative;
width: 100%;
color: #7999d7;
padding: 5px 10px;
margin-bottom: 1rem;
background-color: #d7d7d71c;
border: 2px solid rgba(193, 212, 250, 0.3);
font-size: 1.2rem;
border-radius: 5px;
display: flex;
align-items: center;
svg{
    margin-right: 5px;
}
h5{
    display: flex;
    align-items: center;
}
`;

//**Button Row */
const ButtonRow = styled(({children, ...props})=> (
    <button className={props.className} disabled={props.disabled} onClick={props.onClick}>
        <img src={props.src} alt={props.alt}/>
    </button>
))`    
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    max-height: 2.5rem;
    max-width: 2.5rem;
    margin-right: 5px;
    transform: translateY(-1rem);
    transition: all .1s ease-in-out;

    &:active {
        transform: translateY(-1rem) scale(1.7);
    }

    &:disabled {
        transform: none;
        opacity: .4;
        cursor: not-allowed;
    }

    @media only screen and (max-width: 37em) {
        
    }
`;

//**Button Action */
const ButtonAction = styled(({children, ...props})=> (
    <button className={props.className} type={props.type} disabled={props.disabled} onClick={props.onClick}>
        <img src={props.src} alt="mini"/>
    </button>
))`    
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    max-width: 7.5rem;
    max-height: 2.5rem;
    margin-right: 5px;    
    transition: all .1s ease-in-out;

    img {
        filter: drop-shadow(2px 3px 6px #727275);
    }

    &:active {
        transform: scale(1.3);
    }

    &:disabled {
        transform: none;
        opacity: .4;
        cursor: not-allowed;
    }

    @media only screen and (max-width: 37em) {
        max-width: 7rem;
    }
`;

const GroupInputMemo = styled(({...props}) => (
    <div className={props.className}>
        <input required={props.required} id={props.id} readOnly={props.readOnly} inputMode={props.inputMode} mask={props.mask} type={props.type} name={props.name} value={props.value} className={props.inputClass}  placeholder=" " onChange={props.onChange}/>                       
        <span className="control-group__highlight"/>
        <span className="control-group__bar"/>
        <label className="control-group__label">{props.text}</label>
    </div> 
))`
position: relative;
display: flex;
flex-direction: column;
margin-bottom: 1.7rem;
justify-content: flex-end;
padding: 0 .5rem;
overflow: unset;

@media only screen and (max-width: 75em) {
    padding: 0 5px;
}


input[type=email],
input[type=password],
input[type=text] {
    background-color: unset;
    font-size: .9rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;     
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (max-width: 37.5em) {
        font-size: 1rem;
    }

    &:focus {
        outline: none;
        border: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:read-only {
        cursor: not-allowed;
        user-select: none;
    }
}

label {
    color: #999;
    font-size: 1rem;       
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 7px;
    overflow: unset;    
}

input[type=email]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=text]::-ms-input-placeholder {
    color: transparent;
}

input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=text]::placeholder {
    color: transparent;
}

input[type=email]:not(:placeholder-shown) ~ label,
input[type=password]:not(:placeholder-shown) ~ label,
input[type=text]:not(:placeholder-shown) ~ label {
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;

    @media only screen and (max-width: 37.5em) {
        bottom: 1.6rem;  
        left: 0.6rem;
    }   
}

.control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;    
    left: 0;
    pointer-events: none;
    opacity: 0.15;
}

&:last-child .control-group__highlight {
    // height: calc(100% - 1.7rem);
    @media only screen and (max-width: 37.5em) {
        height: 100%;
    }
}

/* active state */
input[type=email]:focus ~ .control-group__highlight,
input[type=password]:focus ~ .control-group__highlight,
input[type=text]:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
}

input[type=email]:read-only ~ .control-group__highlight,
input[type=password]:read-only ~ .control-group__highlight,
input[type=text]:read-only ~ .control-group__highlight {
    animation: none;
}

.control-group__bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
}

.control-group__bar:before,
.control-group__bar:after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.control-group__bar:before {
    left: 50%;
}
.control-group__bar:after {
    right: 50%;
}

/* active state */
input[type=text]:focus ~ .control-group__bar {
    border-bottom: none;
}

input[type=text]:focus ~ .control-group__bar:before,
input[type=text]:focus ~ .control-group__bar:after {
    width: 50%;
}

input[type=text]:read-only ~ .control-group__bar:before,
input[type=text]:read-only ~ .control-group__bar:after {
    background: #cecece;
    top: 1px;
    height: 0.5px;
    width: 50%;
}

width: calc(100% / 12 * ${props => props["col"]});


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`;

function GroupSelectFunc({ children, className, options, custom, label, name, value="", defaultValue="", onChange, disabled, customSelected, required, ...rest }) {
    // const [status, setStatus] = React.useState('');
  
    // React.useEffect(() => {
    //   console.log(customSelected);
    // }, []);
  
    return (        
      <div className={className}>
        <select required={required} placeholder=" " name={name} defaultValue={value} onChange={onChange} disabled={rest.readOnly} {...rest}> 
            {!customSelected && !value && <option value="">Selecione..</option>}
            {options && options.map(function(item) {if(item.value === value) return(<option style={{display: 'none'}} key={item.value} value={item.value}>{item.name}</option>); return false})}          
           
            {customSelected && options && options.map(item => ( 
                item.value === customSelected ? <option key={item.value} value={item.value} selected>{item.name}</option> : <option key={item.value} value={item.value}>{item.name}</option>               
            ))}

            {!customSelected && options && options.map(item => (                
               <option key={item.value} value={item.value}>{item.name}</option>
            ))}
            {children}            
        </select>
        <label>{label}</label>
        <span className="bar"/>
        <span className="arrow"></span>
      </div>
    );
  }
const GroupSelect = styled(GroupSelectFunc)`
position: relative;
display: flex;
flex-direction: column;
margin-bottom: 1.7rem;
justify-content: flex-end;
padding: 0 .5rem;
overflow: unset;

select {
    background-color: unset;
    font-size: .9rem;
    padding: 5px 10px 5px 0px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;     
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase; 
    background: transparent;  
     

    @media only screen and (max-width: 37.5em) {
        font-size: 1rem;
    }

    &:focus {
        outline: none;
        border: none;
    }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

label {
    color: #999;
    font-size: 1rem;       
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 1rem;
    overflow: unset; 
    
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;
}

.bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
}

.bar:before,
.bar:after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}
.bar:after {
    right: 50%;
}

/* active state */
& select:focus ~ .bar {
    border-bottom: none;
}

& select:focus ~ .bar:before,
& select:focus ~ .bar:after {
    width: 50%;
}

.arrow{    
    position: absolute;
    right: 2.5rem;
    top: 50%;
    display: grid;
    align-items: center;
    pointer-events: none;
    z-index: 12;

    &::before,
    &::after {
        content: "";
        position: absolute;
        background-color: #7376a2;
        width: 0.7rem;
        height: 2px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
        left: 0.4rem;
    }
}


& select:focus ~ .arrow::before {
    transform: rotate(-45deg);
}

& select:focus ~ .arrow::after {
    transform: rotate(45deg);
}


width: calc(100% / 12 * ${props => props["col"]});


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`;

export const GroupInput = React.memo(GroupInputMemo);
export const Column = React.memo(Col);
export {Container, Panel, Paragraph, Row, RowTitle, ButtonRow, ButtonAction, GroupSelect};
