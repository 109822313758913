/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';
import { ajax } from '../../api/chequer';

import Logo from '../../assets/images/logochequer.png';
import { GlobalContext } from '../../contexts/global';

import { formatDataRegex } from '../../services/utils';

import { useHistory } from 'react-router-dom';

export default function Show() {
  const { id } = useParams();
  const { can } = React.useContext(GlobalContext);
  const [pedido, setPedido] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const memoCan = React.useMemo(() => can(['cobranca', 'superadmin']), [can]);

  function handlePrint(e) {
    e.preventDefault();
    window.print();
  }

  React.useEffect(() => {
    async function loadPedido() {
      const res = await ajax({ url: '/pedidos/show/' + id });
      setPedido(res.pedido);
      setLoading(false);
    }

    async function visualized() {
      await ajax({ url: '/pedidos/lido/' + id });
    }

    if (memoCan && id) visualized();
    loadPedido();
  }, [id, memoCan]);

  if (loading) return <div></div>;

  return (
    <Fragment>
      <GlobalStyle />
      <FixedHeader dataId={id} className="fixed-header print" onClick={handlePrint} />
      <Wrap className="wrap first">
        <div className="container-print">
          <header className="page-header">
            <div className="colum1">
              <img src={Logo} alt="Logo empresa" />
            </div>
            <div className="colum2">
              <p>Pedido: #{pedido['pedido']}</p>
              <p>Criado por: {pedido['user']['name']}</p>
              <p>
                Data de criação: {formatDataRegex(pedido.createdAt)} às {pedido.time}
              </p>
            </div>
          </header>
          <div className="content">
            {pedido &&
              pedido['pedidoObservations'].map((item) => (
                <div key={item.id} className="content__description">
                  <h4>Criado por: {item['user']['name']}</h4>
                  <h6>
                    Data de criação: {formatDataRegex(item.createdAt)} às {item.time}
                  </h6>
                  <p>{item['observations']}</p>
                </div>
              ))}
          </div>
        </div>
      </Wrap>
    </Fragment>
  );
}

const FixedHeader = ({ className, onClick, dataId, children }) => {
  const history = useHistory();
  function handleClick(e) {
    e.preventDefault();
    history.goBack();
  }
  return (
    <div className={className}>
      <div className="fixed-title">
        <h6>Pedido {dataId}</h6>
      </div>
      <div className="buttons">
        <a href="/" onClick={handleClick}>
          <img src="/images/buttons/button-mini-back.svg" alt="Boltão voltar" />
        </a>
        <button id="btn-print" onClick={onClick}>
          <img src="/images/buttons/button-mini-print.svg" alt="Boltão voltar" />
        </button>
      </div>
      {children}
    </div>
  );
};

const Wrap = ({ className, children }) => (
  <Fragment>
    <div className={className}>{children}</div>
  </Fragment>
);

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#root {
    background-color: #525659;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(82, 86, 89) !important;
}

img {
    display: block;
    width: 100%;
}

.back {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    font-size: 10pt;
    transform: translate(-35px,-1px);
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 15mm;
    background-color: rgb(50, 54, 57);
    box-shadow: 1px 1px 5px #000;
    color: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 10000;
}
.buttons {
    display: flex;
    align-items: center;
}

.fixed-header button {
    transform: all;
    background-color: transparent;
    box-shadow: none;
    border: none
}

.fixed-header button img {
    width: 35px;
}

.fixed-header button img:hover {
    transform-origin: center;
    transform: scale(1.1);
    cursor: pointer;
    transition: all .2s;
}

.buttons a {
    margin: 5px;
}
.buttons a img{
    width: 35px;
}

.fixed-header .wrap-center {
    width: 210mm;
    margin: 0 auto;
}

.wrap{
    position: relative;
    margin: 0 auto;
    width: 210mm;
    height: 297mm;
    color: #444;
    background-color: #fff;
    margin-bottom: 5mm;
}

.wrap.first {
    margin-top: 17mm;
}

.guia {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.guia .guiaY {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: rgb(192, 192, 192);
    z-index: 2;
}

.guia .guiaX {
    position: absolute;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: rgb(192, 192, 192);
    z-index: 2;
}

.container-print {
    position: relative;
    width: calc((210mm - 2px) / 1);
    height: calc((100% - 2px) / 1);
    z-index: 1;

    display: flex;
    flex-direction: column;
    padding: 20px;
    /* background-color: red; */
    top: 1px;
    left: 1px;

}

.page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #cecece;
    padding: 5mm;
    border-radius: 5px;
    margin-bottom: 4mm;
    & .colum1 {
        width: 150px;
    }
    & .colum2 {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 5mm;

        & p {
            width: 100%;
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;

    & .content__description {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #cecece;
        padding: 5mm;
        border-radius: 5px;
        margin-bottom: 5mm;

        & h4,
        & h6,
        & p {
            width: 100%;
        }

        & h4 {
            margin-bottom: 10px;
            line-height: 1mm;
        }

        & h6 {
            margin-bottom: 10px;
        }

        & p {
            position: relative;
            white-space: pre-wrap;
            color: #6a6a73;
            margin-left: 10px;


            &::before,
            &::after{
                content: "";
                position: absolute;
                background-color: #999;
                left: -10px;
                top: 2px;
                width: 4px;
                height: 100%;
            }
        }
    }
}

.tab_header {
    width: 100%;
}

.tab_header .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.tab_header img {
    width: 80%;
}

.tab_main {
    flex: 1;
    width: 100%;
    margin: 0 10px;
    padding: 10px;
    border: 4px solid #710000;
    border-radius: 20px;
}

.tab_footer {
    width: 100%;
}

@media only screen and (max-width: 37.5em) {
    .wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    .container-print {
        display: flex;
        margin: 0 auto;
        float: none;
    }

    .content {
        transform: scale(1);
        padding: 0;
    }

    .content .content__description h4 {
        font-size: 0.9rem;
    }

    .tab_header img {
        width: 70%;
        transform: translateY(-30%);
    }

    .tab_main {
        margin-bottom: 20px;
    }

    .guia .guiaY,
    .guia .guiaX {
        display: none;
    }

    .page-header {
        padding: 10px 5px;
        & .colum1 {
            width: 90px;
        }
        & .colum2 {
            padding: 2mm;
            font-size: 0.8rem;
        }
    }
}

.tab_footer .prazo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.panel__info {
    font-family: "Roboto", sans-serif;
    font-size: 12pt;
    font-style: italic;
    font-weight: bolder;
    margin-bottom: 10px;
    color: #000051;
}

.panel__info .name {
    font-size: 13pt;
    font-weight: bolder;
}

.panel__description {
    flex: 1;
    display: flex;
    align-items: center;
    font-family: Impact, 'Courier New', Courier, monospace,Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.panel__description .col1 {
    display: flex;
    flex-direction: column;
    padding-right: 2mm;
    color: #000051;
}


.panel__description .col1 .plano {
    font-size: 30pt;
    color: #710000;
    border: 4px solid #000051;
    border-radius: 2mm;
    padding: 0 2mm;
}

.panel__description .col1 .cifrao {
    text-align: right;
    font-size: 23pt;
}

.panel__description .col2 {
    flex: 1;
    font-size: 68pt;
    display: flex;
    justify-content: center;
    line-height: 1;
    color: #000051;;
}

.panel__description .col2 sup {
    height: 20mm;
    font-size: 40pt;
    transform: translateY(3mm);
}

.panel__footer {
    color: #000051;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15pt;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: bolder;
}

@page :left {
    margin: 1mm;
}

@page :right {
    margin: 1mm;
}

@page {
    /* size: A4;     */
    size: auto;
    margin: 0;
}

@media print {
    .fixed-header.print {
        display: none;
    }

    .wrap.first {
        margin: 0 !important;
    }

    .wrap{
        position: relative;
        width: auto !important;
        height: 100% !important;
        color: #444;
        background-color: #fff !important;
        margin: auto;
    }

    .container-print {
        position: relative !important;

        z-index: 1;

        display: flex !important;
        float: left !important;
        padding: 20px !important;
    }

    .page-break {
        page-break-before: always;
    }
}
`;
