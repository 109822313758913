import React, { useContext } from 'react';
import Chart from 'react-apexcharts';
// import styled from 'styled-components';

//**Components */
import Content from "../../components/Content";
import { Container, Panel, Row,  ButtonAction } from '../../components/Form';
import buttonSend from '../../assets/images/buttons/button-mini-search.svg';

import { Input,Select } from '../../components/FormRef/Form';

import { ajax } from '../../api/chequer';
import { GlobalContext } from '../../contexts/global';
import { ChartBox, ChartContent } from '../../components/ApexChart';
import Moment from "../../services/Moment";

export default function Dashboard() {
    const { getYear } = Moment();
    const { can } = useContext(GlobalContext);
    const [loading, setLoading] = React.useState(true);
    const [impostos, setImpostos] = React.useState([]);  
    const [company, setCompany] = React.useState('chequer megastore');  
    const [year, setYear] = React.useState('2023');  
    const memoImposto = React.useMemo(() => can(['imposto', 'superadmin']), [can]);
    // const yearRef = React.useRef('');
    // const companyRef = React.useRef('');


    function DonutChart({ series, labels, colors }) {
        function FloatToReal(val, cifrao = null) {
            if (cifrao) {
                const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val).replace("R$", "");
                return value;
            } else {
                const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
                return value;
            }
        }
        const optionsDonut = {
            chart: {
                width: '100%',
                height: 'auto',
            },

            labels: labels,
            colors: colors,
            // colors: ['#2E93fA', '#2E93fA', '#2E93fA', '#2E93fA', '#2E93fA', '#2E93fA'],
            // colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#BB33FF'],

            legend: {
                position: 'left',
                fontSize: 12,
                fontFamily: "Montserrat",
                formatter: function (seriesName, opts) {
                    const value = FloatToReal(opts.w.globals.series[opts.seriesIndex], true);
                    return [seriesName, "-" + value]
                },
            },

            responsive: [{
                breakpoint: 1200,
                options: {
                    legend: {
                        position: 'bottom',
                        fontSize: 10,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    value: {
                                        fontSize: 14,
                                    }
                                }
                            }
                        }
                    }
                },
            }],


            tooltip: {
                enabled: false,
                y: {
                    formatter: function (val) {
                        // return value + "#"
                        const value = FloatToReal(val, true)
                        return value;
                    }
                }
            },
            plotOptions: {
                pie: {
                    expandOnClick: true,
                    //customScale: 1.01,            
                    donut: {
                        size: '45',
                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                formatter: function (val) {
                                    const valueFormated = FloatToReal(val, true);
                                    return valueFormated;
                                }
                            },
                            total: {
                                show: true,
                                fontSize: 12,
                                color: 'green',
                                fontFamily: "Montserrat",
                                formatter: function ({ globals }) {
                                    const sum = globals.series.reduce((a, b) => a + b);
                                    const total = FloatToReal(sum, true);
                                    return total
                                }
                            }
                        }
                    }
                }
            },
        };
        return (<Chart series={series} options={optionsDonut} type="donut" />)
    }   

    const loadImpostos = React.useCallback(async () => {        
        // let year = yearRef.current.value;          
        let loadYear = year;  
        let loadCompany = company;  
        // const year = fields['year'].value;
        const response = await ajax({ url: "/impostos?year=" + loadYear + "&company=" + loadCompany});
        const chartData = [];
        const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

        function groupMonth(month) {
            const series = [];
            const labels = [];
            const colors = [];

            const objs = {
                Fgts: (item) => {
                    item.order = 3
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 07");
                    colors.push('#FF9800');
                    return '#FF9800'
                },
                Ipva: (item) => {
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase());
                    colors.push('#66DA26');
                    return '#66DA26';
                },
                Irpj: (item) => {
                    item.order = 1
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 30");
                    colors.push('#546E7A');
                    return '#546E7A';
                },
                Csll: (item) => {
                    item.order = 4
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 30");
                    colors.push('#E91E63');
                    return '#E91E63'
                },
                Pis: (item) => {
                    item.order = 0;
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 25");
                    colors.push('#7733ff');
                    return '#7733ff';
                },
                Confins: (item) => {
                    item.order = 5
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 25");
                    colors.push('#BB33FF');
                    return '#BB33FF';
                },
                Gps: (item) => {
                    item.order = 2
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 20");
                    colors.push('#2E93fA');
                    return '#2E93fA';
                },
                Icms: (item) => {
                    item.order = 6
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 10");
                    colors.push('#FF7860');
                    return '#FF7860'
                },
                Gps13: (item) => {
                    item.order = 2
                    series.push(parseFloat(item.price));
                    labels.push(item.imposto.toUpperCase() + " Dia 20");
                    colors.push('#9cc8f5');
                    return '#9cc8f5';
                },
            }

            const order = {
                Fgts: (item) => {
                    item.order = 3
                },
                Ipva: (item) => {
                    item.order = 7
                },
                Irpj: (item) => {
                    item.order = 1
                },
                Csll: (item) => {
                    item.order = 4
                },
                Pis: (item) => {
                    item.order = 0;
                },
                Confins: (item) => {
                    item.order = 5
                },
                Gps: (item) => {
                    item.order = 2
                },
                Icms: (item) => {
                    item.order = 6
                },
                Gps13: (item) => {
                    item.order = 7
                },
            }

            response.rows.forEach(item => {
                order[item.imposto](item)
            });

            response.rows.sort((a, b) => (a.order - b.order)).forEach(item => {
                if (item.month === month) {
                    objs[item.imposto](item);
                }
            });

            if (series.length > 0) return { name: `${month} de ${year}`, value: { series, labels, colors } };
            if (series.length === 0) return undefined;
        }

        months.forEach(item => {
            const value = groupMonth(item);
            if (value !== undefined) chartData.push(value);
        });

        setImpostos(chartData);
        setLoading(false);
    },[year, company])

    function handleChangeImposto() {                 
        loadImpostos()
    }   

    function handleChangeYear(value){
        setYear(value)
    }

    function handleChangeCompany(value){
        setCompany(value)        
    }

    React.useEffect(() => { 
        setLoading(false)
        // return () => { }
    }, [memoImposto, loadImpostos])

    if (loading) return (<div></div>);
    // #ffac33 || #ff5733 || #33ff9f || #7433ff || #d733ff || #ff3380

    return (
        <Content>
            <Container>
                {memoImposto && 
                <>
                    <Panel>                       
                        <Row>   
                            <Input col={2} xs={2} name="year" inputMode="numeric" defaultValue={getYear()} onChange={(e) => handleChangeYear(e.target.value)}/>  
                            <Select label="Empresa.." col={4} md={6} xs={8} name="empresa" onChange={(e) => handleChangeCompany(e.target.value)}>
                                <option value="chequer megastore">CHEQUER MEGASTORE</option>
                                <option value="welo imoveis">WELO IMÓVEIS</option>
                                <option value="steakbeer">STEAKBEER</option>
                            </Select>  
                            <div style={{width: "2.5rem", marginTop: "-25px", marginLeft: '5px'}}>
                                <ButtonAction  type="submit" src={buttonSend} alt="button send" onClick={handleChangeImposto} />
                            </div>  
                        </Row>                       
                    </Panel>
                    <Row>                        
                        {impostos.length > 0 && impostos.map(item => (
                            <ChartBox col={5} md={6} className='border' key={item.name}>
                                <ChartContent className='border'>
                                    <h5>{item.name}</h5>
                                    <DonutChart series={item.value.series} labels={item.value.labels} colors={item.value.colors} />
                                </ChartContent>
                            </ChartBox>
                        ))}
                    </Row>                    
                </>
                }
            </Container>
            
        </Content>
        
    )
}

// const Input = styled.input`
//     display: inline-block;
//     width: 8rem;    
//     border: none;
//     font-size: 2rem; 
//     border-bottom: 1px solid #000;
//     text-align: center;       
// `;