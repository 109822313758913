import React from 'react';
import Content from '../../components/Content';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormSelect, FormInput, FormCheckbox } from '../../components/FormRef/Form';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';

import { FiUsers } from 'react-icons/fi';

import { ajax, serialize } from '../../api/chequer';
import useStore from '../../hooks/useStore';
import { toast } from 'react-toastify';
import { FormContext } from '../../components/FormRef/FormContext'
import { GlobalContext } from '../../contexts/global';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
//const MySwal = withReactContent(Swal);

export default function StoreUser() {  
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {
  const { fields } = React.useContext(FormContext);
  const { can, roleScan } = React.useContext(GlobalContext); 
  const [roles, setRoles] = React.useState([]);  
  const [permissions, setPermissions] = React.useState([]);  
  const [stateButton, setStateButton] = React.useState(false);
  const { options } = useStore();

  //**Permissões */
  //const aDmin = React.useMemo(() => roleScan(), [roleScan]);  
  const memoCan = React.useMemo(() => can(['super_admin']), [can]);

  //**Cria um array com checbox marcados query ex: '#roles input[type=checkbox]:checked'*/
  function handleCheckedBox(query) {
    const array = document.querySelectorAll(query);    
    const arrayParams = [...array].length >= 1 ? [...array].map((item) => item.value) : [];
    return arrayParams
  }

  //**Reseta todos campos */
  const ResetCheckedBox = React.useCallback((query='')=> {        
    const domRoles = document.querySelectorAll(query);    
    [...domRoles].forEach((item) => { 
      fields[item.name].checked = false 
    });
  }, [fields]);

  const handleReset = React.useCallback((e)=> {
    e!== undefined ? e.preventDefault() : e = window.event;
    fields.name.value = "";
    fields.email.value = "";
    fields.password.value = "";
    fields.user_function.value = "";
    fields.status.value = true;    
    ResetCheckedBox('#roles input');
    ResetCheckedBox('#permissions input');
  }, [fields, ResetCheckedBox])

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);
    const rolesParam = handleCheckedBox('#roles input[type=checkbox]:checked');
    const permissionsParam = handleCheckedBox('#permissions input[type=checkbox]:checked');      
    const regionParam = handleCheckedBox('#region input[type=checkbox]:checked');      

    const params = [
      { name: 'name', value: fields.name.value },      
      { name: 'user_function', value: fields.user_function.value },
      { name: 'user_region', value: regionParam },
      { name: 'email', value: fields.email.value },      
      { name: 'password', value: fields.password.value },      
      { name: 'status', value: fields.status.value },      
      { name: 'roles', value: rolesParam },      
      { name: 'permissions', value: permissionsParam },
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/users', method: 'post', body, json: true });
    if (response.error) return toast.error(response.error);
    if (response.success && response.message) toast.success(response.message);

    setTimeout(() => {
      setStateButton(false);
      handleReset()
    }, 1000);
  
  }, [fields, handleReset]);
  
  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  React.useEffect(() => {    
    async function loadRoles() {
      async function start() {
        function dynamicSort(property) {
          var sortOrder = 1;
          if(property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
          }
          return function (a,b) {
              /* next line works with strings and numbers, 
               * and you may want to customize it to your needs
               */
              var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
              return result * sortOrder;
          }
        }

        const wait = new Promise(async(resolve) => {
          const resR = await ajax({ url: '/roles' });
          const resP = await ajax({ url: '/permissions' });          
          if (resR.rows) setRoles(resR.rows.sort(dynamicSort('id')));
          if (resP.rows) setPermissions(resP.rows.sort(dynamicSort('id')));         
          resolve(true)
        })

        return await wait;
      }
      await start();      
    }
    loadRoles();   
  }, []);

  return (
    <Container>
      <form id="formUser" onSubmit={handleSubmit}>
        <RowTitle text="REGISTRAR USUÁRIO">
          <FiUsers size={25} />
        </RowTitle>
        <Panel>      
          <FormInput 
            required={true}
            text="Nome.."
            col={6}
            name="name"            
            //readOnly={checkOnly()}
          /> 
          <FormInput 
            required={true}
            text="Email.."
            col={6}
            name="email"            
            //readOnly={checkOnly()}
          /> 
          <FormInput 
            text="Senha.."
            col={4}
            name="password"            
            //readOnly={checkOnly()}
          /> 
          <FormSelect 
            label="Função.."
            options={options.user_functions} 
            col={4}            
            name="user_function"
            firstText="Selecione..."
            //onChange={handleChange}
          />
          {/* <FormSelect 
            label="Região.."
            options={options.user_regions} 
            col={4}            
            name="user_region"
            firstText="Selecione..."
            //onChange={handleChange}
          /> */}
          <FormSelect 
            label="Status.."
            options={options.status} 
            col={4}            
            name="status"
            //onChange={handleChange}
          />

          
          <div>
            <RowTitle text="Região" />
            <Row id="region">
              <FormCheckbox              
                md={4}
                xs={6}
                lg={3}
                name={"norte"}
                value={"norte"}
                text={"Norte"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"noroeste"}
                value={"noroeste"}
                text={"Noroeste"}                        
              />                
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"sul"}
                value={"sul"}
                text={"Sul"}                        
              />
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"centro_oeste"}
                value={"centro_oeste"}
                text={"Centro"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"serrana"}
                value={"serrana"}
                text={"Serrana"}                        
              /> 
              <FormCheckbox                  
                md={4}
                xs={6}
                lg={3}
                name={"escritorio"}
                value={"escritorio"}
                text={"Escritório"}                        
              /> 
              </Row>
          </div>         

          {scanPermission('up_roles') && (
            <div>
              <RowTitle text="Funções" />
              <Row id="roles">
                {/* {memoCan && <span>can sim</span>} */}
                {roles &&
                  roles.map((role) =>
                    memoCan ? (
                      <FormCheckbox 
                        key={role.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={role.name}
                        value={role.id}
                        text={role.guard_name}                        
                      />                      
                    ) : (
                      role.name !== 'super admin' &&
                      role.name !== 'admin' && (
                        <FormCheckbox 
                        key={role.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={role.name}
                        value={role.id}
                        text={role.guard_name}                        
                      />  
                      )
                    ),
                  )}
              </Row>
            </div>
          )}         

          {scanPermission('up_permissions') &&(
            <div>
            <RowTitle text="Permissões" />
            <Row id="permissions">
              {/* {memoCan && <span>can sim</span>} */}
              {permissions &&
                permissions.map((permission) =>
                  memoCan ? (
                      <FormCheckbox 
                        key={permission.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={permission.name}
                        value={permission.id}
                        text={permission.guard_name}
                        
                      />                    
                  ) : (
                    permission.name !== 'super admin' &&
                    permission.name !== 'admin' && (
                      <FormCheckbox 
                        key={permission.id}
                        md={4}
                        xs={6}
                        lg={3}
                        name={permission.name}
                        value={permission.id}
                        text={permission.guard_name}                       
                      /> 
                    )
                  ),
                )}
            </Row> 
            </div>
          )}   

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />
            <ButtonAction src={buttonReset} alt="mini" disabled={stateButton} onClick={handleReset} />               
          </Row>
            
          
        </Panel>
        {/* <Group col={4} md={6} xs={9} text="Codigo.." type="text" name="user_code" value={usercode} onChange={handleChange}/> */}

        {/* <Column col={4} xs={3}>
                    <Row>
                        <ButtonRow src={MiniSearch} alt="mini"/>
                        <ButtonRow src={MiniAdd} alt="mini"/>
                    </Row>
                </Column> */}
      </form>
    </Container>
  );
}