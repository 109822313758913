import React from 'react';
import Content from '../../components/Content';
import { useParams } from 'react-router-dom';

import { Container, Panel, Row, Column, RowTitle, ButtonAction } from '../../components/Form';
import { FiFileText } from 'react-icons/fi';

import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize, formData } from '../../api/chequer';

import { Input, TextArea, ButtonRow, Select } from '../../components/FormRef/Form';
import { FormContext } from '../../components/FormRef/FormContext';
import { toast } from 'react-toastify';
import Mask from '../../services/mask';

import AuthContext from '../../contexts/auth';

// import { GlobalContext } from "../../contexts/global";

export default function PedidoStore() {
  const { id } = useParams();
  const { user } = React.useContext(AuthContext);
  const { fields } = React.useContext(FormContext);
  const [loading, setLoading] = React.useState(true);
  const [pedido, setPedido] = React.useState(null);
  const [printButton, setPrintButton] = React.useState(true);
  const [addButton, setAddButton] = React.useState(false);
  const [printUrl, setPrintUrl] = React.useState('');

  //**Metodo de busca de cliente */
  async function handleGetCustomer(e) {
    e.preventDefault();
    const params = [{ name: 'codigo', value: fields['ficha'].value }];
    const body = await serialize(params);
    const customer = await ajax({ url: '/services/customer', method: 'post', body, json: true });
    if (customer.data.codigoOut === '0') return toast.warning('Cliente não encontado!');
    if (customer.success && customer.data) {
      fields['nome'].value = customer.data.nome;
      fields['endereco'].value = customer.data.dcliEnd;
      fields['cep'].value = Mask.updateCep(customer.data.dcepCod);
      fields['cidade'].value = customer.data.dcepCid;
      fields['bairro'].value = customer.data.dcliBai;
      fields['cel'].value = customer.data.dcliTel;
    }
  }

  //**Metodo para emissão do formulário */
  async function handleSubmitForm(e) {
    e.preventDefault();
    setAddButton(true);

    const params = [];
    let response;
    let file = false;
    params.push(
      { name: 'user_id', value: +user.id },
      { name: 'observations', value: fields['observations'].value },
      { name: 'status', value: fields['status'].value },
    );

    if (fields['anexo'].value.trim() !== '') {
      params.push({ name: 'anexo', type: 'file', value: fields['anexo'].files });
      file = true;
    }

    if (id) {
      const body = await serialize(params);
      response = await ajax({ url: '/pedidos/' + id, method: 'put', body, json: true });
    } else {
      params.push(
        { name: 'pedido', value: +fields['pedido'].value },
        { name: 'client_id', value: +fields['ficha'].value },
        { name: 'client_name', value: fields['nome'].value },
      );

      if (file) {
        const body = await formData(params);
        response = await ajax({ url: '/pedidos', method: 'post', body });
        file = false;
      } else {
        const body = await serialize(params);
        response = await ajax({ url: '/pedidos', method: 'post', body, json: true });
      }
    }

    if (response.success && response.message) {
      setPrintUrl('/requests/show/' + response.pedido);
      setPrintButton(false);
      handleClear();
      return toast.success(response.message);
    }

    setAddButton(false);
    if (!response.success && response.message) return toast.warning(response.message);
  }

  function handleClear(e) {
    fields['pedido'].value = '';
    fields['ficha'].value = '';
    fields['nome'].value = '';
    fields['endereco'].value = '';
    fields['cep'].value = '';
    fields['cidade'].value = '';
    fields['bairro'].value = '';
    fields['cel'].value = '';
    fields['observations'].value = '';
    fields['status'].selectedIndex = '0';
    fields['anexo'].value = '';
    setAddButton(false);
    setPrintButton(true);
    setPrintUrl('');
  }

  function handlePrintPedido(e) {
    e.preventDefault();
    window.location.href = printUrl;
    setAddButton(false);
  }

  // const formSetCustomer = React.useCallback(() => {
  //     console.log(pedido)
  // }, [pedido])

  React.useEffect(() => {
    async function loadPedido() {
      const res = await ajax({ url: '/pedidos/show/' + id });
      if (res.success && res.pedido) {
        fields['ficha'].value = res.pedido.client_id;
        fields['pedido'].value = res.pedido.pedido;
        fields['btnSearch'].click();
        fields['btnSearch'].disabled = true;
        setPedido(res.pedido);
        setLoading(false);
      }
    }
    if (id && fields.nome !== undefined) {
      loadPedido();
    } else {
      setLoading(false);
    }
  }, [id, loading, fields]);

  if (loading) return <div></div>;

  return (
    <Content>
      <Container>
        <form onSubmit={handleSubmitForm}>
          <Panel>
            <RowTitle text="Cliente pedido">
              <FiFileText size={25} />
            </RowTitle>
            <Row>
              <Input col={4} xs={5} text="Ficha.." name="ficha" inputMode="numeric" readOnly={pedido ? true : false} />
              <Input col={4} xs={5} text="Pedido.." name="pedido" inputMode="numeric" readOnly={pedido ? true : false} />
              <Column col={4} xs={2}>
                <Row>
                  <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleGetCustomer} />
                </Row>
              </Column>
            </Row>
            <Row>
              <Input id="nome" col={12} text="Cliente.." name="nome" readOnly={true} />
              <Input col={6} text="Endereço.." name="endereco" readOnly={true} />
              <Input col={3} xs={6} text="Cep.." name="cep" readOnly={true} />
              <Input col={3} xs={6} text="Cel.." name="cel" readOnly={true} />
              <Input col={5} text="Cidade.." name="cidade" readOnly={true} />
              <Input col={7} text="Bairro.." name="bairro" readOnly={true} />
              <Select label="Status.." col={4} name="status">
                <option selected={pedido && pedido.status === 'Entrega Manual' && true} value="entrega manual">
                  Entrega Manual
                </option>
                <option selected={pedido && pedido.status === 'Entrega Transporte' && true} value="entrega transporte">
                  Entrega Transporte
                </option>
                <option selected={pedido && pedido.status === 'Por Fora' && true} value="por fora">
                  Por Fora
                </option>
                <option selected={pedido && pedido.status === 'Assistência' && true} value="assistência">
                  Assistência
                </option>
              </Select>
              <Input type="file" col={8} text="" name="anexo" />
              {/* <Select label="Alteração.." col={4} name="alteracao">
                                <option value={0}>Não</option>
                                <option value={1}>Sim</option>
                            </Select>                             */}
              {pedido &&
                pedido.pedidoObservations.map((item) => (
                  <Column key={item.id} style={{ padding: '1rem', color: '#999', marginBottom: '10px' }} col={12}>
                    <h4 style={{ marginBottom: '10px' }}>Criado por: {item.user.name}</h4>
                    <p>{item.observations}</p>
                  </Column>
                ))}
              <TextArea col={12} text="Observações.." name="observations" wrap="off" rows={7} className="noResize width99" />
            </Row>
            <Row>
              <ButtonAction type="submit" src={buttonSend} alt="button send" disabled={addButton} />
              <ButtonAction src={buttonPrint} alt="button print" disabled={printButton} onClick={handlePrintPedido} />
              <ButtonAction src={buttonReset} alt="button print" disabled={printButton} onClick={handleClear} />
            </Row>
          </Panel>
        </form>
      </Container>
    </Content>
  );
}
