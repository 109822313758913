/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import Content from '../../components/Content';

import { Container, Panel, Row, Column, RowTitle, ButtonAction } from '../../components/Form';
import { FiFileText, FiXSquare, FiFilter, FiPlusSquare } from 'react-icons/fi';
import { HiOutlineCalendar } from 'react-icons/hi';

import MiniOpen from '../../assets/images/buttons/button-mini-open.svg';
import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
// import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize } from '../../api/chequer';

import { Input, TextArea, ButtonRow, Select, MultSelect, Loader, FilterLabel } from '../../components/FormRef/Form';
import { FormContext } from '../../components/FormRef/FormContext';
import { toast } from 'react-toastify';
import Mask from '../../services/mask';
import AuthContext from '../../contexts/auth';
import Moment from '../../services/Moment';
import styled from 'styled-components';
import { Modal } from '../../components/Modal';
import { Table, Thead, Tbody, Flex } from '../../components/Table';
import { GlobalContext } from '../../contexts/global';
import { formatDataRegex } from '../../services/utils';

export default function Planilha() {
  const { id, planilhaId } = useParams();
  const { lastQuery } = React.useContext(GlobalContext);
  const { user } = React.useContext(AuthContext);
  const { fields } = React.useContext(FormContext);
  const [pedidos, setPedidos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pedido, setPedido] = React.useState(null);
  const [disSendButton, setDisSendButton] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [motorista, setMotorista] = React.useState([]);
  const [montador, setMontador] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [disButton, setDisButton] = React.useState(false);
  const [isModal, setIsModal] = React.useState(false);
  const [isFilter, setIsFilter] = React.useState(false);
  const [entrega, setEntrega] = React.useState(null);

  //**Filter State */
  const [today, setToday] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  const [planilha, setPlanilha] = React.useState(null);
  // const [query, setQuery] = React.useState("");

  const { todayAddDay, unFormat } = Moment();

  //**Metodo de busca de cliente */
  async function handleGetCustomer() {
    const params = [{ name: 'codigo', value: fields['ficha'].value }];
    const body = await serialize(params);
    const customer = await ajax({ url: '/services/customer', method: 'post', body, json: true });

    if (customer.data === undefined) return toast.error('Servidor offline contacte o administrador..');
    if (customer.data.codigoOut === '0') return toast.warning('Cliente não encontado!');
    if (customer.success && customer.data) {
      fields['nome'].value = customer.data.nome;
      fields['endereco'].value = customer.data.dcliEnd;
      fields['cep'].value = Mask.updateCep(customer.data.dcepCod);
      fields['cidade'].value = customer.data.dcepCid;
      fields['bairro'].value = customer.data.dcliBai;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setDisSendButton(true);
    setIsOpen(true);
    let response;

    if (fields['motorista'].value === '' || fields['montador'].value === '') {
      setDisSendButton(false);
      setIsOpen(false);
      return toast.error('Selecione o Motorista e Montador..');
    }
    //value: fields["data_entrega"].value
    const params = [
      { name: 'pedidoId', value: pedido ? pedido.id : '' },
      { name: 'user_created', value: user.name.split(' ')[0] },
      { name: 'data_entrega', value: unFormat({ date: fields['data_entrega'].value }) },
      { name: 'pedido', value: fields['pedido'].value },
      { name: 'ficha', value: fields['ficha'].value },
      { name: 'client_name', value: fields['nome'].value },
      { name: 'endereco', value: fields['endereco'].value },
      { name: 'cidade', value: fields['cidade'].value },
      { name: 'bairro', value: fields['bairro'].value },
      { name: 'observations', value: fields['observations'].value },
      { name: 'motorista', value: fields['motorista'].value },
      { name: 'montador', value: fields['montador'].value },
      { name: 'status', value: fields['status'].value },
    ];

    if (products.length > 0) {
      params.push({ name: 'products', value: products });
    } else {
      setIsOpen(false);
      setDisSendButton(false);
      return toast.error('Nenhum produto adicionado!');
    }

    const body = await serialize(params);
    if (id) {
      response = await ajax({ url: '/planilhas/' + id + '/' + planilhaId, method: 'post', body, json: true });
      if (response.success && response.message) {
        setDisSendButton(false);
        setIsOpen(false);
        toast.success(response.message);
      }
      return false;
    } else {
      response = await ajax({ url: '/planilhas', method: 'post', body, json: true });
    }

    if (response.message && !response.success) {
      setIsOpen(false);
      setDisSendButton(false);
      return toast.error(response.message);
    }

    if (response.message && response.success) {
      setTimeout(() => {
        setDisSendButton(false);
        setIsOpen(false);
        toast.success(response.message);

        fields['pedido'].value = '';
        fields['ficha'].value = '';
        fields['nome'].value = '';
        fields['endereco'].value = '';
        fields['cidade'].value = '';
        fields['bairro'].value = '';
        fields['product_id'].value = '';
        fields['observations'].value = '';
        fields['deposito'].selectedIndex = '0';
        setProducts([]);
      }, 2000);

      return false;
    }

    setTimeout(() => {
      setDisSendButton(false);
      setIsOpen(false);
    }, 2000);
  }

  async function handleSearchProductList(e) {
    e.preventDefault();
    const params = [{ name: 'codigo', value: fields['product_id'].value }];
    const body = await serialize(params);
    const response = await ajax({ url: '/services/product', method: 'post', body, json: true });

    if (typeof response.data === 'undefined') {
      setDisButton(false);
      return toast.error('Socket local offline!');
    }

    if (response.data.codigoOut === '') {
      setDisButton(false);
      return toast.error('Produto não cadastrado!');
    }

    if (response.data && response.data.nome !== '') {
      setDisButton(false);
      fields['codigo'].value = response.data.codigoOut;
      fields['product'].value = response.data.nome;
    }
  }

  async function handleAddProductList(e) {
    e.preventDefault();
    setDisButton(true);
    if (fields['deposito'].value === '') {
      setDisButton(false);
      return toast.error('Selecione um deposito!');
    }

    setDisButton(false);
    setProducts((old) => [
      ...old,
      { product_id: fields['codigo'].value, product: fields['product'].value, deposit: fields['deposito'].value },
    ]);

    setTimeout(() => {
      fields['product_id'].value = '';
      fields['codigo'].value = '';
      fields['product'].value = '';
      fields['deposito'].selectedIndex = '0';
    }, 1000);

    return toast.success('Produto adicionado!');
  }

  const queryCallback = React.useCallback(() => {
    const localStorageQuery = JSON.parse(localStorage.getItem('@query'));
    return new Promise((resolve) => {
      if (localStorageQuery) {
        let search = '';
        localStorageQuery.forEach((item) => {
          search += `${item.name}=${item.value}`;
        });
        // setQuery(search);
        resolve(search);
      } else {
        resolve('');
      }
    });
  }, []);

  const pedidoURI = React.useCallback(
    async (search = null) => {
      let params;
      !search ? (params = await queryCallback()) : (params = search);
      if (params.trim() === '') return false;
      const res = await ajax({ url: `/pedidos${params}` });
      if (res.rows) setPedidos(res.rows);
    },
    [queryCallback],
  );

  async function handleModal(e) {
    e.preventDefault();
    setIsModal(!isModal);
    pedidoURI();
  }

  const handleSearchPedido = React.useCallback(
    (e) => {
      e.preventDefault();
      const search = `?page=1&limit=1&pedido=${fields['search'].value}`;
      pedidoURI(search);
    },
    [fields, pedidoURI],
  );

  const handleSetFilter = React.useCallback(() => {
    let queryStorage = [];
    queryStorage.push({ name: '?page', value: 1 }, { name: '&limit', value: 1000 });
    // let search = '';
    const dataPedido = unFormat({ date: fields['data_pedido'].value });
    const inputs = document.querySelectorAll('.filter-inputs input:checked');
    inputs.forEach((item) => {
      if (item.name === 'date') {
        if (item.value === 'true') {
          queryStorage.push({ name: '&day', value: dataPedido });
        }
        return;
      }
      queryStorage.push({ name: `&${item.name}`, value: item.value });
    });
    lastQuery(queryStorage);
    // queryStorage.forEach(item => {
    //     search += `${item.name}=${item.value}`;
    // });
    // setQuery(search);
    pedidoURI();
  }, [lastQuery, fields, pedidoURI, unFormat]);

  function handleClose(e) {
    e.preventDefault();
    setIsModal(false);
  }

  function handleFilter(e) {
    e.preventDefault();
    setIsFilter(!isFilter);
  }

  function handleLink(e) {
    e.preventDefault();
    let obs = '';
    const el = e.target;
    setIsModal(false);

    if (el.dataset.planilha === 'true') {
      toast.warning('Este pedido já foi registrado..');
    }

    console.log(el.dataset.planilha);

    const dataPedido = +el.dataset.pedido;
    const pd = pedidos.filter((item) => item.pedido === dataPedido);
    setPedido(pd[0]);
    pd[0].pedidoObservations.forEach((item) => (obs += item.observations.trim()));
    fields['observations'].value = obs;
    fields['ficha'].value = pd[0].client_id;
    fields['pedido'].value = pd[0].pedido;
    handleGetCustomer();
  }

  function handleRemoveProduct(e) {
    e.preventDefault();
    const id = parseInt(e.target.dataset.id, 10);
    setProducts(products.filter((item) => item.product_id !== id));

    toast.warning('Produto removido...');
  }

  React.useMemo(() => {
    const localStorageQuery = JSON.parse(localStorage.getItem('@query'));
    if (localStorageQuery) {
      localStorageQuery.forEach((item) => {
        if (item.name === '&day') setToday(item.value);
        if (item.name === '&tipo') setTipo(item.value);
        if (item.name === '&planilha') setPlanilha(`${item.value}`);
      });
    }
  }, []);

  React.useEffect(() => {
    async function loadPage() {
      const resMt = await ajax({ url: '/users?user_function=deposito' });
      const resMtd = await ajax({ url: '/users?user_function=deposito' });
      setMotorista(resMt.rows);
      setMontador(resMtd.rows);
      if (!id) setLoading(false);
    }
    loadPage();
    // console.log(todayAddDay())
  }, [id, todayAddDay]);

  React.useEffect(() => {
    async function loadEntrega() {
      const response = await ajax({ url: '/planilhas/' + id + '/' + planilhaId });
      if (response.success && response.result) {
        setEntrega(response.result);
        if (fields['pedido'] !== undefined) {
          fields['pedido'].value = response.result.planilhas[0].pedido;
          fields['ficha'].value = response.result.planilhas[0].ficha;
          fields['nome'].value = response.result.planilhas[0].client_name;
          fields['endereco'].value = response.result.planilhas[0].endereco;
          fields['cidade'].value = response.result.planilhas[0].cidade;
          fields['bairro'].value = response.result.planilhas[0].bairro;
          fields['observations'].value = response.result.planilhas[0].observations;
          for (const item of response.result.planilhas[0].planilhaProduct) {
            setProducts((old) => [
              ...old,
              { product_id: item.product_id, product: item.product, deposit: item.deposit },
            ]);
          }
          // response.result.planilhas[0].planilhaProduct.map(item => {
          //     setProducts((old) => [...old, {product_id: item.product_id, product: item.product, deposit: item.deposit}]);
          // });
        }
      }
      setLoading(false);
    }

    if (id) loadEntrega();
  }, [id, fields, planilhaId]);

  if (loading) return <div></div>;

  return (
    <>
      <Content>
        <Container>
          <Loader className={isOpen && 'open'} />
          <form onSubmit={handleSubmit}>
            <Panel>
              <RowTitle text="Planilha de entrega">
                <FiFileText size={25} />
              </RowTitle>
              <Row>
                {entrega ? (
                  <Input
                    col={2}
                    defaultValue={todayAddDay({ date: entrega.data_entrega, format: 'dd/mm/yyyy' })}
                    text="Data entrega"
                    name="data_entrega"
                  />
                ) : (
                  <Input
                    col={2}
                    defaultValue={todayAddDay({ days: 1, format: 'dd/mm/yyyy' })}
                    text="Data entrega"
                    name="data_entrega"
                  />
                )}

                <Input
                  col={2}
                  xs={5}
                  text="Pedido.."
                  defaultValue={pedido && pedido.pedido}
                  name="pedido"
                  inputMode="numeric"
                  readOnly={pedido ? true : false}
                />
                <Column col={4} xs={2}>
                  <Row>
                    <ButtonRow name="pedidoOpen" src={MiniOpen} alt="mini" onClick={handleModal} />
                  </Row>
                </Column>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <Input col={2} xs={5} text="Ficha.." name="ficha" inputMode="numeric" readOnly={true} />
                <Input id="nome" col={4} text="Cliente.." name="nome" readOnly={true} />
                <Input col={6} md={6} text="Endereço.." name="endereco" />
                <Input col={4} xs={6} text="Cep.." name="cep" readOnly={true} />
                <Input col={4} xs={6} text="Cidade.." name="cidade" />
                <Input col={4} text="Bairro.." name="bairro" />

                <RowTitle text="Dados do transportador">
                  <FiFileText size={25} />
                </RowTitle>
                <Select label="Motorista.." col={4} name="motorista">
                  <option value={entrega ? entrega.motorista : ''} style={{ display: 'none' }}>
                    {entrega ? entrega.motorista : 'Selecione...'}
                  </option>
                  {motorista &&
                    motorista.map((item) => {
                      return (
                        <option key={item.id} value={item.name.split(' ')[0]}>
                          {item.name.split(' ')[0]}
                        </option>
                      );
                    })}
                </Select>

                {entrega ? (
                  <Input col={5} name="montador" defaultValue={entrega.montador} />
                ) : (
                  <MultSelect label="Montador.." col={5} name="montador">
                    <option value="" style={{ display: 'none' }}>
                      Selecione...
                    </option>
                    {montador &&
                      montador.map((item) => {
                        return (
                          <option key={item.id} value={item.name.split(' ')[0]}>
                            {item.name.split(' ')[0]}
                          </option>
                        );
                      })}
                  </MultSelect>
                )}

                <Select label="Status da entrega.." col={3} name="status">
                  <option value={entrega ? entrega.planilhas[0].status : 'entrega'} style={{ display: 'none' }}>
                    {entrega ? entrega.planilhas[0].status : 'Entrega'}
                  </option>
                  <option value="entrega">Entrega</option>
                  <option value="aguardar">Aguardar</option>
                </Select>

                <TextArea
                  col={12}
                  text="Observações.."
                  name="observations"
                  wrap="off"
                  rows={5}
                  className="noResize width99"
                />
                <RowTitle text="Produtos do transporte">
                  <FiFileText size={25} />
                </RowTitle>
                <Input col={3} xs={9} text="Código.." name="product_id" inputMode="numeric" />
                <Column col={2} xs={3}>
                  <Row>
                    <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleSearchProductList} />
                    <ButtonRow
                      name="btnProduct"
                      src={MiniAdd}
                      alt="mini"
                      onClick={handleAddProductList}
                      disabled={disButton}
                    />
                  </Row>
                </Column>
                <Row>
                  <Input col={2} text="Ref.." name="codigo" />
                  <Input col={7} text="Produto.." name="product" />
                  <Select label="Deposito.." col={3} name="deposito">
                    <option value="" style={{ display: 'none' }}>
                      Selecione...
                    </option>
                    <option value={1}>Deposito 1</option>
                    <option value={2}>Deposito 2</option>
                    <option value={10}>Deposito 10</option>
                    <option value={20}>Deposito 20</option>
                    <option value={30}>Deposito 30</option>
                    <option value={31}>Deposito 31</option>
                    <option value={91}>Deposito 91</option>
                    <option value={0}>Sem Deposito</option>
                  </Select>
                </Row>
                <Column col={12}>
                  <Row id="produtos">
                    {products.length > 0 &&
                      products.map((item) => {
                        return (
                          <Product key={item.product_id}>
                            <p>REF: {item.product_id}</p>
                            <p>PRODUTO: {item.product}</p>
                            <p>DEPOSITO: {item.deposit}</p>
                            <span data-id={item.product_id} onClick={handleRemoveProduct}>
                              x
                            </span>
                          </Product>
                        );
                      })}
                  </Row>
                </Column>
              </Row>
              <Row>
                <ButtonAction type="submit" src={buttonSend} alt="button send" disabled={disSendButton} />
                <ButtonAction type="reset" src={buttonReset} alt="button Reset" />
              </Row>
            </Panel>
          </form>
        </Container>
      </Content>
      <Modal
        title="Lista de pedidos"
        close={handleClose}
        is={isModal}
        lgW="80%"
        lgH="60%"
        mdW="90%"
        mdH="60%"
        xsW="90%"
        xsH="80%"
      >
        <div className="content">
          <div className="content__header">
            <Flex className="shadow">
              <Input col={3} xs={9} text="Pedido.." name="search" />
              <Column col={3} xs={3}>
                <Row>
                  <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleSearchPedido} />
                  <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter} />
                </Row>
              </Column>
            </Flex>
          </div>
          <div className="content__main">
            <Table>
              <Thead>
                <tr>
                  <th scope="col">Pedido</th>
                  <th scope="col">Tipo</th>
                  <th scope="col" className="center">
                    Ficha
                  </th>
                  <th scope="col" className="center">
                    Criado em
                  </th>
                  <th scope="col" className="center">
                    Planilha
                  </th>
                  <th scope="col" className="center">
                    Action
                  </th>
                </tr>
              </Thead>

              <Tbody>
                {pedidos.length > 0 &&
                  pedidos.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td data-label="cliente">
                          <span className="mob">Pedido</span>
                          <p>{item.pedido}</p>
                        </td>
                        <td data-label="Tipo" className="center">
                          <span className="mob">Tipo</span>
                          <span className="badge" style={{ backgroundColor: '#556677' }}>
                            {item.status}
                          </span>
                        </td>
                        <td className="center" data-label="Horário">
                          <span className="mob">Ficha</span>
                          {item.client_id}
                        </td>
                        <td data-label="Cadastrado" className="center">
                          <span className="mob">Criado em</span>
                          <Flex className="center xs-left">
                            <HiOutlineCalendar size={22} />
                            {formatDataRegex(item.createdAt)}
                          </Flex>
                        </td>
                        <td data-label="Lido" className="center">
                          <span className="mob">Entrega</span>
                          <span className="badge" style={{ backgroundColor: item.planilha ? '#5cb85c' : '#556677' }}>
                            {item.planilha ? 'Sim' : 'Não'}
                          </span>
                        </td>
                        <td data-label="#" className="nowrap center">
                          <span className="mob">Action</span>
                          <a
                            onClick={handleLink}
                            href="/"
                            data-planilha={item.planilha}
                            data-pedido={item.pedido}
                            data-ficha={item.client_id}
                            className="action"
                          >
                            <FiPlusSquare size={23} />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </Tbody>
            </Table>
          </div>
          <Filter className={isFilter && 'open'}>
            <div className="filter__header">
              <span>
                <FiFilter size={20} />
                Filtros
              </span>
              <FiXSquare size={30} onClick={handleFilter} />
            </div>
            <Row className="filter-inputs">
              <Input
                id="data-pedido"
                col={12}
                defaultValue={todayAddDay({ date: today, days: 0, format: 'dd/mm/yyyy' })}
                text="Data pedidos"
                name="data_pedido"
              />
              <GroupList>
                <h2>Filtrar por data..</h2>
                <FilterLabel type="radio" name="date" value={true} defaultChecked={today && true} text="Sim" />
                <FilterLabel type="radio" name="date" value={false} defaultChecked={!today && true} text="Não" />
              </GroupList>
              <GroupList>
                <h2>Filtrar por tipo..</h2>
                <FilterLabel
                  type="radio"
                  name="tipo"
                  value={'entrega%20transporte'}
                  defaultChecked={tipo === 'entrega%20transporte' && true}
                  text="Entrega Transporte.."
                />
                <FilterLabel
                  type="radio"
                  name="tipo"
                  value={'entrega%20manual'}
                  defaultChecked={tipo === 'entrega%20manual' && true}
                  text="Entrega Manual.."
                />
                <FilterLabel
                  type="radio"
                  name="tipo"
                  value={'assistência'}
                  defaultChecked={tipo === 'assistência' && true}
                  text="Assistência.."
                />
              </GroupList>
              <GroupList>
                <h2>Planilhado..</h2>
                <FilterLabel
                  type="radio"
                  name="planilha"
                  value={'true'}
                  defaultChecked={planilha === 'true' && true}
                  text="Sim"
                />
                <FilterLabel
                  type="radio"
                  name="planilha"
                  value={'false'}
                  defaultChecked={planilha === 'false' && true}
                  text="Não"
                />
              </GroupList>
            </Row>
            <Row>
              <button onClick={handleSetFilter}>Aplicar filtro</button>
            </Row>
          </Filter>
        </div>
        {/* <div className="footer">footer</div> */}
      </Modal>
    </>
  );
}

const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  margin-bottom: 5px;
  h2 {
    width: 100%;
    font-size: 1rem;
    color: #7483be;
  }
  label {
    width: auto !important;
    margin-right: 1rem;

    display: flex;
    -moz-box-align: center;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    font-size: 0.9rem;
    user-select: none;

    input {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 5px;
    }
  }
`;

const ProductStyled = ({ children, className }) => {
  return <span className={className}>{children}</span>;
};

const Product = styled(ProductStyled)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px 1rem;
  background-color: #7999d7;
  width: 100%;

  p {
    margin-right: 10px;
    font-size: 0.8rem;
    color: #fff; //7376a2
    text-transform: uppercase;
  }
  span {
    position: absolute;
    right: 1rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Filter = styled.div`
  position: absolute;
  padding: 5px;
  width: 15rem;
  right: -15rem;
  top: 5px;
  bottom: 5px;
  z-index: 2;
  box-shadow: 0 0 10px #436d932e;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #7376a2;
  transition: right 0.4s ease-in-out;

  &.open {
    right: 5px;
    overflow: auto;
  }

  .filter__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    background-color: #464646;
    color: #fff;
    padding: 5px;

    span {
      display: flex;
      align-items: center;
    }
  }
  button {
    width: 100%;
    padding: 10px;
    border: 1px solid #cecece;
    font-size: 1rem;
    color: currentcolor;
  }
`;
