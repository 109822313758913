/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Fragment } from 'react';

import { createGlobalStyle } from 'styled-components';
import Logo from '../../assets/images/logochequer.png';
import { ajax } from '../../api/chequer';

const todayAddDay = ({ date, days, format }) => {
  const requestDate = (date) => {
    return date ? new Date(date) : new Date();
  };

  const data = date ? requestDate(date) : requestDate();
  const currentDate = {
    dia: (data.getDate() + (date ? 1 : 0) + (days || 0)).toString().padStart(2, '0'),
    mes: (data.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
    ano: data.getFullYear(),
  };
  if (format && format === 'dd/mm/yyyy') return `${currentDate.dia}/${currentDate.mes}/${currentDate.ano}`;
  return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}T03:00:00.000Z`;
};

export default function Primary() {
  const { search } = useLocation();
  const [planilhas, setPlanilhas] = React.useState(null);

  React.useEffect(() => {
    async function loadPrintPage() {
      const response = await ajax({ url: `/planilhas${search}` });
      if (response.rows) setPlanilhas(response.rows);
      console.log(response.rows);
    }

    loadPrintPage();

    const btnPrint = document.querySelector('#btn-print');
    btnPrint.addEventListener('click', function () {
      window.print();
    });
  }, [search]);

  return (
    <Fragment>
      <GlobalStyle />
      <FixedHeader className="fixed-header print" />
      {/* <Wrap className="wrap first"/> */}
      {planilhas && planilhas.length > 0 && <Wrap className="wrap first" data={planilhas} />}
    </Fragment>
  );
}

const FixedHeader = ({ className, children }) => (
  <div className={className}>
    <div className="fixed-title">
      <h6>Planilha</h6>
    </div>
    <div className="buttons">
      <a href="/planilhas">
        <img src="/images/buttons/button-mini-back.svg" alt="Boltão voltar" />
      </a>
      <button id="btn-print">
        <img src="/images/buttons/button-mini-print.svg" alt="Boltão voltar" />
      </button>
    </div>
    {children}
  </div>
);

const Wrap = ({ className, data, children }) => (
  <Fragment>
    <div className={className}>
      <div className="content">
        {data.map((item) => {
          return (
            <div key={item.id}>
              <header className="wrap-header">
                <div className="logotipo">
                  <img src={Logo} alt="logotipo" />
                </div>
                <div className="information">
                  <h2>Planilha de Entrega</h2>
                  <p>Motorista: {item.motorista}</p>
                  <p>Montador: {item.montador}</p>
                  <p>Data: {todayAddDay({ date: item.data_entrega, format: 'dd/mm/yyyy' })}</p>
                </div>
              </header>
              <div className="table-content">
                <table>
                  <thead>
                    <tr>
                      <th>Ficha</th>
                      <th>Cliente</th>
                      <th>Endereço</th>
                      <th>Cidade</th>
                      <th>Bairro</th>
                      <th style={{ width: '60px' }}>Ref</th>
                      <th>Produto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.planilhas.map((planilha) => (
                      <Fragment key={planilha.id}>
                        <tr>
                          <td>{planilha.ficha}</td>
                          <td>{planilha.client_name}</td>
                          <td>{planilha.endereco}</td>
                          <td>{planilha.cidade}</td>
                          <td>{planilha.bairro}</td>
                          <td colSpan={2}>
                            {planilha.planilhaProduct.map((product) => (
                              <div className="tdrow" key={product.id}>
                                <p className="ref">{product.product_id}</p>
                                <p className="product">
                                  {product.product} / Dep: {product.deposit}
                                </p>
                              </div>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5} className="observation">
                            <p>{planilha.observations}</p>
                          </td>
                          <td colSpan={2}>Conferir:</td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    {children}
  </Fragment>
);

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
#root {
    background-color: #525659;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(82, 86, 89) !important;
}

img {
    display: block;
    width: 100%;
}

.back {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    font-size: 10pt;
    transform: translate(-35px,-1px);
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 15mm;
    background-color: rgb(50, 54, 57);
    box-shadow: 1px 1px 5px #000;
    color: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 10000;
}
.buttons {
    display: flex;
    align-items: center;
}

.fixed-header button {
    transform: all;
    background-color: transparent;
    box-shadow: none;
    border: none
}

.fixed-header button img {
    width: 35px;
}

.fixed-header button img:hover {
    transform-origin: center;
    transform: scale(1.1);
    cursor: pointer;
    transition: all .2s;
}

.buttons a {
    margin: 5px;
}
.buttons a img{
    width: 35px;
}

.fixed-header .wrap-center {
    width: 210mm;
    margin: 0 auto;
}

.wrap{
    position: relative;
    margin: 0 auto;
    width: 297mm;
    /* height: 210mm;  */
    height: auto;
    color: #444;
    background-color: #fff;
    margin-bottom: 5mm;
}

.wrap.first {
    margin-top: 17mm;
}

.content {
    padding: 10px;
}

@media only screen and (max-width: 37.5em) {
    .wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }
}

.wrap-header {
    display: flex;
    padding: 10px;
    border: 1px solid #DDD;
    border-radius: 5px;
    margin-bottom: 10px;
}

.logotipo {
    img {
        width: 130px;
    }
}

.information {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -80px;

    h2 {
        width: 100%;
        text-align: center;
        /* margin-left: -70px; */
    }

    p {
        margin: 0 10px;
    }
}

.table-content {
    border: 1px solid #f2f2f2;
    height: 100%;
}

.table-content table {
    font-size: 75%;
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;
}

.table-content table thead tr {
    display: table-row;
    text-align: left;

    &.center{
        text-align: center;
    }

    & th {
        text-align: left;
        border-width: 1px;
        padding: 0.5em;
        position: relative;
        background: #EEE;
        border-color: #BBB;

        &.center{
            text-align: center;
        }
    }
}


.table-content table tbody tr {
    display: table-row;
    text-align: left;

    &.center{
        text-align: center;
    }

    & td {
        text-align: left;
        border-width: 1px;
        padding: 0.5em;
        position: relative;
        border-radius: 0.25em;
        border-style: solid;
        border-color: #DDD;

        &.center{
            text-align: center;
        }

        &.observation {
            width: 70%;
            p{
                display: flex;
            }
        }
    }
}

.tdrow {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    &:not(:last-of-type) {
        border-bottom: 1px solid #DDD;
    }

    & .ref {
       width: 60px;
    }

    & .product {
       /* text-align: center;
       display: flex;
       justify-content: center;  */
       flex: 1
    }
}


@page :left {
    margin: 1mm;
}

@page :right {
    margin: 1mm;
}

@page {
    /* size: A4;     */
    size: auto;
    margin: 0;
}

@media print {
    #root {
        background-color: #fff;
    }
    .fixed-header.print {
        display: none;
    }

    .wrap.first {
        margin: 0 !important;
    }

    .wrap{
        position: relative;
        width: calc(297mm - 4px / 1);
        /* height: calc(210mm - 4px / 1); */
        height: auto;
        color: #444;
        background-color: #fff;
        margin: 0 auto;
        top: 2px;
        left: 2px;
    }

    .content {
        padding: 10px;
        width: calc((297mm - 1px) / 1);
        height: auto;
    }

    .page-break {
        page-break-before: always;
    }

    /* tr{
        page-break-after: always;
        display: block;
    } */
}
`;
