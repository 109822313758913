import React from "react";
import Content from '../../components/Content';
//import {useParams} from 'react-router-dom';

import {Container, Panel, Row, RowTitle} from '../../components/Form'; //Column
import {FiFileText, FiSave, FiPrinter} from 'react-icons/fi';

import { ajax, serialize } from "../../api/chequer";

import {Input, Select} from '../../components/FormRef/Form'; //ButtonRow
import {FormContext} from "../../components/FormRef/FormContext";
// import { toast } from "react-toastify";
import Mask from "../../services/mask";

import Moment from "../../services/Moment";
import styled from "styled-components";

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import { toast } from "react-toastify";

// import { GlobalContext } from "../../contexts/global";

export default function ImpostoStore() {  
    const {getYear} = Moment();    
    const {fields} = React.useContext(FormContext);    
    const [loading, setLoading] = React.useState(true);
    const [extraColumns, setExtraColumns] = React.useState([]);
    const [dataColumns, setDataColumns] = React.useState(null);
    const [disButtom, setDisButtom] = React.useState(false);

    const handleAddExtraColumn = React.useCallback(()=> {
        if(fields["month"].value === "") return toast.warning("Selecione um mês..")
        setExtraColumns((old)=> [...old, extraColumns + 1]);
    }, [fields, extraColumns]);  
    
    function handleMaskCustom(e) {
        Mask.money(e.target, true);
    }
   
    const formatFieldMoelda = React.useCallback((text) => {
        if(fields[text].value === "R$ 0,00") return "";       
        return fields[text].value.replace(/R\$/g, "").replace(/[.]/gi, '').replace(",", ".");
    }, [fields]);    

    const settingFieldsMask = React.useCallback(()=> {
        Mask.money(fields['contas_receber'], true);
        Mask.money(fields['estoque'], true);
        Mask.money(fields['caixa'], true);
        Mask.money(fields['pai'], true);
        Mask.money(fields['banco_brasil'], true);
        Mask.money(fields['banco_itau'], true);
        Mask.money(fields['banco_santander'], true);
        Mask.money(fields['bitcoin'], true);
        Mask.money(fields['mae'], true);
        Mask.money(fields['contas_pagar'], true);
        Mask.money(fields['salario'], true);
        Mask.money(fields['lucro_bruto'], true);
        Mask.money(fields['juros'], true);
        Mask.money(fields['despesa'], true);
        Mask.money(fields['c_liquido'], true);
        Mask.money(fields['vendas'], true);
        Mask.money(fields['c_receber_antes'], true);
        Mask.money(fields['c_receber_depois'], true);
    }, [fields]);

    const initialFields = React.useCallback(()=> {
        fields['contas_receber'].value = "R$ 0,00";
        fields['estoque'].value = "R$ 0,00";
        fields['caixa'].value = "R$ 0,00";
        fields['pai'].value = "R$ 0,00";
        fields['banco_brasil'].value = "R$ 0,00";
        fields['banco_itau'].value = "R$ 0,00";
        fields['banco_santander'].value = "R$ 0,00";
        fields['bitcoin'].value = "R$ 0,00";
        fields['mae'].value = "R$ 0,00";
        fields['contas_pagar'].value = "R$ 0,00";
        fields['salario'].value = "R$ 0,00";
        fields['lucro_bruto'].value = "R$ 0,00";
        fields['juros'].value = "R$ 0,00";
        fields['despesa'].value = "R$ 0,00";
        fields['c_liquido'].value = "R$ 0,00";
        fields['vendas'].value = "R$ 0,00";
        fields['c_receber_antes'].value = "R$ 0,00";
        fields['c_receber_depois'].value = "R$ 0,00";
        settingFieldsMask();
    }, [fields, settingFieldsMask]);

    const settingFieldsDataset = React.useCallback((dataSet)=> {
        fields['contas_receber'].value = dataSet.contas_receber ? dataSet.contas_receber : "R$ 0,00";
        fields['estoque'].value = dataSet.estoque ? dataSet.estoque : "R$ 0,00";
        fields['caixa'].value = dataSet.caixa ? dataSet.caixa : "R$ 0,00";
        fields['pai'].value = dataSet.pai ? dataSet.pai : "R$ 0,00";
        fields['banco_brasil'].value = dataSet.banco_brasil ? dataSet.banco_brasil : "R$ 0,00";
        fields['banco_itau'].value = dataSet.banco_itau ? dataSet.banco_itau : "R$ 0,00";
        fields['banco_santander'].value = dataSet.banco_santander ? dataSet.banco_santander : "R$ 0,00";
        fields['bitcoin'].value = dataSet.bitcoin ? dataSet.bitcoin : "R$ 0,00";
        fields['mae'].value = dataSet.mae ? dataSet.mae : "R$ 0,00";
        fields['contas_pagar'].value = dataSet.contas_pagar ? dataSet.contas_pagar : "R$ 0,00";
        fields['salario'].value = dataSet.salario ? dataSet.salario : "R$ 0,00";
        fields['lucro_bruto'].value = dataSet.lucro_bruto ? dataSet.lucro_bruto : "R$ 0,00";
        fields['juros'].value = dataSet.juros ? dataSet.juros : "R$ 0,00";
        fields['despesa'].value = dataSet.despesa ? dataSet.despesa : "R$ 0,00";
        fields['c_liquido'].value = dataSet.c_liquido ? dataSet.c_liquido : "R$ 0,00";
        fields['vendas'].value = dataSet.vendas ? dataSet.vendas : "R$ 0,00";
        fields['c_receber_antes'].value = dataSet.c_receber_antes ? dataSet.c_receber_antes : "R$ 0,00";
        fields['c_receber_depois'].value = dataSet.c_receber_depois ? dataSet.c_receber_depois : "R$ 0,00";
        settingFieldsMask();
    }, [fields, settingFieldsMask]);  

    const handleChangeMonth = React.useCallback((e)=> {
        const year = fields['year'].value;
        const month = e.target.value;
        async function getBalance() {
            const response = await ajax({url: `/balances?page=1&limit=100&year=${year}&month=${month}`});
            
            if(response && response.count === 0) {
                setExtraColumns([]);
                setDataColumns([]);
                return initialFields()
            };
            if(response && response.count > 0) {                  
                setExtraColumns([]);               
                setDataColumns(response.rows[0].balanceColumns.length > 0 ? response.rows[0].balanceColumns : []);
                return settingFieldsDataset(response.rows[0]);            
            };            
        }
        getBalance();
    }, [fields, initialFields, settingFieldsDataset]);

    const handleSubmitForm = React.useCallback(async () => {
        if(fields["month"].value === "") return toast.error("Selecione um mês..");
        setDisButtom(true);
        const extra = [];
        const extraColumns = document.querySelectorAll(".custom-column");        
        [...extraColumns].forEach(item => {            
            if(item.children[1].children[0].value !== "R$ 0,00") {
                extra.push({name: item.children[0].children[0].value, value: formatFieldMoelda(item.children[1].children[0].name)});
            }                        
        }); 

        const params = [            
            {name: "company_id", value: 1},           
            {name: "year", value: fields["year"].value},           
            {name: "month", value: fields["month"].value}, 
            {name: "contas_receber", value: formatFieldMoelda("contas_receber")},
            {name: "estoque", value: formatFieldMoelda("estoque")},
            {name: "caixa", value: formatFieldMoelda("caixa")},
            {name: "pai", value: formatFieldMoelda("pai")},
            {name: "banco_brasil", value: formatFieldMoelda("banco_brasil")},
            {name: "banco_itau", value: formatFieldMoelda("banco_itau")},
            {name: "banco_santander", value: formatFieldMoelda("banco_santander")},
            {name: "bitcoin", value: formatFieldMoelda("bitcoin")},
            {name: "mae", value: formatFieldMoelda("mae")},
            {name: "contas_pagar", value: formatFieldMoelda("contas_pagar")},
            {name: "salario", value: formatFieldMoelda("salario")},
            {name: "lucro_bruto", value: formatFieldMoelda("lucro_bruto")},
            {name: "juros", value: formatFieldMoelda("juros")},
            {name: "despesa", value: formatFieldMoelda("despesa")},
            {name: "c_liquido", value: formatFieldMoelda("c_liquido")},
            {name: "vendas", value: formatFieldMoelda("vendas")},
            {name: "c_receber_antes", value: formatFieldMoelda("c_receber_antes")},
            {name: "c_receber_depois", value: formatFieldMoelda("c_receber_depois")},
            {name: "extra_columns", value: extra.length > 0 ? extra : ""},
        ];  

        const body = await serialize(params);
        const response = await ajax({url:"/balances/store", method: "post", body, json:true});
        if(response.success) {                     
            toast.success("Registro salvo com sucesso!");
            setDisButtom(false);
            return false;
        }else{
            setDisButtom(false);
        }       

        
    }, [fields, formatFieldMoelda]);    

    React.useEffect(()=> {
        setLoading(false);
    }, []);
    
    React.useEffect(()=> {
        async function loadPedido() { 
            fields['year'].value = getYear();                
            setLoading(false);
           
        }
        if(fields['year'] !== undefined) loadPedido();
    }, [fields, getYear]);

    if(loading) return(<div></div>)

    return(
        <Content>            
            <Container> 
            <Panel style={{alignItems: "initial"}}>       
                        <RowTitle text="Balanço Administrativo"><FiFileText size={25}/></RowTitle> 
                        <Row>                                                      
                            <Input col={1} md={2} xs={3} text="Ano.." name="year" inputMode="numeric" defaultValue={new Date().getFullYear()}/>                            
                            <Select label="Mês.." col={2} md={3} xs={9} name="month" onChange={handleChangeMonth}>
                                <option value="" style={{display: "none"}}>Selecione...</option> 
                                <option value="1">Janeiro</option>                                
                                <option value="2">Fevereiro</option>                                
                                <option value="3">Março</option>                                
                                <option value="4">Abril</option>                                
                                <option value="5">Maio</option>                                
                                <option value="6">Junho</option>                                
                                <option value="7">Julho</option>                                
                                <option value="8">Agosto</option>                                
                                <option value="9">Setembro</option>                                
                                <option value="10">Outubro</option>                                
                                <option value="11">Novembro</option>                                
                                <option value="12">Dezembro</option>                                
                            </Select>     
                            {/* <Column col={4} xs={2}>
                                <Row>                                                    
                                    <ButtonRow name="btnSearch" src={MiniSearch} alt="mini"/>
                                </Row>
                            </Column> */}
                        </Row>      
                        <ContainerCelula col={6}>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Contas a Receber:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="contas_receber" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Estoque:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="estoque" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Caixa:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="caixa" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Pai:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="pai" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Banco do Brasil:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="banco_brasil" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Banco Itaú:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="banco_itau" defaultValue="R$ 0,00"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Banco Santander:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="banco_santander" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">BitCoin:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="bitcoin" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="redlight">Mãe:</Celula>
                                <Celula col={4} xs={6} className="redlighthover"><InputCelula type="text" name="mae" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="redlight">Contas a Pagar:</Celula>
                                <Celula col={4} xs={6} className="redlighthover"><InputCelula type="text" name="contas_pagar" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="redlight">Salário:</Celula>
                                <Celula col={4} xs={6} className="redlighthover"><InputCelula type="text" name="salario" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                        </ContainerCelula>
                        <ContainerCelula col={6}>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Lucro Bruto:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="lucro_bruto" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Juros:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="juros" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="redlight">Despesa:</Celula>
                                <Celula col={4} xs={6} className="redlighthover"><InputCelula type="text" name="despesa" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">L. Liquido:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="c_liquido" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">Vendas:</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="vendas" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">C. Receber (Antes):</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="c_receber_antes" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>
                            <RowCelula> 
                                <Celula col={8} xs={6} className="opaque">C. Receber (Depois):</Celula>
                                <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name="c_receber_depois" defaultValue="R$ 0,00" inputMode="numeric"/></Celula>
                            </RowCelula>

                            <CustomColumn col={6}>
                                <h4>Adicionar colunas extras</h4>
                                <button onClick={handleAddExtraColumn}><img src={MiniAdd} alt="mini add"/></button>
                            </CustomColumn>
                        </ContainerCelula>
                        <ContainerCelula col={6} id="extra-columns">
                            {dataColumns && dataColumns.map((item)=> (
                                 <RowCelula key={item.id} className="custom-column"> 
                                    <Celula col={8} xs={6} className="opaque"><InputCelula type="text" name={`text_${item.name}`} defaultValue={item.name}/></Celula>
                                    <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name={`value_${item.name}`} defaultValue={Mask.setMoney(item.value, true)} onKeyUp={handleMaskCustom}/></Celula>
                                </RowCelula>
                            ))}                            
                            {extraColumns && extraColumns.map((item)=> (
                                <RowCelula key={item} className="custom-column"> 
                                    <Celula col={8} xs={6} className="opaque"><InputCelula type="text" name={`text_${item}`} defaultValue="Digite um nome..."/></Celula>
                                    <Celula col={4} xs={6} className="bluelight"><InputCelula type="text" name={`value_${item}`} defaultValue="R$ 0,00" onKeyUp={handleMaskCustom}/></Celula>
                                </RowCelula>
                            ))}
                        </ContainerCelula>
                        <Row>                    
                            <ButtonCelulaRow>
                                <button disabled={disButtom} onClick={handleSubmitForm}><FiSave />Save</button>
                                <button disabled={disButtom} className="print"><FiPrinter />Print</button>
                            </ButtonCelulaRow>          
                        </Row>
                    </Panel>              
            </Container>
        </Content>       
    )
}

const CustomColumn = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 5px;
    justify-content: space-between;
    color: #7999d7;
    background-color: #d7d7d71c;
    border: 2px solid rgba(193,212,250,0.3);

    button {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;
        margin-right: 0px;
        padding: 0;
        background-color: transparent;
        border: none;
        max-height: 2.5rem;
        max-width: 2.5rem;
        min-width: 2.5rem;
        margin-right: 5px;       
        -webkit-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;    
        
        &:active {
            transform: scale(1.7);
        }
    }
`;

const ButtonCelulaRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    button {       
        border: none;
        padding: 5px 3rem;
        border-radius: 5px;
        background-color: #04910d;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        opacity: 1;
        display: flex;
        align-items: center;
        margin-right: 0.7rem;
        transition: all 0.2s;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            transform: scale(1.1);   
            
            &:hover {
                opacity: 1;
            }
        }        

        &.print {
            background-color: #2885fd;
        }

        &:disabled {
            background-color: #999;
        }

        svg {
            margin-right: 5px;
            width: 1.7rem;
            height: 1.7rem;
        }

        @media only screen and (max-width: 37.5em) {
            width: 100%;            
        }
    }
`;

function InputCelula({className, name, type, value, ...rest}) {
    const {registerField} = React.useContext(FormContext);
    const inputRef = React.useRef();

    React.useEffect(()=> {
        if(inputRef.current && name) {
            registerField(name, inputRef.current);
        }
    }, [name, registerField]);

    return(
        <input ref={inputRef} className={className} name={name} type={!type ? "text" : type} value={value && value} {...rest} autoComplete="off" /> 
    )
}

const ContainerCelula = styled.div`
width: calc(100% / 12 * ${props => props["col"]});
margin-bottom: 1rem;

@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`;

const RowCelula = styled.div`
width: 100%;
display: flex;
align-items: center;
`;

const Celula = styled.div`

width: calc(100% / 12 * ${props => props["col"]});
padding: 0.5rem;
border: 1px solid #999;
margin: 0.5px 0.5px;
color: #5b5e82;
font-weight: 500;


&.opaque {
    background-color: #ffe5c3;    
}

&.redlight {
    background-color: #c15d5d;
    color: #fff;
}
&.redlighthover {
    background-color: #ffd0d0;
    color: #ff4c4c;;    
}

&.bluelight {
    background-color: #c3d2ff;
}

input {
    width: 100%;
    display: block;
    border: none;
    font-size: 1rem;
    color: #7376a2;
    font-weight: 500;
    background-color: unset;
    color: currentColor;
}


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`;