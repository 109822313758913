/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import Content from '../../components/Content';
import GridStyled from '../../stylesheets/GridStyled';

import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonPrint from '../../assets/images/buttons/buttonblue-print.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';

import { ajax, serialize } from '../../api/chequer';

import Mask from '../../services/mask';

import { toast } from 'react-toastify';

export default function Profile() {
  return (
    <Content>
      <ProdileContent />
    </Content>
  );
}

function ProdileContent() {
  const [calculate, setCalculate] = React.useState(false);
  const [codigo, setCodigo] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [padrao, setPadrao] = React.useState('');
  const [preco, setPreco] = React.useState('');
  const [promocao, setPromocao] = React.useState('');
  const [desconto, setDesconto] = React.useState('10%');
  const [acrescimo, setAcrescimo] = React.useState('16%');
  const [plano, setPlano] = React.useState('15X');
  const [taxa, setTaxa] = React.useState('10%-16%');
  const [table, setTable] = React.useState('/tabelas/2x4');

  const [floatPrice, setFloatPrice] = React.useState('0.00');
  const [floatPricePromotion, setFloatPricePromotion] = React.useState('0.00');

  const [search, setSearch] = React.useState(false);
  const [add, setAdd] = React.useState(true);

  const [html, setHtml] = React.useState(null);
  const [saveStorage, setSaveStorage] = React.useState([]);
  const [processCash, setProcessCash] = React.useState('');
  const [process10x, setProcess10x] = React.useState('');
  const [process15x, setProcess15x] = React.useState('');

  //**Dinamic functions useState */
  const handles = {
    codigo: setCodigo,
    nome: setNome,
    padrao: setPadrao,
    preco: setPreco,
    promocao: setPromocao,
    desconto: setDesconto,
    acrescimo: setAcrescimo,
    plano: setPlano,
    taxa: setTaxa,
    table: setTable,
    handleChangeTaxa,
    processCash: setProcessCash,
    process10x: setProcess10x,
    process15x: setProcess15x,
  };

  //**Request product on local server
  async function requestProduct() {
    setSearch(true);
    const params = [{ name: 'codigo', value: codigo }];
    const body = await serialize(params);
    const response = await ajax({ url: '/services/product', method: 'post', body, json: true });
    console.log(response);

    if (typeof response.data === 'undefined') {
      setSearch(false);
      return toast.error('Socket local offline!');
    }

    if (response.data.codigoOut === '') {
      setSearch(false);
      return toast.error('Produto não cadastrado!');
    }

    setNome(response.data.nome);
    setPadrao(response.data.padrao);
    setPreco(Mask.updateMoney(response.data.preco));
    setPromocao(Mask.updateMoney(response.data.promocao));
    setFloatPrice(response.data.preco);
    setFloatPricePromotion(response.data.promocao);
    setTimeout(() => {
      setSearch(false);
      setAdd(false);
      setCalculate(true);
    }, 200);
  }

  React.useLayoutEffect(() => {
    if (calculate) {
      let avista;
      let percentual;
      setSaveStorage([]);
      //**Determina o valor do preço a vista */
      if (floatPricePromotion === '0.00' || floatPricePromotion === '0,00') {
        percentual = parseFloat(desconto.replace('%', '')) / 100.0;
        avista = Mask.updateMoney((floatPrice - percentual * floatPrice).toFixed(2));
      } else {
        avista = Mask.updateMoney(floatPricePromotion);
      }

      //Acrescimo 15X
      const acm = parseFloat(floatPrice) + (parseFloat(floatPrice) / 100.0) * parseFloat(acrescimo.replace('%', ''));
      const valorPrimario = acm / parseInt(plano.toLowerCase().replace('x', ''));

      //Acrescimo 10X
      const valorSecundario = Mask.updateMoney((floatPrice / 10).toFixed(2));

      setProcessCash(avista);
      setProcess10x(valorSecundario);
      setProcess15x(`${+String(valorPrimario).replace(/\..*$/, '')},99`);

      setHtml(`
                <div class="group-accordion col-12">
                    <button class="group-accordion__remove" data-value=${codigo}>
                        <img src="/images/buttons/button-mini-remove.svg" alt="button remove"/>
                    </button>
                    <div class="group-accordion__header">
                        <label>PRODUTO: ${nome}</label>
                    </div>
                    <div class="group-accordion__content">
                        <p>REF: ${codigo}</p>
                        <p>MARCA: ${padrao}</p>
                        <p>AVISTA: R$ ${avista}</p>
                        <p>10X: R$ ${valorSecundario}</p>
                        <p>15X: R$ ${+String(valorPrimario).replace(/\..*$/, '')},99</p>
                    </div>
                </div>
            `);

      //**Load products on localStorage */
      const storageProducts = JSON.parse(localStorage.getItem('@products')) || [];

      //** Prepare product to save in localStorage */
      const productStorage = {
        avista,
        codigo,
        padrao,
        nome,
        plano,
        valor_15: +String(valorPrimario).replace(/\..*$/, ''),
        valor_10: valorSecundario,
      };

      //**Save product in localStorage */
      storageProducts.push(productStorage);
      setSaveStorage(storageProducts);
      setCalculate(false);
    }
  }, [calculate, codigo, desconto, acrescimo, floatPricePromotion, floatPrice, nome, padrao, plano]);

  //**Calula e salva os produtos no localStorage */
  function handleCalculate() {
    setAdd(true);
    if (nome.trim() === '') return toast.error('Produto está vazio..');

    //** Append product to page
    const products = document.querySelector('.products');
    products.innerHTML += html;

    //**Save product to localStorage */
    localStorage.setItem('@products', JSON.stringify(saveStorage));

    //**Reset form */
    setCodigo('');
    setNome('');
    setPadrao('');
    setPreco('');
    setPromocao('');
    setProcessCash('');
    setProcess10x('');
    setProcess15x('');
    setFloatPrice('0.00');
    setFloatPricePromotion('0.00');
  }

  //**State change inputs dinamic */
  function stateChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    handles[name](value);
  }

  //**Load products storage */
  function loadProductsStorage(array = null) {
    const products = document.querySelector('.products');
    products.innerHTML = '';
    if (array.length > 0) {
      array.forEach((item) => {
        const html = `
                <div class="group-accordion col-12">
                    <button class="group-accordion__remove" data-value=${item.codigo}>
                        <img src="/images/buttons/button-mini-remove.svg" alt="button remove"/>
                    </button>
                    <div class="group-accordion__header">
                        <label>PRODUTO: ${item.nome}</label>
                    </div>
                    <div class="group-accordion__content">
                        <p>REF: ${item.codigo}</p>
                        <p>MARCA: ${item.padrao}</p>
                        <p>AVISTA: ${item.avista}</p>
                        <p>10X: R$ ${item.valor_10}</p>
                        <p>15X: R$ ${item.valor_15 + ',99'}</p>
                    </div>
                </div>
                `;
        products.innerHTML += html;
      });
    }
  }

  //*Altera a taxa de do produto e faz um novo calculo dos valores
  function handleChangeTaxa(e) {
    const el = e.target;
    if (el.matches('input')) {
      const [desc, acs] = el.value.split('-');
      setDesconto(desc);
      setAcrescimo(acs);
      if (floatPrice !== '0.00') setCalculate(true);
    }
  }

  //**Print table selection */
  function handlePrint() {
    return (window.location.href = table);
  }

  //Reset form
  function handleReset() {
    setCodigo('');
    setNome('');
    setPadrao('');
    setPreco('');
    setPromocao('');
    setProcessCash('');
    setProcess10x('');
    setProcess15x('');
    localStorage.setItem('@products', JSON.stringify([]));
    const products = document.querySelector('.products');
    products.innerHTML = '';
  }

  //**DidComponentAmount */
  React.useEffect(() => {
    //**Load products on storage */
    const storageProducts = JSON.parse(localStorage.getItem('@products')) || [];
    loadProductsStorage(storageProducts);

    //**Event click accordion products */
    const products = document.querySelector('.products');

    //**Event click para adição de um produto para impressão */
    function handleProductClick(e) {
      const el = e.target;

      //**Open acrodion product */
      if (el.matches('.group-accordion__header')) {
        e.preventDefault();
        const content = el.nextElementSibling;
        if (parseInt(content.style.height) !== content.scrollHeight) {
          content.style.height = content.scrollHeight + 'px';
        } else {
          content.style.height = '0px';
        }

        return;
      }

      //**Remove product on localStorage */
      if (el.matches('.group-accordion__remove')) {
        const codigo = el.dataset.value;
        const storageProducts = JSON.parse(localStorage.getItem('@products')) || [];
        const newStorageProduct = storageProducts.filter((item) => codigo !== item.codigo);
        localStorage.setItem('@products', JSON.stringify(newStorageProduct));
        loadProductsStorage(newStorageProduct);
        return;
      }
    }

    products.addEventListener('click', handleProductClick, false);

    //**Mask inputs */
    const form = document.getElementById('mask-products');
    Mask.init(form);
  }, []);

  return (
    <div className="container">
      <GridStyled />
      <div id="mask-products" className="panel-row">
        <div className="row mgtp2">
          <Group cols="col-3 col-lg-4 col-md-4 col-xs-9" text="Codigo.." name="codigo" inputMode="numeric" value={codigo} onChange={stateChange} />
          <div className="group col-4 col-xs-3">
            <GroupRow>
              <GroupRowButton onClick={requestProduct} src={MiniSearch} alt="Button add search" disabled={search} />
              <GroupRowButton onClick={handleCalculate} src={MiniAdd} alt="Button add mini" disabled={add} />
            </GroupRow>
          </div>
        </div>
        <div className="row subtitle">
          <h5>Dados do sistema</h5>
        </div>
        <div className="row">
          <Group cols="col-6" text="Produto.." name="nome" value={nome} onChange={stateChange} />
          <Group cols="col-2" text="Marca.." name="padrao" value={padrao} onChange={stateChange} />
          <Group cols="col-2 col-xs-6" text="Preço.." mask="money" name="preco" value={preco} onChange={stateChange} />
          <Group cols="col-2 col-xs-6" text="Promocao.." mask="money" name="promocao" value={promocao} onChange={stateChange} />
        </div>
        <div className="row subtitle">
          <h5>Dados processados</h5>
        </div>
        <div className="row">
          <Group cols="col-2 col-xs-4" text="Desconto.." name="desconto" value={desconto} onChange={stateChange} />
          <Group cols="col-2 col-xs-4" text="Acréscimo.." name="acrescimo" value={acrescimo} onChange={stateChange} />
          <Group cols="col-2 col-xs-4" text="Plano.." name="plano" value={plano} onChange={stateChange} />
          <Group cols="col-2 col-xs-4" text="A vista.." mask="money" name="processCash" value={processCash} onChange={stateChange} />
          <Group cols="col-2 col-xs-4" text="10X de.." mask="money" name="process10x" value={process10x} onChange={stateChange} />
          <Group cols="col-2 col-xs-4" text="15X de.." mask="money" name="process15x" value={process15x} onChange={stateChange} />
        </div>
        <div className="row subtitle">
          <h5>Taxa dos produtos</h5>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row" onClick={handleChangeTaxa}>
              <RadioGroup text="Eletro" name="taxa" value="10%-16%" checked={taxa === '10%-16%' ? true : false} onChange={stateChange} />
              <RadioGroup text="Móveis" name="taxa" value="17%-10%" checked={taxa === '17%-10%' ? true : false} onChange={stateChange} />
              <RadioGroup text="Celular" name="taxa" value="0%-14%" checked={taxa === '0%-14%' ? true : false} onChange={stateChange} />
              <RadioGroup text="Apple" name="taxa" value="0%-30%" checked={taxa === '0%-30%' ? true : false} onChange={stateChange} />
            </div>
          </div>
        </div>
        <div className="row subtitle">
          <h5>Tipo de impressão</h5>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <RadioGroup text="2x2" name="table" value="/tabelas/2x2" checked={table === '/tabelas/2x2' ? true : false} onChange={stateChange} />
              <RadioGroup text="2x4" name="table" value="/tabelas/2x4" checked={table === '/tabelas/2x4' ? true : false} onChange={stateChange} />
              <RadioGroup text="4x8" name="table" value="/tabelas/4x8" checked={table === '/tabelas/4x8' ? true : false} onChange={stateChange} />
            </div>
          </div>
        </div>
        <div className="row subtitle">
          <h5>Produtos para impressão</h5>
        </div>
        <div className="row products" style={{ overflow: 'auto', maxHeight: '250px', scrollbarWidth: 'thin' }}></div>
        <div className="row">
          <div className="btn-row col-12">
            <button className="btn-row__btn" onClick={handlePrint}>
              <img src={buttonPrint} alt="Button add mini" />
            </button>
            <button className="btn-row__btn" onClick={handleReset}>
              <img src={buttonReset} alt="Button add mini" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const Group = ({ ...props }) => {
  return (
    <div className={`group ${props.cols}`}>
      <input
        inputMode={props.inputMode}
        mask={props.mask}
        readOnly={props.readOnly}
        type={props.type ? props.type : 'text'}
        className={props.className ? props.className : 'group__input'}
        value={props.value}
        name={props.name}
        placeholder=" "
        onChange={props.onChange}
      />
      <span className="group__highlight" />
      <span className="group__bar" />
      <label className="group__label">{props.text}</label>
    </div>
  );
};

const GroupRow = ({ children }) => <div className="group-button-row">{children}</div>;

const GroupRowButton = ({ ...props }) => (
  <Fragment>
    <button onClick={props.onClick} disabled={props.disabled}>
      <img src={props.src} alt={props.alt} />
    </button>
  </Fragment>
);

const RadioGroup = ({ ...props }) => (
  <label className={props.className ? `radio-group ${props.className}` : 'radio-group'}>
    <input type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
    <span>{props.text}</span>
  </label>
);
