import React from 'react';
//import { Link } from 'react-router-dom';

import AuthContext from '../contexts/auth';
import { ajax } from '../api/chequer';

//**Components */
import Content from "../components/Content";
import usePaginate from '../hooks/usePaginate';
import { Container, Row } from '../components/Form'; //Column
import { Table, Thead, Tbody, Paginate, Flex } from '../components/Table';
import { formatDataRegex } from '../services/utils';

//import { Table, Thead, Tbody } from '../components/Table';

//import { FiPrinter, FiEdit } from 'react-icons/fi';
// import { GoProjectRoadmap } from "react-icons/go";
// import { GiInjustice, GiReceiveMoney } from 'react-icons/gi';
// import { FcSalesPerformance } from 'react-icons/fc';
import { HiOutlineCalendar } from 'react-icons/hi';
import {FaEye, FaPrint } from 'react-icons/fa';
// import { FaProductHunt, FaUser } from 'react-icons/fa'; //FaDeezer
// import { BsCurrencyExchange } from "react-icons/bs";

// import { ajax } from '../api/chequer';
// import Moment from '../services/Moment';
import styled from 'styled-components';
// import { GlobalContext } from '../contexts/global';

import Abstract from '../assets/images/abstract.jpg';
import Abstract2 from '../assets/images/abstract2.jpg';

export default function Dashboard() {
    const { user } = React.useContext(AuthContext);
    // const { roleScan } = useContext(GlobalContext);
    const [stage, setStage] = React.useState(0);
    const [dataSet, setDataSet] = React.useState([]);
    const [min, setMin] = React.useState(1);
    const [max, setMax] = React.useState(5);
    const [totalProjects, setTotalProjects] = React.useState(0);
    const [projectAprovados, setProjectAprovados] = React.useState(0);
    const [projectRejecteds, setProjectRejecteds] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const { currentPage, cbCurrentPage } = usePaginate(1);    
    const [limitCount, setLimitCount] = React.useState(0);
    // const [pedidos, setPedidos] = React.useState([]);

    // const { today } = Moment();

    function handleStatus(status) {    
        if(status === "EM VIABILIDADE TÉCNICA") return '#979797'; //#979797 | 33b9fb
        if(status === "EM APROVAÇÃO TÉCNICA") return '#6189ff';
        if(status === "EM REVISÃO") return '#a033fb';
        if(status === "AGUARDANDO REVISÃO") return '#fb8733';
        if(status === "RECUSADO") return '#fb3333';
        if(status === "AGUARDANDO O.S") return '#33b9fb';
        if(status === "EM APROVAÇÃO ADM") return '#3365fb';
        if(status === "APROVADO") return "#47a85b";
    }

    const handlePrintItem = React.useCallback((e) => {
        const el = e.target;
        const id = el.closest('tr').dataset.id;
        window.location.href = '/project/print/' + id;
    }, []);

    const handleEditItem = React.useCallback((e) => {
        const el = e.target;
        const id = el.closest('tr').dataset.id;
        window.location.href = '/project/' + id;
    }, []);

    const memoPaginate = React.useMemo(() => {
        const array = [];
        for (let i = 0; i < limitCount + 1; i++) {
          array.push(i);
        }
    
        if (currentPage + 1 > max) {
          setMax(max + 4);
          setMin(min + 4);
          return array.splice(min, min).map((item) => (
            <span className={currentPage === item ? 'active' : ''} key={item}>
              {item}
            </span>
          ));
        }
    
        if (currentPage < min) {
          setMin(min - 4);
          setMax(max - 4);
        }
        return array.splice(min, 5).map((item) => (
          <span className={currentPage === item ? 'active' : ''} key={item}>
            {item}
          </span>
        ));
    }, [limitCount, currentPage, min, max]);

    const handlePaginate = React.useCallback(
        (e) => {
          setStage(0);
          const el = e.target;
          if (el.matches('.prev')) return currentPage >= 2 ? cbCurrentPage(currentPage - 1) : cbCurrentPage(1);
          if (el.matches('.next'))
            return currentPage <= limitCount - 1 ? cbCurrentPage(currentPage + 1) : cbCurrentPage(limitCount);
          const spanActive = document.querySelector('.content span.active');
          if (spanActive && spanActive.classList.contains('active')) spanActive.classList.remove('active');
          el.classList.add('active');
          cbCurrentPage(parseInt(el.innerText, 10));
        },
        [currentPage, limitCount, cbCurrentPage],
      );

    const PaginateRender = React.useCallback(() => {
        return (
          limitCount >= 2 && (
            <Paginate onClick={handlePaginate}>
              <div className="first active">
                <span className="firstpage">1</span>
                <span className="prev">Prev</span>
              </div>
              <div className="content">{memoPaginate}</div>
              <div className="last active">
                <span className="next">Next</span>
                <span className="lastpage">{limitCount}</span>
              </div>
            </Paginate>
          )
        );
    }, [handlePaginate, memoPaginate, limitCount]);

    // const scanPermission = React.useCallback((array) => {   
    //     return roleScan(array);
    // },[roleScan])

    React.useEffect(() => {
        (async function() {
            if(stage === 0) {
                let response;
                let fetchProjectAproved;
                let fetchProjectRejected;
                if(user.user_function === "Tecnico_regional") {
                    response = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}&user_id=${user.id}` });
                    fetchProjectAproved = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}&user_id=${user.id}&status=APROVADO` });
                    fetchProjectRejected = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}&user_id=${user.id}&status=RECUSADO` });
                }else{
                    response = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}` });
                    fetchProjectAproved = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}&status=APROVADO` });
                    fetchProjectRejected = await ajax({ url: `/order-service?page=${currentPage}&limit=50&user_region=${user.user_region}&status=RECUSADO` });
                }                          
                setLimitCount(Math.ceil(+response.count / 50));
                setTotalProjects(+response.rows.length - +fetchProjectAproved.rows.length - +fetchProjectRejected.rows.length);
                setProjectAprovados(+fetchProjectAproved.rows.length);
                setProjectRejecteds(+fetchProjectRejected.rows.length);
                setDataSet(response);                
                setStage(1);
                setLoading(false);
            }
        })();        
    }, [stage, user, currentPage]);

    if (loading) return (<div></div>);
    // #ffac33 || #ff5733 || #33ff9f || #7433ff || #d733ff || #ff3380

    return (
        <Content>
            <Container>
                <GridMenuRow>
                    <GridMenuColumn className='mobile-center' col={3} xs={6}>
                        <GridMenuBox className='bgimage' backGroundImage={Abstract} backGround="#3f6ad8" color="#fff" href='' onClick={(e)=> e.preventDefault()}>
                        {/* <GoProjectRoadmap size={32} /> */}                       
                        <span>Projetos em Andamento</span>
                        <span className='number-projects'>{totalProjects}</span>
                        </GridMenuBox>
                    </GridMenuColumn>
                    <GridMenuColumn className='mobile-center' col={3} xs={6}>
                        {/* #7433ff */}
                        <GridMenuBox backGround="#10b338" backGroundImage={Abstract2} color="#fff" href='' onClick={(e)=> e.preventDefault()}>                  
                            {/* <FaProductHunt size={32} /> */}
                            <span>Projetos Aprovados</span>
                            <span className='number-projects'>{projectAprovados}</span>
                        </GridMenuBox>
                    </GridMenuColumn>
                    <GridMenuColumn className='mobile-center' col={3} xs={6}>
                        <GridMenuBox backGround="#ff5733" backGroundImage={Abstract} color="#fff" href='/users/register'>
                            {/* <FaUser size={32} /> */}
                            <span>Projetos Recusados</span>
                            <span className='number-projects'>{projectRejecteds}</span>
                        </GridMenuBox>
                    </GridMenuColumn>
                    {/* scanPermission(["in_users"]) */}
                    
                </GridMenuRow>                
                <Row>
                <div style={{ flex: 1, overflow: 'auto', scrollbarWidth: 'thin', padding: '0.5rem' }}>
                    <Table>
                        <Thead>
                        <tr className="">
                        <th scope="col">ID</th>
                            <th scope="col">Local do evento</th>
                            <th scope="col">Ação/Metodo/Evento</th>
                            {/* <th scope="col">Início evento</th>
                            <th scope="col">Encerramento evento</th> */}
                            <th scope="col" className="center">Status</th>
                            <th scope="col" className="center">Inicio do evento</th>
                            <th scope="col" className="center">Criado em</th>
                            <th scope="col" className="center">Por..</th>                           
                            <th scope="col" className="center">Ações..</th>                           
                        </tr>
                        </Thead>
                        <Tbody>
                        {dataSet.rows.map((item) => (
                            <tr data-id={item.id} key={item.id} className="">
                                <td data-label="OS">
                                    <span className="mob">OS</span>
                                    <p>{item.id}</p>
                                </td>
                                <td data-label="event_place">
                                    <span className="mob">Local do evento</span>
                                    <p>{item.event_place}</p>
                                </td>
                                <td data-label="event_name">
                                    <span className="mob">Ação/Metodo/Evento</span>
                                    <p>{item.event_name}</p>
                                </td>
                                {/* <td data-label="start_date">
                                    <span className="mob">Início evento</span>
                                    {item.start_date}
                                </td>
                                <td data-label="end_date">
                                    <span className="mob">Encerramento evento</span>
                                    {item.end_date}
                                </td> */}
                                {/* <td data-label="no_retention">
                                    <span className="mob">Sem Retenção</span>
                                    {Mask.setMoney(item.no_retention)}
                                </td>                             */}
                                <td data-label="status" className="center">
                                    <span className="mob">Status</span>
                                    <span className="badge" style={{backgroundColor: `${handleStatus(item.status)}`}}> {/* style={{ backgroundColor: item.status === true ? '#5cb85c' : '#FF4444' }} */}
                                    {item.status}
                                    {/* {item.status ? 'Ativo' : 'Inativo'} */}
                                    </span>
                                </td>
                                <td data-label="Inicio do evento" className="center">
                                    <span className="mob">Criado</span>
                                    <Flex className="center xs-left">
                                    <HiOutlineCalendar size={22} />
                                    {formatDataRegex(item.start_date)}
                                    </Flex>
                                </td>
                                <td data-label="created" className="center">
                                    <span className="mob">Criado</span>
                                    <Flex className="center xs-left">
                                    <HiOutlineCalendar size={22} />
                                    {formatDataRegex(item.createdAt)}
                                    </Flex>
                                </td>
                                <td data-label="user_name">
                                    <span className="mob">Por..</span>
                                    {item.user_name.split(" ")[0]}
                                </td>
                                <td data-label="#" className="nowrap">
                                    <span className="mob">Action</span>                
                                    <button className="action" data-id={item.id} style={{ backgroundColor: '#7400ff' }} onClick={handlePrintItem}>
                                    <FaPrint color="#fff" size={17} />
                                    </button>
                                    <button className="action" data-id={item.id} style={{ backgroundColor: '#3583f6' }} onClick={handleEditItem}>
                                    <FaEye color="#fff" size={17} />
                                    </button>                                       
                                </td>
                            </tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
                <PaginateRender />
                    {/* {(memoCan && pedidos.count > 0) && (
                        <Column col={6}>
                            <TableContainer>
                                <Table>
                                    <Thead>
                                        <tr className="">
                                            <th scope="col">Pedido</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Horário</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </Thead>
                                    <Tbody>
                                        {pedidos.count > 0 && pedidos.rows.map(item => (
                                            <tr key={item.id}>
                                                <td><span className="mob">Pedido</span>{item['pedido']}</td>
                                                <td><span className="mob">Cliente</span>{item['client_name']}</td>
                                                <td><span className="mob">Horário</span>{item['time']}</td>
                                                <td className="nowrap">
                                                    <span className="mob">Action</span>
                                                    <Link to={`/requests/show/${item['id']}`} className="action" style={{ backgroundColor: '#3583f6' }}><FiPrinter color='#fff' size={17} /></Link>
                                                    <Link to={`/requests/${item['id']}/edit`} className="action" style={{ backgroundColor: '#f6a935' }}><FiEdit color='#fff' size={17} /></Link>
                                                </td>
                                            </tr>
                                        ))}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Column>
                    )} */}
                </Row>
            </Container>
        </Content >
    )
}


// const TableContainer = styled.div`
// flex: 1;
// overflow: auto;
// scrollbar-width: thin;
// padding: 0.5rem;
// border: 1px solid #ececec;
// border-radius: 5px;
// @media only screen and (max-width: 37.5em) {
//     padding: 0;
//     border: none;
// }
// `;

const GridMenuRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
`;

const GridMenuColumn = styled.div`
    margin-right: 1rem;   
    
    width: calc(100% / 12 * ${props => props["col"]});    

    @media only screen and (min-width: 82em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }   

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
        
        &.mobile-center {
            margin-right: initial;
            display: flex;
            justify-content: center;
        }
    }  
`;

const GridMenuBox = styled.a`
    position: relative;
    border-radius: 5px;
    box-shadow: 0 1px 10px #436d932e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    user-select: none;
    color: ${props => props.color};
     
    margin-bottom: 0.5rem;   
    background-color: ${props => props.backGround};
    opacity: 1;
    

    @media only screen and (max-width: 50em) {
        padding: initial;
        width: calc(100% - 0.5rem);
        padding: 1rem 0;
    }  

    svg{
        margin-bottom: 5px;
        transition: all .2s ease;
    }

    span {
        transition: all .2s ease;

        
    }
   
    &::before{      
        content: '';  
        background-image: url(${props => props.backGroundImage});        
        background-size: cover;  
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        width: 100%;          
        transition: all .2s ease; 
        transform: scale(0.98);  
        opacity: 0.1;    
    }   

    &:hover {
        cursor: pointer;       
        
        &::before{
            transform: scale(1); 
        }
    }   

    &:hover span,
    &:hover svg {
        transform: scale(1.1);        
    }
    & .number-projects {
        font-size: 2.5rem;
    }
`;
