import React from 'react';
import '../stylesheets/pages/toggle.css';


export default function ToggleWrap(props){
    function handleClick(e){
        const root = document.getElementById("root");
        root.classList.toggle("active");  
    }

    return(
        <div className={'toggle-wrap'} onClick={handleClick}>
            <div className="toggle-bar"></div>
        </div>
    )
}