import React from "react";
import Routes from "./routes/web";
import { BrowserRouter } from 'react-router-dom';

//** Toast alert
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//**Socket io */
import { io } from "socket.io-client";

//**AuthContext Provider */
import {AuthProvider} from './contexts/auth';
import GlobalProvider from './contexts/global';
import FormProvider from "./components/FormRef/FormContext";

const host = "http://127.0.0.1:8200";
const SOCKET_TOKEN = "a)dQunxKr_evq35]6NR.cH-<S#,U;!t>ygJfZ2p9h(@?CELPBY";

const socket = io(host, {
    reconnection: true,
    autoConnect: true,
    query: { name: SOCKET_TOKEN }
});


function App() { 
  return (    
    <GlobalProvider socket={socket}> 
      <FormProvider>
        <AuthProvider socket={socket}>
          <BrowserRouter>
            <Routes/>
            <ToastContainer autoClose={3000}/>
          </BrowserRouter> 
        </AuthProvider>   
      </FormProvider> 
    </GlobalProvider> 
  );
}

export default App;