// https://apexcharts.com/docs/options/tooltip/
import React from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function FloatToReal(val, cifrao = null) {
    if(cifrao) {
        const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val).replace("R$", "");
        return value;
    }else{
        const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
        return value;
    }    
}

const options  = {    
    labels: ['CONFINS', 'CSLL', 'FGTS', 'GPS', 'IRPJ', 'PIS'],
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#BB33FF'],
  
    legend: {  
        position: 'left',
        fontSize: 14,
        fontFamily: "Montserrat",
        formatter: function(seriesName, opts) {            
            const value = FloatToReal(opts.w.globals.series[opts.seriesIndex], true);
            return [seriesName, "-" + value]
        },        
    },
    
    tooltip: {
    enabled: true,
    y: {
        formatter: function(val) {            
            const value = FloatToReal(val, true)
            return value;
        }
    }        
    }, 
    plotOptions: {
        pie: {
            expandOnClick: true,              
        }
    },
    // Se remover o default e porcentagem
    // dataLabels: {
    //     enabled: true,
    //     formatter: function (val) {
    //         return ''
    //     },
    // }
};

const optionsDonut  = {   
    chart: {
        width: '100%',
        height: 'auto',
    } ,

    // labels: ['CONFINS', 'CSLL', 'FGTS', 'GPS', 'IRPJ', 'PIS'],    
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#BB33FF'],
    
    legend: {  
        position: 'left',
        fontSize: 12,
        fontFamily: "Montserrat",
        formatter: function(seriesName, opts) {                       
            const value = FloatToReal(opts.w.globals.series[opts.seriesIndex], true);
            return [seriesName, "-" + value]
        },        
    },  

    responsive: [{
        breakpoint: 1200,
        options: {
            legend: { 
                position: 'bottom',
                fontSize: 10,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            value: {
                                fontSize: 14,
                            }
                        }
                    }
                }
            }
        },
    }],
    
    
    tooltip: {
        enabled: false,
        y: {
            formatter: function(val) {
                // return value + "#"
                const value = FloatToReal(val, true)
                return value;
            }
        }        
    }, 
    plotOptions: {
        pie: {
            expandOnClick: true,
            //customScale: 1.01,            
            donut: {
                size: '45',
                labels: {
                    show: true,                    
                    value: {
                        show: true,  
                        fontSize: 16,
                        fontFamily: "Montserrat",
                        formatter: function(val) {
                            const valueFormated = FloatToReal(val, true);
                            return valueFormated;                            
                        }
                    },
                    total: {
                        show: true,  
                        fontSize: 15,   
                        color: 'green',
                        fontFamily: "Montserrat",
                        formatter: function({globals}) {                           
                            const sum = globals.series.reduce((a, b) => a + b);
                            const total = FloatToReal(sum, true);
                            return total
                        }
                    }
                }
            }            
        }
    },    
};

const seriesDonut  = [randomIntFromInterval(1000, 60000), randomIntFromInterval(1000, 60000), randomIntFromInterval(1000, 60000), randomIntFromInterval(1000, 60000), randomIntFromInterval(1000, 60000), randomIntFromInterval(1000, 60000)];

export function Donut({series, labels}) {  
    console.log(seriesDonut); 
    optionsDonut.labels = labels;
    return (
        <div className='donut'>
            <Chart series={series} options={optionsDonut} type="donut" />
        </div>
    );
}

export function Pie() {
    return (
        <div className='pie' style={{width: '100%', height: '100%', display: 'flex', overflow: 'hidden', padding: '10px 5px'}}>
            <Chart series={seriesDonut} options={options} type="pie" />
        </div>
    );
}

export const ChartBox = styled.div`
@media only screen and (min-width: 75em) {
    width: 30rem;
    height: 18rem;
}

/* width: calc(100% / 12 * ${props => props["col"]}); */
padding: 5px;


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 85em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`;

export const ChartContent = styled.div`
height: 100%;
&.border {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 10px #436d932e;
    border-radius: 5px;
}

&.flex {
    display: flex;
    align-items: center;
}

h5 {
    padding: 5px 1rem;
    font-weight: 500;
    font-size: 1rem;
    border-bottom: 1px solid #f2f2f2;
}
`;

// const seriesBar  = [
//     {
//         name: 'Venda',
//         data: [
//             randomIntFromInterval(1000, 60000),
//         ]
//     }
// ];

const optionBar = {
    chart: {
        height: 350,
        type: 'bar',
    },    

    legend: {  
        show: false,
        position: 'bottom',     
        fontSize: 12,
        fontFamily: "Montserrat",              
    },  

    plotOptions: {
        bar: {      
            columnWidth: '65',             
            distributed: true, // trur , false
            borderRadius: 20,
            dataLabels: {               
                position: 'top', // top, center, bottom                
            },
        }
    },

    dataLabels: {
        enabled: true,        
        formatter: function (val) {
            if(val === 0) return "FECHADA";            
            const valueFormated = FloatToReal(val, true);
            return valueFormated;
            //return val + "%";
        },
        offsetY: -20,
        style: {
        fontSize: '9px',
        fontFamily: "Montserrat",
        color: 'green',
        colors: ["#304758"]
        }
    },

    xaxis: {        
        // categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"],
        categories: null,
        position: 'bottom',
        axisBorder: {
        show: false
        },
        axisTicks: {
        show: false
        }, 
    },
    tooltip: {
        enabled: true,
        x: {
            formatter: function(val) {                
                return "Dia " + val;
            }
        },
        y: {
            formatter: function(val) {
                const value = FloatToReal(val, true)
                return value;
            }
        }        
    },     
};

export function Bar({series, categories, width, height}) {    
    // optionsDonut.labels = labels;
    let Height = height;
    
    if(window.innerHeight < 750) {
        Height = 400;
    }
   
    optionBar.xaxis.categories = categories;

    // optionBar.plotOptions.bar.columnWidth = columnWidth;

    return (
        <div id='chart' className='bar'>
            <Chart id="chart" series={series} options={optionBar} type="bar" width={width} height={Height}/>
        </div>
    );
}

