import React from "react";
import styled from "styled-components";
import {FiXSquare} from 'react-icons/fi';

function ModalStyled({children, className, id, type, close, is, title, ...props}) {
    return(
        <div className={className} id={id} data-type={type}>
            <div className={`modal ${is ? 'open' : ''}`}>
                <header>
                    <h2>{title}</h2>
                    <button onClick={close}><FiXSquare size={25}/></button>
                </header>
                {children}
            </div>
            <div className="overlay" onClick={close} />
        </div>
    )
}

export const Modal = styled(ModalStyled)`
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.2);    
    z-index: -1;
}

.modal {
    position: absolute;
    width: ${props => props.lgW ? props.lgW : '70%'};
    height: ${props => props.lgH ? props.lgH : '50%'};
    /* width: 70%;
    height: 50%; */
    background-color: #fff;
    top: 50%;    
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
    max-width: 70rem;
    z-index: -1;
    
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;

    &.open .overlay {
        z-index: 999;
    }

    &.open {
        // animation: modalTop ease-in-out forwards;
        z-index: 9999;
        animation-name: modalTop;
        animation-duration: .4s;
        animation-timing-function: cubic-bezier(.85,1.53,.93,1);   
        animation-fill-mode: forwards;    
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #464646;
        padding: 5px 1rem;
        color: #fff;
        h2{
            font-size: 1rem;
        }
        button {
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            background-color: transparent;
            color: currentcolor;
            &:focus {
                outline: none;
            }

            svg {
                stroke: currentcolor;
            }
        }
    }

    .content {
        position: relative;
        flex: 1; 
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        
        & .content__header,
        & .content__main {
            width: 100%;            
        }

        & .content__main {
            overflow: auto;
            flex: 1;
        }
    }

    .content__main {
        overflow: auto;
        flex: 1;
    }

    @media only screen and (max-width: 75em) {
        width: ${props => props.mdW ? props.mdW : '90%'};
        height: ${props => props.mdH ? props.mdH : '60%'};
    }

    @media only screen and (max-width: 37.5em) {
        width: ${props => props.xsW ? props.xsW : '90%'};
        height: ${props => props.xsH ? props.xsH : '90%'};
    }
}

.modal.open ~ .overlay {
     z-index: 999;
}

@keyframes modalTop {
    0% {
        opacity: .1;  
        transform-origin: center;      
        transform: translate(-50%, -72%);
    }    

    80% {       
        opacity: .8;
        transform-origin: center; 
        transform: translate(-50%, -45%);
    }

    100% {       
        opacity: 1;
        transform-origin: center; 
        transform: translate(-50%, -50%);
    }
}
`;