import React, {Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import { ajax } from '../../api/chequer';
import Logo from '../../assets/images/logochequer.png';
import Moment from '../../services/Moment';
import Utils from '../../services/utils';

export default function ReportList(){
    const {search} = useLocation();
    const [total, setTotal] = React.useState(null);
    const {splitName} = Utils();
    const {stringWeekDayMonth, todayAddDay, time, converteToTime} = Moment();
    React.useEffect(()=> {
        async function loadPrintPage() {     
            const page = document.querySelector(".page");
            const response = await ajax({url: `/timerecords${search}`});
            
            let nextPage = 1000;
            let wrap = page.querySelector(".wrap");           
            let wraptbody = page.querySelector(".wrap-tbody"); 

            function TotalExtraCalculator(totalBase){
                const DayInSeconds = 86400;
                
                if(Math.sign(totalBase) < 0) {
                    if(Math.abs(totalBase) >= DayInSeconds) {
                        const day = Math.floor(Math.abs(totalBase) / DayInSeconds);               
                        return `-${day}D ${converteToTime(totalBase)}`; 
                    }
                    return converteToTime(totalBase)
                }       
        
                if(totalBase >= DayInSeconds) {
                    const day = Math.floor(totalBase / DayInSeconds);               
                    return `${day}D ${converteToTime(totalBase)}`;
                }
        
                return converteToTime(totalBase);
            }
            
            function calllbackItem(item){
                return item ? item : "00:00:00";
            }

            async function processWait(item) {
                return new Promise(async resolve => {
                    wraptbody.innerHTML += `
                    <tr>
                        <td>${splitName(item.user.name, 2)}</td>
                        <td>${stringWeekDayMonth(item.data)}</td>
                        <td class="green">${item.entry}</td>
                        <td class="indigo">${calllbackItem(item.interval_start)}</td>
                        <td class="orange">${calllbackItem(item.interval_back)}</td>
                        <td class="teal">${calllbackItem(item.output)}</td>
                        <td>${calllbackItem(item.workend_hour)}</td>
                        <td>${calllbackItem(item.extra_hour)}</td>                   
                    </tr>
                    `;

                    await wrapCheck();

                    resolve();
                });
            }

            function create() { 
                return new Promise(resolve => {
                    wrap = wrap.cloneNode(true);
                    wrap.classList.add("page-break");
                    wraptbody = wrap.querySelector(".wrap-tbody");
                    wraptbody.innerHTML = "";
                    // wrap.classList.remove("first");
                    page.appendChild(wrap);
                    resolve();
                })      
            }

            async function wrapCheck(){
                return new Promise(resolve => {                    
                    if(wrap.clientHeight > nextPage) {
                        create();      
                        setTimeout(() => {
                            // nextPage += 500;
                            resolve(true);
                        }, 100);                  
                    }else{
                        resolve(true);
                    };                    
                });
            }            
            
            async function processRows(array){
                return new Promise(resolve=> {
                    async function Load(){
                        for(let item of array) {                        
                            await processWait(item);                                               
                        }
                        resolve();
                    }   
                    Load();                 
                });
            }             
            await processRows(response.rows); 

            if(response.extra) {
                setTotal(TotalExtraCalculator(response.extra[0].total));
            } 
            
        }

        loadPrintPage();

        const btnPrint = document.querySelector("#btn-print"); 
        btnPrint.addEventListener("click", function() {
            window.print();
        });           
    }, [search, splitName, stringWeekDayMonth, converteToTime]);


    return(
        <Fragment>
            <GlobalStyle/>
            <FixedHeader className="fixed-header print"/>
            <div className='page'>
                <div className='wrap'>
                    <div className='wrap-container'>
                        <div className='wrap-header'>
                            <Row>
                                <Column className='auto'>
                                    <Row className='titulos'>
                                        <Column col={12}><h2>RELATÓRIO DE PONTO</h2></Column>
                                        <Column col={12}><h4>CHEQUER MEGASTORE</h4></Column>
                                        {total && <Column col={12}><h4>Extra: {total}</h4></Column>}
                                    </Row>
                                </Column>
                                <Column col={4}>
                                    <Row>
                                        <Column className='auto'><img src={Logo} alt="logotipo"/></Column>
                                        <Column col={6}>
                                            <Row className='times'>
                                                <Column col={12}>Data: {todayAddDay({format: "dd/mm/yyyy"})}</Column>
                                                <Column col={12}>Hora: {time()}</Column>
                                            </Row>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                        </div>
                        <div className='wrap-content'>
                            <table>                   
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Data</th>
                                        <th>Entrada</th>
                                        <th>Inicio Int.</th>
                                        <th>Volta Int.</th>
                                        <th>Saida</th>
                                        <th>Trabalhou</th>
                                        <th>Extra</th>
                                    </tr>
                                </thead>                   

                                <tbody className='wrap-tbody'></tbody>
                                {/* <tfoot>
                                    <tr>
                                    <th>tfoot teste</th>
                                    <th>tfoot teste</th>
                                    </tr>
                                </tfoot> */}
                            </table>                    
                        </div>
                    </div>
                </div>                
            </div>            
            {/* {(planilhas && planilhas.length > 0)  && <Wrap className="wrap first" data={planilhas}/>} */}
        </Fragment>
    )
}

const Row = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
`;

const Column = styled.div`
width: calc(100% / 12 * ${props => props["col"]});

@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}

&.auto {
    width: auto;
    flex: 1
}
`;

const FixedHeader = ({className, children}) => (
    <div className={className}>
        <div className="fixed-title">
            <h6>Relatório de ponto</h6>
        </div>
        <div className="buttons">
            <a href="/ponto/list">
                <img src="/images/buttons/button-mini-back.svg" alt="Boltão voltar"/>
            </a>
            <button id="btn-print">
                <img src="/images/buttons/button-mini-print.svg" alt="Boltão voltar"/>
            </button>
        </div>
        {children}        
    </div>
)

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    background: transparent;
    color: #000;
    text-shadow: none; 
    filter: none;
    -ms-filter: none;
}

#root {
    background-color: #525659;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(82, 86, 89) !important;
}

img {
    display: block;
    width: 100%;
}

.back {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    font-size: 10pt;
    transform: translate(-35px,-1px);
}
.fixed-header {   
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 15mm;
    background-color: rgb(50, 54, 57);
    box-shadow: 1px 1px 5px #000;
    color: #fff;    
    z-index: 2;    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 10000;    
}

.buttons {
    display: flex;
    align-items: center;
}

.fixed-header button {
    transform: all;
    background-color: transparent;
    box-shadow: none;
    border: none
}

.fixed-header .fixed-title h6 {
    color: #fff;
}

.fixed-header button img {
    width: 35px;
}

.fixed-header button img:hover {
    transform-origin: center;
    transform: scale(1.1);
    cursor: pointer;
    transition: all .2s;
}

.buttons a {
    margin: 5px;
}
.buttons a img{
    width: 35px;
}

.fixed-header .wrap-center {
    width: 210mm;
    margin: 0 auto;
}

.wrap{
    position: relative;
    margin: 17mm auto auto auto;
    width: 210mm;
    height: auto; //297mm
    color: #444;
    background-color: #fff;    
    margin-bottom: 5mm;
    padding: 1cm;
}

.wrap-container {       
    height: 100%;
    width: 100%;
    border: 1px solid #000;
}

.wrap-header {
    margin: 3px 3px;
    padding: 10px 5px;
    border: 1px solid #000;
    border-radius: 3px;    

    img{
        width: 100px;
        height: 55px;
    }   

    & .titulos {        
        h2 {
            color: #993737;
            font-size: 14pt;
            margin-bottom: 5px;
           
        }

        h4 {            
            font-size: 11pt;
            margin-bottom: 5px;
           
        }
    }
    
    & .times {
        font-size: 10pt;
        font-weight: 600;
    }
}

.wrap-content {
    margin: 3px 3px;
    border: 1px solid #000;
    border-radius: 3px;
    padding: 10px 5px;
}

table
{
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
}
table th
{
    background-color: #F7F7F7;
    color: #333;
    font-weight: bold;
    font-size: 10pt;
}
table th, table td
{
    padding: 5px;
    border: 1px solid #ccc;
}

table tbody td {
    font-size: 10pt;
    /* text-shadow: 0 0 1px #000; */
    &.green {
        color: #678E0A;
    }
    &.indigo {
        color: #6610f2
    }
    &.orange {
        color: #f68710;
    }
    &.teal {
        color: #4dc0b5;
    }
}

/* @page :left {
    margin: 1mm;
}

@page :right {
    margin: 1mm;
} */

@page {
    /* size: A4;     */
    size: auto; 
    margin: 0;
}

@media print {
    #root {
        background-color: #fff;
    }

    .fixed-header.print {
        display: none;
    }

    .wrap{
        position: relative;        
        width: 210mm; //210mm
        height: auto; //297mm
        color: #444;
        background-color: #fff; 
        margin: 0 auto; 
        padding: 0 auto;
    }

    .wrap-container {       
        height: 100%;
        width: 100%;
        border: 1px solid #999 !important;
    }

    table {
        /* page-break-before: always; */
        /* page-break-after: always; */
        /* page-break-inside: avoid; */
    }

    // Avoid page breaks straight after a heading.
    h1, h2, h3, h4, h5, h6
    {
    /* page-break-after: avoid; */
    }


    /* Avoid page breaks inside paragraphs, blockquotes, lists, and preformatted
    text. */
    p, blockquote, ul, ol, dl, pre
    {
    page-break-inside: avoid;
    }

    .page-break { 
        page-break-before: always; 
    }    

    p {    
    orphans: 3;
    }

    /* tr{
        page-break-after: always;
        display: block;
    } */
}
`;
