import React from 'react';
import Content from '../../components/Content';
import styled, { css } from 'styled-components';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormSelect, FormInput, ButtonRow, TextArea } from '../../components/FormRef/Form';
import { Table, Thead, Tbody } from '../../components/Table';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';
//**Icons */
import { FaClipboardList } from "react-icons/fa";

// import { FiFileText } from 'react-icons/fi'; //FiFileText
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import { FormContext } from '../../components/FormRef/FormContext'
import { GlobalContext } from '../../contexts/global';
import AuthContext from '../../contexts/auth';
import { ajax, serialize, formData } from '../../api/chequer';
import { toast } from 'react-toastify';

//**Utilidades */
import Mask from '../../services/mask';
import Moment from '../../services/Moment';

//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
//const MySwal = withReactContent(Swal);

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {   
  const { fields } = React.useContext(FormContext);
  const [stage, setStage] = React.useState(0);
  // const [totalPrices, setTotalPrices] = React.useState("0,00");
  const { roleScan } = React.useContext(GlobalContext);
  const { user } = React.useContext(AuthContext);
  const [stateButton, setStateButton] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [checkedProduct, setCheckedProduct] = React.useState([]);
  const [isView, setIsView] = React.useState(false);
  const { todayAddDay } = Moment(); //converteToTime
  const [formTotalValue, setFormTotalValue] = React.useState([]);
  const modalRef = React.useRef(null);
  const [today] = React.useState(todayAddDay({ format: 'yyyy/mm/dd' }));
  const [regiao] = React.useState([{ name: 'Norte', value: 'norte' },
    { name: 'Noroeste', value: 'noroeste' },
    { name: 'Sul', value: 'sul' },
    { name: 'Centro', value: 'centro_oeste' },
    { name: 'Serrana', value: 'serrana' },
    { name: 'Escritório', value: 'escritorio' },])

  //**Permissões */
  // const aDmin = React.useMemo(() => roleScan(), [roleScan]);
  // const cr_Register = React.useMemo(() => roleScan(['cr_products']), [roleScan]);
 
  //** Reset */
  const handleReset = React.useCallback((e)=> {
    e!== undefined ? e.preventDefault() : e = window.event;
    fields.codigo.value = "";
    fields.name.value = "";
    fields.price.value = "0,00";
    fields.retention.value = "0,00";
    fields.no_retention.value = "0,00";
    fields.status.value = true;
    fields.product_category_id.value = ""
  }, [fields])

  const dynamicSort = React.useCallback((property)=> {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {        
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }, []);

  const checkPrazoEvento = React.useCallback(() => {
    //const splitData = fields["start_date"].value.split('-');
    const splitData = today.split('-');
    const d  = new Date(splitData[0], splitData[1], splitData[2]);
    const data = new Date(d.getFullYear(), d.getMonth() - 1, (d.getDate() + 19));
  
    const field1 = new Date(fields['start_date'].value);
    const field2 = new Date(fields['end_date'].value);
  
    // const lDate = new Date(e.target.value);   
  
    if((data >= field1) || (data >= field2)) {
       toast.error("O prazo do evento nao pode ser inferior a 20 dias");
       return false;
    }
    
    return true;
   },[today, fields]);

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    let file = false;
    // if(!checkPrazoEvento()) {      
    //   return
    // }
    setStateButton(true); 

    function unFormat(ev) {
      return ev.replace(".", "").replace(",", ".");
    }

    const productAll = document.querySelectorAll('.product');
    const productArray = [];
    [...productAll].forEach((p)=> {
      productArray.push({       
        product_category_id: +p.dataset.category, 
        product_id: p.children[0].value, 
        codigo: p.children[1].children[0].value, 
        name: p.children[2].children[0].value,
        quantity_day: p.children[3].children[0].value,
        quantity_total: p.children[4].children[0].value,
        value_unit: unFormat(p.children[5].children[0].value),
        value_total: unFormat(p.children[6].children[0].value),
        unit_of_measurement: p.children[7].children[0].value,
      })
    })

    const params = [
      { name: 'user', value: {name: user.name, id: user.id, user_function: user.user_function}},
      { name: 'user_region', value: fields['user_region'].value},
      { name: 'technical_manager', value: fields.technical_manager.value },
      { name: 'event_place', value: fields.event_place.value },
      { name: 'event_name', value: fields.event_name.value },
      { name: 'start_date', value: fields.start_date.value },
      { name: 'end_date', value: fields.end_date.value },
      { name: 'hour_start', value: fields.hour_start.value },
      { name: 'hour_end', value: fields.hour_end.value },
      { name: 'event_duration', value: +fields.event_duration.value },
      { name: 'number_of_participants', value: +fields.number_of_participants.value },     
      {name: 'price_total', value: unFormat(fields.price_total.value)},
      {name: 'observations', value: fields.observations.value},
      {name: "event_description", value: fields.event_description.value},
      { name: 'order_products', value: productArray },      
    ];

    if (fields['upload_archive'].value.trim() !== '') {
      params.push({ name: 'upload_archive', type: 'file', value: fields['upload_archive'].files });  
      file = true;   
    }

    const body = await serialize(params); 
    const response = await ajax({ url: '/order-service/store', method: 'post', body, json: true }); 

    if(file) {
        params.push({ name: 'id', value: response.id });
        const body = await formData(params);
        await ajax({ url: '/uploadfile', method: 'post', body }); 
        toast.success(response.message);        
        file = false;
        setTimeout(() => {
          setStateButton(false);      
          window.location.reload();
        }, 3000);
    }else{
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) {
        toast.success(response.message);       
        setTimeout(() => {
          setStateButton(false);      
          window.location.reload();
        }, 3000);
      };
    }   
    

    // if(cr_Register) {
    //   const response = await ajax({ url: '/order-service/store', method: 'post', body, json: true });      
    //   if (response.error) return toast.error(response.error);
    //   if (response.success && response.message) toast.success(response.message);
    //   handleReset()
    // }else{
    //   MySwal.fire("Você não tem permissão para atualizar usuários!");
    // }

    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, user, checkPrazoEvento]);

  //** Click da Checkbox do modal */
  const handleCheckBox = React.useCallback((e)=> {  
      const id = e.target.dataset.id; // id do produto  
      const categoryId = e.target.dataset.category; // id da categoria 
      const newCategory = categoryOptions.filter((f) => +f.value !== +categoryId);
      const category = categoryOptions.filter((f) => +f.value === +categoryId);
      const categoryEdited = category.filter((c)=> c.products.map((p)=> {
        if(p.id === +id) {  
          if(p.checked === true) {          
            p.checked = !p.checked 
            const tds = formTotalValue.filter((i) => +i.id !== +p.id);          
            setFormTotalValue(tds)
          }else {         
            p.checked = !p.checked 
            setFormTotalValue((old)=> [...old, {id: p.id, price: p.price, quantity_total: 1}]); 
          }                   
        }
        setStage(2)
        return p;        
    }));
    
    categoryEdited[0].products.sort(dynamicSort("id"));
    const result = [...categoryEdited, ...newCategory];
    result.sort(dynamicSort("value"));
    setCategoryOptions(result);
  },[categoryOptions, formTotalValue, dynamicSort]);
  //** Fecha o modal */
  const CloseModal = React.useCallback((e)=> {    
    if (e.target.classList.contains('open') || e.target.classList.contains('view-close')) {      
      modalRef.current.removeEventListener('click', CloseModal)
      setIsView(false)
    }
  },[]);

  //** Abre o modal */
  const OpenModal = React.useCallback((e)=> {
    e.preventDefault();  
    const id = e.target.dataset.id;
    const result = categoryOptions.filter((item)=> {      
      if(+item.value === +id) return item;
      return false;
    });
    setCheckedProduct(result);
    setIsView(true);   
    modalRef.current.addEventListener('click', CloseModal);    
  },[categoryOptions, CloseModal]);

  //** Calcula a quantidade de itens */
  const totalCalculate = React.useCallback((e)=> {
    function unFormat(ev) {
      return ev.replace(".", "").replace(",", ".");
    }
    const totalValue = e.target.offsetParent.nextSibling.nextSibling.children[0];
    const quantity_total = e.target.value;
    const element = e.target.parentElement.parentElement;  
    const id = +element.children[0].value   
    const tds = formTotalValue.filter((i) => +i.id !== id);    
    setFormTotalValue([...tds, {id: id, price: unFormat(element.children[5].children[0].value), quantity_total: quantity_total}]);
    
    const total = unFormat(element.children[5].children[0].value) * +quantity_total;
    totalValue.value = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
    setStage(2);
  }, [formTotalValue]);

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  const checkPermissiom = React.useCallback((evento, css)=> {   
    if(stage === 3) {
      if(scanPermission(evento) === undefined) return css      
      setStage(4);
    }    
  }, [stage, scanPermission]) 

  React.useEffect(() => {
    if(stage === 0) {
      async function fetchProductCategory() {   
        return new Promise(async (resolve)=> {
          function dynamicSort(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                 * and you may want to customize it to your needs
                 */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
          }
  
          const response = await ajax({ url: `/product/category` });      
          const options = [];
          if(response.rows) response.rows.map((item) => options.push({value: item.id, name: item.name, products: item.products.sort(dynamicSort("id"))}));
          setCategoryOptions(options.sort(dynamicSort("value")));
          resolve(true);
        });               
      }
      (async function() {
        await fetchProductCategory();   
      })();
      setStage(2)
    }    
  }, [fields, stage]);
  
  React.useEffect(()=> {     
    if(stage === 2) {         
      if(fields['price_total'] !== undefined) {
        //Mask.timeSec(fields['hour_start']);
        //Mask.timeSec(fields['hour_end']);
        if(formTotalValue.length >=1) {
          let valorTotal = 0;  
          if(formTotalValue && formTotalValue.length >= 1) {        
            formTotalValue.map((p)=> {
              const total = p.price * +p.quantity_total;
              valorTotal = +valorTotal + +total;
              return p;
            });       
          }      
          fields['price_total'].value = valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});
          if(scanPermission(['total_view'])) toast.success("Total dos items R$ "+ fields['price_total'].value);        
        }else{
          fields['price_total'].value = "0,00";
        }  
      }
      setStage(3)
    }    
    // Cleanup side effect
    // return () => {
    //   setTotalPrices("0,00");
    //   setFormTotalValue([]);
    // };
  }, [fields, formTotalValue, stage, scanPermission]); 

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}> 
        {/* ver_total_projeto */}
        <Panel className={scanPermission(["total_view"]) ? "" : "hidden"} style={checkPermissiom(['total_view'], {visibility: 'hidden', position: 'absolute'})}>
          <RowTitle text="TOTAL"/>
          <FormRow>
            <FormInput
              required={true}              
              inputMode="numeric"
              text="VALOR TOTAL.."
              col={3}
              name="price_total"
              defaultValue={"0,00"}
              readOnly={true}
            />           
          </FormRow>
        </Panel>       
        <Panel>
          <RowTitle text="DADOS DA ATIVIDADE">
            <FaClipboardList size={25} />
          </RowTitle>
          <TextArea col={12} text="Descrição do evento.." name="event_description" wrap="off" rows={7} className="noResize width99" style={{ marginTop: "5px"}}/>
          <FormSelect 
            label="Regiao do projeto.."
            options={regiao} 
            col={2}
            md={4}
            lg={3}
            name="user_region"
            firstText="Selecione..."
            //onChange={handleChange}
          />
          <FormInput
            required={true}            
            text="Responsável Técnico.."
            col={2}
            md={4}
            lg={3}
            name="technical_manager"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}            
            text="Local do Evento.."
            col={2}
            md={4}  
            lg={3}
            name="event_place"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}            
            text="Ação/Método/Evento.."
            col={2}
            md={4}  
            lg={3}
            name="event_name"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}
            type="date"
            inputMode="numeric"
            text="Data / Horário de início.."
            defaultValue={todayAddDay({ days: 20, format: 'yyyy/mm/dd' })}
            col={2}
            md={4}  
            lg={3}
            name="start_date"
            //onChange={checkPrazoEvento}
          />
          
          <FormInput
            required={true}
            type="date"
            inputMode="numeric"
            text="Data / Horário de encerramento.."
            defaultValue={todayAddDay({ days: 20, format: 'yyyy/mm/dd' })}
            col={2}
            md={4}
            lg={3}
            name="end_date"
            //onChange={checkPrazoEvento}
            //readOnly={checkOnly()}
          />

          <FormInput
            required={true}
            inputMode="numeric"
            text="Horário / Inicio"
            col={2}
            md={4}
            lg={3}
            name="hour_start"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}
            inputMode="numeric"
            text="Horário / Final"
            col={2}
            md={4}
            lg={3}
            name="hour_end"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}
            inputMode="numeric"
            text="Duração do evento (dias).."
            col={2}
            md={4}
            lg={3}
            name="event_duration"
            defaultValue={""}
            //readOnly={checkOnly()}
          /> 

          <FormInput
            required={true}
            inputMode="numeric"
            text="Número de participantes.."
            col={2}
            md={4}
            lg={3}
            name="number_of_participants"
            defaultValue={""}
            //readOnly={checkOnly()}
          /> 
          <FormInput            
            type="file"
            text=""
            col={2}
            md={4}
            lg={3}
            name="upload_archive"
            defaultValue={""}           
            //readOnly={checkOnly()}
          /> 
          <TextArea col={12} text="Observações gerais.." name="observations" wrap="off" rows={7} className="noResize width99" />
        </Panel>              
        <Panel id={"products"}>
            {categoryOptions && categoryOptions.map((item) => (  
              <FormRow key={item.value}>
                <RowTitle text={item.name.toUpperCase()}>
                  <input type={"hidden"} defaultValue={item.value}/> 
                  <AbsoluteButton>
                    <ButtonRow dataSet={item.value} size={"1.8rem"} top={"0.3rem"} name="btnAdd" src={MiniAdd} alt="mini" onClick={OpenModal}/> 
                  </AbsoluteButton>                              
                </RowTitle>
                {item.products && item.products.map(fb => (
                  fb.checked === true && (
                    <FormRow key={fb.id} id={'products_' + fb.id} className='product' data-category={item.value}>
                      <input name="id" value={fb.id} type='hidden'/>
                      <InputCategory 
                        data-category={item.value}
                        readOnly={true}
                        defaultValue={fb.codigo}                      
                        inputMode="numeric"
                        text="Código.."
                        col={1}
                        name={"codigo" + fb.id}
                      />      
                      <InputCategory
                        readOnly={true}
                        defaultValue={fb.name} 
                        text="Descrição.."
                        col={4}
                        name={"name" + fb.id}                      
                      />                      
                      <InputCategory
                        required={true}
                        inputMode="numeric"
                        defaultValue={1}
                        text="QTD/DIA.."
                        col={1}
                        name={"quantity_day" + fb.id}                     
                      /> 
                      <InputCategory
                        data-category={item.value}
                        required={true}
                        inputMode="numeric"
                        defaultValue={1}
                        text="QTD TOTAL.."
                        col={1}
                        name={"quantity_total" + fb.id} 
                        onChange={totalCalculate}                     
                      />

                      <InputCategory
                        required={true}
                        className={scanPermission(["price_view"]) ? "" : "hidden"}
                        text="Valor Unit.."
                        col={1}
                        name={"value_unit"}
                        value={Mask.updateMoney(fb.price)} 
                        readOnly={true} 
                        //onClick={(e) => Mask.money(e.target)}
                      /> 
                      <InputCategory
                        required={true}
                        className={scanPermission(["price_view"]) ? "" : "hidden"}
                        text="Valor Total.."
                        col={2}
                        name={"value_total"}
                        value={Mask.updateMoney(fb.price)}
                        readOnly={true}                     
                      />
                      <InputCategory
                        readOnly={true}
                        defaultValue={fb.unit_of_measurement} 
                        text="Un. Medida.."
                        col={scanPermission(["price_view"]) ? 2 : 5}
                        name={"unit_of_measurement" + fb.id}                      
                      />
                    </FormRow>
                    )
                ))}              
            </FormRow>
            ))}         

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />
            <ButtonAction src={buttonReset} alt="mini" disabled={stateButton} onClick={handleReset} />
          </Row>
          
        </Panel>
      </form>
      <Modal ref={modalRef} className={isView ? 'open' : ''}>
      <div className="modal-view">
        <div className="view">
          <div className="view-close" onClick={CloseModal}>
            FECHAR [X]
          </div>
          <div className="view-container">
            <Table>
            <Thead>
              <tr className="">
                <th scope="col">#</th>
                <th scope="col">Código</th>
                <th scope="col">Item</th>
                {scanPermission(['price_view']) && (
                <th scope="col">Preço</th>
                )}
                <th scope='col'>Un. Medida</th>
              </tr>
            </Thead>
            <Tbody>
              {checkedProduct && checkedProduct.map((item)=> (
                item.products.map((fb)=> (                  
                  <tr key={fb.id}>
                    <td data-label="codigo">
                      <span className="mob">#</span>                      
                      <InputCheckbox defaultChecked={fb.checked || false} data-id={fb.id} data-category={item.value} name={"checkbox"} type='checkbox' onClick={handleCheckBox}/>
                    </td>
                    <td data-label="codigo">
                      <span className="mob">Código</span>
                      <p>{fb.codigo}</p>
                    </td>
                    <td data-label="name">
                      <span className="mob">Item</span>
                      <p>{fb.name}</p>
                    </td>
                    {scanPermission(['price_view']) && (                  
                    <td data-label="price">
                      <span className="mob">Preço</span>
                      <p>{fb.price}</p>
                    </td>
                    )}
                    <td data-label="un. medida">{fb.unit_of_measurement}</td>
                  </tr>
                ))
              ))}     
            </Tbody>
            </Table>
          </div>
        </div>
      </div>
      </Modal>
    </Container>    
  );
}

const InputCheckbox = styled.input`
width: 1.5rem;
max-width: 1.5rem;
min-height: 1.5rem;
`
function GroupInputFunction({className, name, type, value, text, ...rest}) {  
  const { registerField } = React.useContext(FormContext);  
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }   
    
  }, [name, registerField]);
  return (
    <div className={className}>
        <input className='' name={name} type={!type ? 'text' : type} defaultValue={value && value} {...rest} placeholder=" " autoComplete="off" {...rest}/>                       
        <span className="control-group__highlight"/>
        <span className="control-group__bar"/>
        <label className="control-group__label">{text}</label>        
    </div>
  )
}

const InputCategory = styled(GroupInputFunction)`
position: relative;
display: flex;
flex-direction: column;
margin-bottom: 1.7rem;
justify-content: flex-end;
padding: 0 .5rem;
overflow: unset;

@media only screen and (max-width: 75em) {
    padding: 0 5px;
}

&.hidden {
  display: none;
}

input[type=email],
input[type=password],
input[type=date],
input[type=text] {
    background-color: unset;
    font-size: .9rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;     
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (max-width: 37.5em) {
        font-size: 1rem;
    }

    &:focus {
        outline: none;
        border: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:read-only {
        cursor: not-allowed;
        user-select: none;
    }
}

label {
    color: #999;
    font-size: 1rem;       
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 7px;
    overflow: unset;    
}

input[type=email]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=date]::-ms-input-placeholder,
input[type=text]::-ms-input-placeholder {
    color: transparent;
}

input[type=email]::placeholder,
input[type=date]::placeholder,
input[type=password]::placeholder,
input[type=text]::placeholder {
    color: transparent;
}

input[type=email]:not(:placeholder-shown) ~ label,
input[type=date]:not(:placeholder-shown) ~ label,
input[type=password]:not(:placeholder-shown) ~ label,
input[type=text]:not(:placeholder-shown) ~ label {
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;

    @media only screen and (max-width: 37.5em) {
        bottom: 1.6rem;  
        left: 0.6rem;
    }   
}

.control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;    
    left: 0;
    pointer-events: none;
    opacity: 0.15;
}

&:last-child .control-group__highlight {
    // height: calc(100% - 1.7rem);
    @media only screen and (max-width: 37.5em) {
        height: 100%;
    }
}

/* active state */
input[type=email]:focus ~ .control-group__highlight,
input[type=date]:focus ~ .control-group__highlight,
input[type=password]:focus ~ .control-group__highlight,
input[type=text]:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
}

input[type=email]:read-only ~ .control-group__highlight,
input[type=date]:read-only ~ .control-group__highlight,
input[type=password]:read-only ~ .control-group__highlight,
input[type=text]:read-only ~ .control-group__highlight {
    animation: none;
}

.control-group__bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
}

.control-group__bar:before,
.control-group__bar:after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.control-group__bar:before {
    left: 50%;
}
.control-group__bar:after {
    right: 50%;
}

/* active state */
input[type=text]:focus ~ .control-group__bar {
    border-bottom: none;
}

input[type=text]:focus ~ .control-group__bar:before,
input[type=text]:focus ~ .control-group__bar:after {
    width: 50%;
}

input[type=text]:read-only ~ .control-group__bar:before,
input[type=text]:read-only ~ .control-group__bar:after {
    background: #cecece;
    top: 1px;
    height: 0.5px;
    width: 50%;
}

width: calc(100% / 12 * ${props => props["col"]});


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: inherit;

  &.hidden {
    display: none
  }
`;

const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: 40%;
  margin-top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
`;

export const Modal = styled.div`
  ${({ theme }) => css`
    position: fixed;
    background-color: #ffffffed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;   

    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 999;
    }

    & .modal-view {
      position: relative;
      width: 1024px;
      padding: 1rem;
      z-index: 9999;

      & .view {
        position: relative;      

        & .view-close {
          padding: 5px;
          cursor: pointer;
          user-select: none;
          &:hover {
            color: orange;
          }
        }

        & .view-container {
          position: relative;
          z-index: 99999;
          height: 500px;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
          border-radius: 5px;
          overflow: auto;
          background-color: #fff;

          & .left-arrow,
          .right-arrow {
            position: absolute;
            top: 50%;
            z-index: 100;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            & svg {
              pointer-events: none;
              background-color: #ffffffb2;
              border-radius: 3px;
            }

            &:hover {
              transform: scale(1.1);
            }
          }

          & .left-arrow {
            left: 1rem;
          }

          & .right-arrow {
            right: 1rem;
          }

          & img {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            transition: all 0.2s ease-in-out;

            &.active {
              position: relative;
              visibility: visible;
              opacity: 1;
              z-index: 99;
              left: 0;
            }
          }
        }
      }
    }
  `}
`