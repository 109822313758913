import React from "react";
import styled from "styled-components";
import Utils from "../../../services/utils";

import {Table, Thead, Tbody} from '../../../components/Table';
import Moment from "../../../services/Moment";
import { IoAlarmOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";



export default function TableRender({dataSet, editAction}) {
    const {splitName} = Utils();      
    const {converteToTime} = Moment();

    // function Svg(props) {
    //     var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
    //     return <svg className={props.className} dangerouslySetInnerHTML={{__html: useTag }} />;
    // }

    function TotalExtraCalculator(totalBase){
        const DayInSeconds = 86400;
        
        if(Math.sign(totalBase) < 0) {
            if(Math.abs(totalBase) >= DayInSeconds) {
                const day = Math.floor(Math.abs(totalBase) / DayInSeconds);               
                return `-${day}D ${converteToTime(totalBase)}`; 
            }
            return converteToTime(totalBase)
        }       

        if(totalBase >= DayInSeconds) {
            const day = Math.floor(totalBase / DayInSeconds);               
            return `${day}D ${converteToTime(totalBase)}`;
        }

        return converteToTime(totalBase);
    }

    return(
        <Table>
            <Thead>
                <tr className="">
                    <th scope="col">Nome</th>
                    <th scope="col">Extra</th> 
                </tr>
            </Thead>
            <Tbody> 
                {dataSet && dataSet.map((item) => (
                    <tr key={item.user_id} data-item={item.user_id}>                        
                        <td className="green-light">
                            <span className="mob">Colaborador</span>
                            <TdFlex className="">
                                <AiOutlineUser size={30}/>
                                <p>{splitName(item.username, 2)}</p>
                            </TdFlex>
                        </td>
                        <td className="color-teal">
                            <span className="mob">Total Extra</span>
                            <TdFlex className="">
                                <IoAlarmOutline size={30} />
                                <p>{TotalExtraCalculator(item.total)}</p>
                            </TdFlex>
                        </td>  
                    </tr>
                ))}                                                                            
            </Tbody>
        </Table>
    )
}

const TdFlex = styled.div`
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;
        @media only screen and (max-width: 50em) {
            justify-content: initial;
        }
    }

    svg {        
        max-width: 1.6rem;
        max-height: 1.6rem;
        margin-right: 5px;
    }
`;