import React from "react";
import Content from '../../components/Content';
//import {useParams} from 'react-router-dom';

import {Container, Panel, Row, RowTitle, ButtonAction} from '../../components/Form';
import {FiFileText} from 'react-icons/fi';

//import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
//import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize } from "../../api/chequer";

import {Input, Select} from '../../components/FormRef/Form';
import {FormContext} from "../../components/FormRef/FormContext";
import { toast } from "react-toastify";
// import Mask from "../../services/mask";

import AuthContext from "../../contexts/auth";
import Moment from "../../services/Moment";
import { useParams } from "react-router-dom";


// import { GlobalContext } from "../../contexts/global";

export default function FeriadoStore() {  
    const {id} = useParams();
    const {todayAddDay, converteToTime} = Moment();
    const {user} = React.useContext(AuthContext);
    const {fields} = React.useContext(FormContext);    
    const [loading, setLoading] = React.useState(true);    
    const [buttonStatus, setButtonStatus] = React.useState(false); 
    const [status, setStatus] = React.useState(false);    

    async function handleSubmitForm(e) {
        e.preventDefault();
        setButtonStatus(true);
        // if(fields['imposto'].value === "" || fields['month'].value === "") return toast.warning("Selecione um imposto e o Mês.")
        let response;
        const params = [
            {name: "user_created", value: user.name.split(" ")[0]},
            {name: "name", value: fields['name'].value},
            {name: "date_at", value: fields['date_at'].value},            
            {name: "status", value: fields['status'].value}, 
        ];

        if(fields['workload'] !== undefined) params.push({name: 'workload', value: fields['workload'].value});        

        const body = await serialize(params); 
        if(id) {
            response = await ajax({url: "/feriados/" + id, method: "put", body, json: true});
        }else{
            response = await ajax({url: "/feriados", method: "post", body, json: true});
        }
        
        if(response.success && response.message) { 
            toast.success(response.message);
            setButtonStatus(false);
            if(!id) resetForm();
            return false;
        }   
        
        setButtonStatus(false);
        if(!response.success && response.message) return toast.warning(response.message);  
    }

    function handlerStatusChange(e){
        if(parseInt(e.target.value) === 1) {
            setStatus(true);
        }else{
            setStatus(false);
        }
    }

    function handleReset(e) {
        e.preventDefault();
    }

    function handlerConverteWorkload(e) {
        fields['workloadin'].value = converteToTime(e.target.value);
    }

    function resetForm(){
        fields["name"].value = ''; 
        if(fields['workload'] !== undefined) fields["workload"].value = 28800; 
        fields['date_at'].value = todayAddDay({format: "yyyy/mm/dd"});
        fields["status"].selectedIndex = 0;
        setStatus(false);       
    }
    
    const getFeriado = React.useCallback(async ()=> {
        if(id && fields['name'] !== undefined) {
            const response = await ajax({url: "/feriados/" + id});
            if(response.success) {
                const feriado = response.feriado;
                fields['date_at'].value = feriado.date_at;             
                fields['feriado_id'].value = feriado.id;             
                fields['name'].value = feriado.name;             
                fields['status'].selectedIndex = !feriado.status ? 0 : 1; 
                if(feriado.status) {
                    setStatus(true);
                    if(fields['workload'] !== undefined) fields['workload'].value = feriado.workload;  
                    if(fields['workloadin'] !== undefined) fields['workloadin'].value = converteToTime(feriado.workload);                             
                }     
            }
        }
    }, [fields, id, converteToTime]);

    React.useEffect(() => {
        async function loadPedido() {           
            setLoading(false);           
        }
        loadPedido();
        if(!loading) {
            getFeriado();
        } 
            
    }, [loading, getFeriado]);

    if(loading) return(<div></div>)

    return(
        <Content>            
            <Container> 
                <form onSubmit={handleSubmitForm}>                    
                    <Panel>       
                        <RowTitle text="Registrar feriado"><FiFileText size={25}/></RowTitle> 
                        <Row> 
                            <Input className="hidden" type="hidden" name="feriado_id"/>
                            <Input type="date" col={4} defaultValue={todayAddDay({format: "yyyy/mm/dd"})} text="Dia da feriado" name="date_at"/>                             
                            <Input col={4}  text="Descrição do feriado.." name="name" />
                            <Select label="Vai trabalhar?" col={4} name="status" onChange={handlerStatusChange}>
                                <option value={0}>Não</option>                                
                                <option value={1}>Sim</option>                                
                            </Select>    
                            {status &&
                                <>
                                    <Input col={4}  text="Horas a trabalhar em segundos..." name="workload" defaultValue={28800} onBlur={handlerConverteWorkload}/>
                                    <Input col={4}  text="Horas formatada" name="workloadin" readOnly={true}/>
                                </>                                
                            }                                              
                        </Row> 
                                            
                        <Row>                    
                            <ButtonAction type="submit" src={buttonSend} alt="button send" disabled={buttonStatus}/>
                            <ButtonAction src={buttonReset} alt="button print" onClick={handleReset}/>                       
                        </Row>
                    </Panel>
                </form>                
            </Container>
        </Content>       
    )
}
