import React from 'react';

//**Components */
import Content from "../../components/Content";
import {ButtonRow, Column, Container, Panel, Row} from '../../components/Form';
import { Input } from '../../components/FormRef/Form';

import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';

import Mask from "../../services/mask";
import { FormContext } from '../../components/FormRef/FormContext';
import Moment from '../../services/Moment';

export default function Calc() {
    const {fields} = React.useContext(FormContext); 
    const {hoursToSeconds, converteToTime} = Moment();

    const [total, setTotal] = React.useState(0);

    const handleClick = React.useCallback(() => {
        const entrada = hoursToSeconds(fields["entrada"].value);
        const interval_start = hoursToSeconds(fields["interval_start"].value);
        const interval_back = hoursToSeconds(fields["interval_back"].value);
        const output = hoursToSeconds(fields["output"].value);
        
        const amTotal = output - entrada - (interval_back - interval_start);

        const workload = 28800;
        const extraInSeconds =  amTotal - workload;        
                
        fields["workload"].value = converteToTime(amTotal);
        fields["extra"].value = converteToTime(extraInSeconds);
        
    }, [fields, hoursToSeconds, converteToTime]);

    const handleTotalClick = React.useCallback(() => { 
        let seconds = 0;
        let totalBase = 0;        
        const DayInSeconds = 86400;
        
        if(Math.sign(fields["time"].value) < 0 && Math.sign(total) >= 0) {
            seconds = Math.abs(fields["time"].value);
            totalBase = total - seconds;     
            setTotal(totalBase);

            if(totalBase >= DayInSeconds) {
                const day = Math.floor(totalBase / DayInSeconds);               
                fields["totalhoras"].value = `${day}D ${converteToTime(totalBase)}`; 
                return false;           
            }   
            
            fields["totalhoras"].value = converteToTime(totalBase);  

        }else if(Math.sign(fields["time"].value) < 0 && Math.sign(total) < 0){
            seconds = Math.abs(fields["time"].value);            
            totalBase = parseInt(`${Math.abs(total) + seconds}`, 10);
            setTotal(parseInt(`-${totalBase}`, 10));

            if(totalBase >= DayInSeconds) {
                const day = Math.floor(totalBase / DayInSeconds);               
                fields["totalhoras"].value = `-${day}D ${converteToTime(totalBase)}`; 
                return false;           
            }  

            fields["totalhoras"].value = converteToTime(parseInt(`-${totalBase}`, 10));

        }else{
            seconds = parseInt(fields["time"].value, 10);
            totalBase = total + seconds;
            setTotal(totalBase);

            if(totalBase >= DayInSeconds) {
                const day = Math.floor(totalBase / DayInSeconds);               
                fields["totalhoras"].value = `${day}D ${converteToTime(totalBase)}`; 
                return false;           
            }           
            
            fields["totalhoras"].value = converteToTime(totalBase);                    
        }              
    }, [fields, total, converteToTime]);

    React.useEffect(() => {
        if(fields["entrada"] !== undefined) {
            Mask.timeSec(fields['entrada']);            
            Mask.timeSec(fields['interval_start']);            
            Mask.timeSec(fields['interval_back']);            
            Mask.timeSec(fields['output']);     
            fields["total"].value = total;
        }
    }, [fields, total]);

    return(
        <Content>            
            <Container> 
                <Panel>
                    <Row>
                        <Input col={3} text="Seconds.." name="time" inputMode="numeric" /> 
                        <Column col={1}>
                            <Row>                                                    
                                <ButtonRow name="btnTotalCalc" src={MiniAdd} alt="mini" onClick={handleTotalClick}/>
                            </Row>
                        </Column> 
                    </Row>
                   
                    <Row>
                        <Input col={4} xs={5} text="Total em segundos.." name="total" inputMode="numeric"/>  
                        <Input col={4} xs={5} text="Total em horas.." name="totalhoras" inputMode="numeric" defaultValue="00:00:00"/>  
                    </Row>
                </Panel>

                <Panel>
                    <Row>                        
                        <Input col={3} text="Entrada.." name="entrada" inputMode="numeric" defaultValue="08:30:00"/> 
                        <Input col={3} text="A.Saida.." name="interval_start" inputMode="numeric" defaultValue="12:00:00"/> 
                        <Input col={3} text="A.Volta.." name="interval_back" inputMode="numeric" defaultValue="14:00:00"/> 
                        <Input col={2} text="Saida.." name="output" inputMode="numeric" defaultValue="18:30:00"/> 

                        <Column col={1}>
                            <Row>                                                    
                                <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleClick}/>
                            </Row>
                        </Column> 
                    </Row>
                    <Row>
                        <Input col={4} xs={5} text="Trabalhou.." name="workload" inputMode="numeric"/>  
                        <Input col={4} xs={5} text="Extra.." name="extra" inputMode="numeric" defaultValue="00:00:00"/>  
                    </Row>
                </Panel>
            </Container>
        </Content>
    )
}