import React from "react";

import {Paginate} from '../../../components/Table';

export default function PaginateRender({ count, limitCount, page, onClick}) {  
    const [loading, setloading] = React.useState(true);
    const [pageItems, setPageItems] = React.useState([]);
    const [rows, setRows] = React.useState(limitCount);
    const [min, setMin] = React.useState(1);
    const [max, setMax] = React.useState(5);

    React.useMemo(()=> {
        const array = [];

        for(let i = 1;i < count;i++) {            
            array.push(i);
        }
        setPageItems(array);                 
    }, [count]);

    React.useMemo(()=> {
        setRows(pageItems.slice(min - 1, max >= limitCount ? limitCount : max));                        
    }, [pageItems, min, max, limitCount]);

    React.useEffect(()=> {
        if(rows.length > 0) setloading(false); 

        if((page + 1) > max) {
            setMax(page + 4);
            setMin(page); 
            return false;         
        }else if(page === 1 && max > 5) {
            setMax(5);
            setMin(1); 
        }else if(page < min) {
            setMax(min);
            setMin(min - 4); 
        }       
    }, [rows, page, min, max]);

    if(loading) return(<div></div>);

    return(
        <Paginate className="paginate" onClick={onClick}>
            <div className="first active">
                <span className="firstpage">1</span>    
                <span className="prev">Prev</span>  
            </div>               
            <div className="content content_paginate">                 
                {rows.map((item)=> (
                     <span key={item} className={item === page ? 'item active' : 'item'}>{item}</span>
                ))} 
            </div>
            <div className="last active">
                <span className="next">Next</span> 
                <span className="lastpage">{limitCount}</span> 
            </div> 
        </Paginate>
    )
}