import React from 'react';
import Content from '../../components/Content';
import { useParams } from 'react-router-dom';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormInput } from '../../components/FormRef/Form';

//**Icons */
import buttonSend from '../../assets/images/buttons/button-send.svg';
import { FaProductHunt } from "react-icons/fa";
//import { FiFileText } from 'react-icons/fi'; //FiFileText
import { FormContext } from '../../components/FormRef/FormContext'
// import { GlobalContext } from '../../contexts/global';
import { ajax, serialize } from '../../api/chequer';
import { toast } from 'react-toastify';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
//const MySwal = withReactContent(Swal);

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent />
    </Content>
  );
}

function ProdileContent() {
  const { id } = useParams();
  const { fields } = React.useContext(FormContext);
  // const { roleScan } = React.useContext(GlobalContext);
  const [stateButton, setStateButton] = React.useState(false);  
  
  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);

    const params = [      
      { name: 'name', value: fields.name.value },      
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/product/category/' + id, method: 'put', body, json: true });      
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message); 
      //MySwal.fire("Você não tem permissão para atualizar usuários!");    

    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, id]);

  React.useEffect(() => {
    async function firstHandle() {
      return new Promise(async (resolve) => {
        const response = await ajax({ url: '/product/category/' + id });
        fields.name.value = response.name;
        resolve(true);
      })
    }
    (async function() {
      if (id && fields.name !== undefined) {
        await firstHandle();
      }
    })();
  }, [fields, id]);

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}>
        <RowTitle text="ATUALIZAR CATEGORIA">
          <FaProductHunt size={25} />
        </RowTitle>
        <Panel>          
          <FormInput
            required={true}
            text="Descrição.."
            col={8}
            name="name"
            //readOnly={checkOnly()}
          />
          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />             
          </Row>          
        </Panel>
      </form>
    </Container>
  );
}