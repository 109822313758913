import React from 'react';
import { useHistory } from 'react-router-dom';

export default function usePaginate() {
  // const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);

  const cbCurrentPage = React.useCallback((value) => {
    setCurrentPage(value);
  }, []);

  React.useEffect(() => {
    history.push({
      search: `?page=${currentPage}`,
      page: currentPage,
    });
  }, [currentPage, history]);

  return {
    currentPage,
    cbCurrentPage,
  };
}
