import React from 'react';
// import Peppa from '../../assets/videos/peppa.mp4';
import styled from 'styled-components';

export default function Youtube(){
    const [inputText, setInputText] = React.useState("");

    function handlePlay(e){
        e.preventDefault();        
        const VideoOverlay = document.getElementById('videoOverlay');
        VideoOverlay.classList.add("show"); 
    }

    function handleStop(e){        
        const ModalVideo = document.getElementById('modalVideo');
        ModalVideo.classList.add("show");
    }

    function handleShowButtons(e){
        const el = e.target;
        const ModalVideo = document.getElementById('modalVideo');
        el.classList.add("showButton");   
        
        setTimeout(() => {
            el.classList.remove("showButton");
            ModalVideo.classList.remove("show");
        }, 8000);
    }

    function handleChange(e){       
        const VideoOverlay = document.getElementById('videoOverlay');
        const ModalVideo = document.getElementById('modalVideo');
        const value = e.target.value;
        setInputText(value);        
        if(value === "stop" || value === "Stop"){            
            VideoOverlay.classList.remove("show");            
            VideoOverlay.classList.remove("showButton"); 
            ModalVideo.classList.remove("show");            
            setInputText("");           
        }
    }     

    return(
        <>
            <VideoContainer onClick={handlePlay}>
                {/* <iframe id="video" src={Peppa} allowtransparency="true" title='Peppa' /> */}
                <iframe id="video" src="" allowtransparency="true" title='Peppa' />
            </VideoContainer>           
           <VideoOverlay id="videoOverlay" onDoubleClick={handleShowButtons}>
                <button onClick={handleStop}>Stop</button>               
           </VideoOverlay>
           <ModalAction id="modalVideo">
               <input type="text" value={inputText} placeholder='Digite stop para parar..' onChange={handleChange}/>
           </ModalAction>
        </>
    )
}

const VideoContainer = styled.div`
position: relative;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
z-index: 50 !important;
div{
    position: absolute;
    bottom: 8%;
    left: 8%;
    display: flex;

    button {
        padding: 5px 1rem;
        margin-right: 5px;
    }
}
iframe {
    position: fixed;
    width: 90%;
    height: 90%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: auto;
}

`;

const VideoOverlay = styled.div`
position: fixed;
width: 100%;
height: 100%;
z-index: -1;
top: 0;
left: 0;
bottom: 0;
right: 0;
padding: 2rem;

button {
    display: none;
    padding: 5px 1rem;
    margin-right: 5px;
}

&.show {
    z-index: 110;
}

&.showButton button {
    display: initial;
}

`;

const ModalAction = styled.div`
position: fixed;
z-index: -1;
background-color: #cecece;
top: 0;
left: 8%;
padding: 2rem;
&.show {
    z-index: 110;
}

input {
padding: 5px;
border: none;
font-size: 1rem;
font-family: inherit;
border-radius: 5px;
}
`;