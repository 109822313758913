import React from "react";
import Content from '../../components/Content';
//import {useParams} from 'react-router-dom';

import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FiFileText } from 'react-icons/fi';

//import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
//import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize } from "../../api/chequer";

import { Input, Select } from '../../components/FormRef/Form';
import { FormContext } from "../../components/FormRef/FormContext";
import { toast } from "react-toastify";
import Mask from "../../services/mask";

import AuthContext from "../../contexts/auth";
import Moment from "../../services/Moment";


// import { GlobalContext } from "../../contexts/global";

export default function ImpostoStore() {
    const { getYear } = Moment();
    const { user } = React.useContext(AuthContext);
    const { fields } = React.useContext(FormContext);
    const [loading, setLoading] = React.useState(true);
    const [buttonStatus, setButtonStatus] = React.useState(false);

    async function handleSubmitForm(e) {
        e.preventDefault();
        setButtonStatus(true);
        if (fields['imposto'].value === "" || fields['month'].value === "") return toast.warning("Selecione um imposto e o Mês.")
        const params = [
            { name: "user_created", value: user.name.split(" ")[0] },
            { name: "empresa", value: fields['empresa'].value },
            { name: "imposto", value: fields['imposto'].value },
            { name: "year", value: fields['year'].value },
            { name: "month", value: fields['month'].value },
            { name: "price", value: fields['price'].value.replace(".", "").replace(",", ".") },
            { name: "status", value: fields['status'].value },
        ];

        const body = await serialize(params);
        const response = await ajax({ url: "/impostos", method: "post", body, json: true });
        if (response.success && response.message) {
            toast.success(response.message);
            setButtonStatus(false);
            resetForm();
            return false;
        }

        setButtonStatus(false);
        if (!response.success && response.message) return toast.warning(response.message);
    }

    function handleReset(e) {
        e.preventDefault();
    }

    function resetForm() {
        fields["price"].value = '';
        fields["imposto"].selectedIndex = "0";
        fields["month"].selectedIndex = "0";
    }


    React.useEffect(() => {
        async function loadPedido() {
            setLoading(false);

        }
        loadPedido();
        // if(id && fields.nome !== undefined) {loadPedido()}else{setLoading(false)};  
        if (fields['price'] !== undefined) {
            Mask.money(fields['price']);
            fields['year'].value = getYear();
        };

    }, [loading, fields, getYear]);

    if (loading) return (<div></div>)

    return (
        <Content>
            <Container>
                <form onSubmit={handleSubmitForm}>
                    <Panel>
                        <RowTitle text="Registrar impostos"><FiFileText size={25} /></RowTitle>
                        <Row>
                            <Select label="Empresa.." col={4} md={6} name="empresa">
                                <option value="chequer megastore">CHEQUER MEGASTORE</option>
                                <option value="welo imoveis">WELO IMÓVEIS</option>
                                <option value="steakbeer">STEAKBEER</option>
                            </Select>
                            <Select label="Tipo do imposto.." col={3} md={4} name="imposto">
                                <option value="" style={{ display: "none" }}>Selecione...</option>
                                <option value="confins">CONFINS</option>
                                <option value="csll">CSLL</option>
                                <option value="fgts">FGTS</option>
                                <option value="gps">GPS</option>
                                <option value="irpj">IRPJ</option>
                                <option value="pis">PIS</option>
                                <option value="ipva">IPVA</option>
                                <option value="icms">ICMS</option>
                                <option value="gps13">GPS 13</option>
                            </Select>
                            <Select label="Mês.." col={3} md={3} name="month">
                                <option value="" style={{ display: "none" }}>Selecione...</option>
                                <option value="janeiro">Janeiro</option>
                                <option value="fevereiro">Fevereiro</option>
                                <option value="março">Março</option>
                                <option value="abril">Abril</option>
                                <option value="maio">Maio</option>
                                <option value="junho">Junho</option>
                                <option value="julho">Julho</option>
                                <option value="agosto">Agosto</option>
                                <option value="setembro">Setembro</option>
                                <option value="outubro">Outubro</option>
                                <option value="novembro">Novembro</option>
                                <option value="dezembro">Dezembro</option>
                            </Select>
                            <Input col={2} md={2} text="Ano.." name="year" inputMode="numeric" defaultValue="2023" />
                            <Input col={3} text="Valor.." name="price" inputMode="numeric" />
                            <Select label="Status.." col={2} name="status">
                                <option value="pago">Pago</option>
                                <option value="pendente">Pendente</option>
                            </Select>
                        </Row>

                        <Row>
                            <ButtonAction type="submit" src={buttonSend} alt="button send" disabled={buttonStatus} />
                            <ButtonAction src={buttonReset} alt="button print" onClick={handleReset} />
                        </Row>
                    </Panel>
                </form>
            </Container>
        </Content>
    )
}


