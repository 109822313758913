import React from 'react';
// import { Link } from 'react-router-dom';

//**Components */
import Content from "../../components/Content";
import { Container } from '../../components/Form';
import { Flex, Input, ButtonRow, Select } from '../../components/FormRef/Form'; //FilterLabel || Select
import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

import styled from 'styled-components';
import { FormContext } from '../../components/FormRef/FormContext';
import { ajax, serialize } from '../../api/chequer';
import AuthContext from '../../contexts/auth';
import Moment from '../../services/Moment';

export default function Dashboard() {
  const { user } = React.useContext(AuthContext)
  const { fields } = React.useContext(FormContext);
  const [total, setTotal] = React.useState(0);
  const [company, setCompany] = React.useState("chequer");
  const { todayAddDay } = Moment();

  // const startEndDate = React.useCallback(() => {
  //   setTotal(0);

  //   const inicio = new Date(fields['year'].value, parseInt(fields['month'].value) - 1, 1);
  //   const final = new Date(new Date().getFullYear(), parseInt(fields['month'].value), 0);

  //   const datainicial = `${inicio.getFullYear()}-${(inicio.getMonth() + 1).toString().padStart(2, "0")}-${inicio.getDate().toString().padStart(2, "0")}`;
  //   const datafinal = `${final.getFullYear()}-${(final.getMonth() + 1).toString().padStart(2, "0")}-${final.getDate().toString().padStart(2, "0")}`;
  //   const dias = final.getDate().toString().padStart(2, "0");
  //   return { datainicial, datafinal, dias };
  // }, [fields]);

  function FloatToReal(val, cifrao = null) {
    if (cifrao) {
      const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val).replace("R$", "");
      return value;
    } else {
      const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
      return value;
    }
  }

  const getVenda = React.useCallback(async () => {
    const params = [
      { name:'company', value: company},
      { name: 'estab', value: fields['estab'].value },
      { name: 'dataI', value: fields['dataI'].value },
      { name: 'dataF', value: fields['dataF'].value },
      { name: 'user', value: user.name.split(' ')[0] }
    ]

    const body = await serialize(params)
    const response = await ajax({ url: `/services/contas-receber`, method: 'post', body, json: true });
    if (response.data === undefined) return false;
    setTotal(FloatToReal(response.data.saldo[0].saldo))

  }, [user, company, fields]);

  const handleChangeCompany = React.useCallback(() => {  
    setCompany(fields['company'].value);               
  }, [fields])

  function handleSearch(e) {
    e.preventDefault()
    setTotal(0);
    getVenda();
  }

  //React.useEffect(() => {
    // async function startLoadEvent() {
    //   //fields['year'].value
    //   // fields['month'].selectedIndex = new Date().getMonth() + 1;
    //   // const { datainicial, datafinal, dias } = startEndDate();
    //   // getVenda(datainicial, datafinal);
    // }
    // if (fields['estab'] !== undefined) startLoadEvent();
  //}, [fields, startEndDate, getVenda])

  return (
    <Content>
      <Container>
        <Flex className="shadow">
          <Select label="Empresa.." col={2} md={3} xs={12} name="company" onChange={handleChangeCompany}>
              <option value={company} style={{ display: "none" }}>{company.toUpperCase()}</option>
              <option value="chequer">CHEQUER</option>
              <option value="steakbeer">STEAKBEER</option>                        
          </Select>
          <Input col={2} md={3} xs={12} text="Estabelecimento.." name="estab" inputMode="numeric" defaultValue={1} />
          <Input
            type="date"
            col={3}
            defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
            text="Data inicial"
            name="dataI"
          />
          <Input
            type="date"
            col={3}
            defaultValue={todayAddDay({ format: 'yyyy/mm/dd' })}
            text="Data final"
            name="dataF"
          />

          <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleSearch} />

        </Flex>
        <FlexTitle>
          {total !== 0 && (
            <>
              <FlexColumn className='_animated-easy-out--bottom' col={12}>
                <h3>Contas a receber</h3>
              </FlexColumn>
              <FlexColumn className='_animated-easy-out--left' col={12}>
                <span><b>SALDO TOTAL: </b>{total}</span>
              </FlexColumn>
            </>
          )}
        </FlexTitle>

      </Container>
    </Content>
  )
}

const FlexTitle = styled.div`
    display: flex;   
    flex-direction: column;   
    margin-top: 1rem;
    padding: 1rem;
    h3 {
        color: #7376a2;
        font-family: unset;
        text-transform: uppercase;
    }   

    span {        
        font-weight: bold;
        color: #7376a2;; //rgba(255,69,96,0.85) rgba(0,227,150,0.85)
        text-decoration: underline;
        position: relative;
        margin-left: 1.5rem;
        b {
            font-weight: 500;
            color: #7376a2;
        }

        &::before{
            content: '';
            position: absolute;
            width: .9rem;
            height: .9rem;
            background-color: #26e7a5;
            top: 50%;
            transform: translateY(-50%);
            left: -1.5rem;
            border-radius: 1rem;
        }

        &.sale::before {
            background-color: #269ffb;
        }
    }
`;

const FlexColumn = styled.div`
    width: calc(100% / 12 * ${props => props["col"]});    
    user-select: none;
    opacity: 1;
    scrollbar-color: rgba(0,143,251,0.85) #9f9f9f;
    scrollbar-width: thin;
    pointer-events: initial;
    transition: all 0.2s; 
    margin: 2px 0;   

    &.overflow {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &.active { 
        opacity: 0.4;
        cursor: grabbing !important;
        cursor: -webkit-grabbing !important;    
        /* pointer-events: none;         */
    }   

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #777;
    }   

    @media only screen and (min-width: 82em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }   

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
    }  
`;
