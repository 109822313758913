import React from "react";
import styled from "styled-components";
import Logo from '../../assets/images/logo-chequer.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { FiSend } from "react-icons/fi";
import {ajax, serialize} from "../../api/chequer";
import { toast } from "react-toastify";
import Moment from '../../services/Moment';

const MySwal = withReactContent(Swal);

export default function PontoDesk() {      
    const [fields, setFields] = React.useState({});
    const [buttonText, setButtonText] = React.useState("Próximo");    
    const [timerecords, setTimeRecords] = React.useState(null);    
    const { isnow, dayOfWeek, time, todayBR } = Moment();

    const registerField = React.useCallback((name, ref) => { 
        setFields((oldInputs) => {return  {...oldInputs, [name]: ref}});        
    }, []);

    const WaitText = React.useCallback((text) => {
        let ponto = ".";       
        setButtonText(text);  
        return setInterval(() => {
            setButtonText(`${text}${ponto}`);            
            ponto += ".";
            if(ponto === ".....") ponto = ""; 
        }, 1000);
    },[]);  

    const getUser = React.useCallback(async (code) => {
        //const esnow = "2023-11-07T03:00:00.000Z";
        const esnow = isnow(); // const esnow = "2023-06-24T03:00:00.000Z";        
        const estime = time();
        const today = todayBR();
        
        const users = [            
            {id: 5, user_code: '7030', name: 'Rosangela Gonçalves'},
            {id: 7, user_code: '7050', name: 'Samara Barbosa'},
            {id: 55, user_code: '1019', name: 'Julia Carolina'},
            {id: 16, user_code: '7130', name: 'Michelle Mendes'},
            {id: 12, user_code: '7090', name: 'Brunna Andrade'},
            {id: 19, user_code: '7140', name: 'Xenia Bastos'},            
            {id: 21, user_code: '7160', name: 'Paulo Roberto'}, 
            {id: 24, user_code: '7190', name: 'Celio Bento'},
            {id: 25, user_code: '7200', name: 'Rafael Barbosa'},                   
            {id: 57, user_code: '1022', name: 'Jaqueline Machado'}
        ]
        const userFilter = users.filter((user) => user.user_code === code);
        
        const week = await dayOfWeek(esnow);
        
        // const params = [           
        //     {id: 57, user_code: '1022', name: 'Jaqueline Machado', entry: '08:30:00', interval_start: '11:31:21', interval_back: '13:30:20', output: '19:04:43'}            
        // ] 

        // localStorage.setItem("@ponto#" + esnow, JSON.stringify(params));

        const localSaved = JSON.parse(localStorage.getItem("@ponto#" + esnow));       
        
        let message = '';
        
        if(userFilter.length === 1) {
            const user = {id: userFilter[0].id,name: userFilter[0].name, user_code: userFilter[0].user_code};                      

            if(localSaved === null) {
                const message = `ENTRADA: ${today} ÀS ${estime}`;
                return {success: true, user, time: estime, day: esnow, message, weeking: week}
            }

            const userPonto = localSaved.filter((item) => item.user_code === code);
           

            if(userPonto.length === 0) {
                const message = `ENTRADA: ${today} ÀS ${estime}`;
                return {success: true, user, time: estime, day: esnow, message, weeking: week}
            }

            if (userPonto[0].output !== "00:00:00") return {error: true, message: "Jornada já foi finalizada!"};
            
            if(week === "Sábado") {
                return {success: true, user, type: 'output',time: estime, day: esnow, message: `FINALIZAR JORNADA: ${today} ÀS ${estime},`, weeking: week}
            }else if(week !== "Domigno" || week !== "Sábado"){
               if(userPonto[0].interval_start === '00:00:00' && userPonto[0].entry !== '00:00:00') return {success: true, user, type: 'interval_start',time: estime, day: esnow, message: `ALMOÇO SAIDA: ${today} ÀS ${estime}`, weeking: week}
               if(userPonto[0].interval_back === '00:00:00' && userPonto[0].interval_start !== '00:00:00') return {success: true, user, type: 'interval_back',time: estime, day: esnow, message: `ALMOÇO VOLTA: ${today} ÀS ${estime}`, weeking: week}
               if(userPonto[0].output === '00:00:00' && userPonto[0].interval_back !== '00:00:00') return {success: true, user, type: 'output',time: estime, day: esnow, message: `FINALIZAR JORNADA: ${today} ÀS ${estime}`, weeking: week}
            }

            

            return {success: true, user, time: estime, day: esnow, message, weeking: week}

            //return {success: true, user, message, time, day}
            // return {success: true, user: user[0].name, user_code: user[0].user_code, message: "Bom dia!"}
        }       

        return {error: true, message: "Usuario inválido!"};
    }, [dayOfWeek, isnow, time, todayBR]);    

    const handleleNext = React.useCallback(async ()=> {        
        if(fields['usuario'].value.trim() === "") {
            toast.error("Digite seu usuário..");
            return false;
        }
        const interval = WaitText("Processando.");         
        const res = await getUser(fields['usuario'].value);    
        
        if(res.error && res.message) {
            toast.warning(res.message);            
            clearInterval(interval);
            setButtonText("Próximo");
            return false;
        }
       
        if(res.success && res.warning) {
            toast.warning(res.warning);
            fields['usuario'].value = "";
            clearInterval(interval);
            setButtonText("Próximo");
            return false;
        }

        if(res.success && res.user) {          
            
            const name = res.user.name.split(" ");
           
            // const name = res.user;

            setTimeout(async () => {
                const newPonto = [];
                // const today = todayBR();
                const isNow = isnow();
                // const isNow = "2023-06-24T03:00:00.000Z"
                MySwal.fire({
                    title: name[0] + " " + name[1],
                    // title: name,
                    text: "Registrar Entrada 08:26:25",
                    html: `
                        <h4>${res.message}</h4>
                        <input id="swal-input1" class="swal2-input" placeholder="Senha..." type="password">
                        `,    
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Salvar!',
                    preConfirm: async () => {
                        
                        if(document.getElementById('swal-input1').value === "") {
                            toast.error("Digite sua senha..");
                            return false;
                        }
                        
                       
                        const localPonto = JSON.parse(localStorage.getItem("@ponto#" + isNow));                         
                        if(localPonto === null) {
                            if(res.weeking === "Sábado") {
                                newPonto.push({id: res.user.id, user_code: res.user.user_code, name: res.user.name, entry: res.time, interval_start: 'SÁBADO', interval_back: 'SÁBADO', output: '00:00:00'})
                            }else{
                                newPonto.push({id: res.user.id, user_code: res.user.user_code, name: res.user.name, entry: res.time, interval_start: '00:00:00', interval_back: '00:00:00', output: '00:00:00'})
                            }
                                                    
                        }else{
                            const localUser = localPonto.filter((item) => item.user_code === res.user.user_code);                            
                            localPonto.forEach((item) => {
                                if(item.user_code === res.user.user_code) {
                                    item[res.type] = res.time
                                }
                                newPonto.push(item);                             
                            });

                            if(localUser.length === 0) {
                                if(res.weeking === "Sábado") {
                                    newPonto.push({id: res.user.id, user_code: res.user.user_code, name: res.user.name, entry: res.time, interval_start: 'SÁBADO', interval_back: 'SÁBADO', output: '00:00:00'});
                                }else{
                                    newPonto.push({id: res.user.id, user_code: res.user.user_code, name: res.user.name, entry: res.time, interval_start: '00:00:00', interval_back: '00:00:00', output: '00:00:00'});
                                }                                    
                            }
                        }
                        

                        const saved = {success: "success"}

                        if(saved.error && saved.message) {
                            toast.error(saved.message);
                            return false;
                        }                        

                        if(!saved.success) {                            
                            toast.error("Ocorreu algum erro contact o administrador..");
                            return false;
                        }                       
                    }
                  }).then(async (result) => {
                      
                    if (result.isConfirmed) {                       
                        localStorage.setItem("@ponto#" + isNow, JSON.stringify(newPonto));
                        const result = JSON.parse(localStorage.getItem("@ponto#" + isNow)); 
                        setTimeRecords(result)

                        //const result = await ajax({url: "/ponto/day" });
                        
                        // if(result.success && result.timerecord.rows.length > 0) {
                        //     setTimeRecords(result.timerecord.rows);
                        // }                        

                        MySwal.fire(
                        'Salvo..',
                        'Ponto registrado com sucesso!',
                        'success'
                        );

                        fields['usuario'].value = "";
                        window.location.reload();

                    }else if(result.isDismissed)  {
                        fields['usuario'].value = "";
                    }                 
                  });
    
                // fields['usuario'].value = "";
                clearInterval(interval);
                setButtonText("Próximo");  
                
            }, 1000);              
            
        }        
    },[fields, WaitText, getUser, isnow]);

    const sendStorageToServer = React.useCallback(async (data, day, key) => {
        console.log("SENDDING...");
        const params = [            
            {name: "users", value: data},  
            {name: "day", value: day}                  
        ];       
        const body = await serialize(params);
        const saved = await ajax({url: "/timerecords/massstore", method: "post", body, json: true});        
        if(saved.success) {
            localStorage.removeItem(key);
        }       
    }, [])

    const checkStorage = React.useCallback(async () => {        
        const day = isnow();  // const today = todayBR();

        // setTimeout(() => {
        //     const param = [
        //     {
        //         "id": 19,
        //         "user_code": "7140",
        //         "name": "Xenia Bastos",
        //         "entry": "09:01:50",
        //         "interval_start": "15:17:23",
        //         "interval_back": "17:20:06",
        //         "output": "18:34:44"
        //     },
        //     {
        //         "id": 8,
        //         "user_code": "7060",
        //         "name": "Deborah Freitas",
        //         "entry": "09:02:09",
        //         "interval_start": "14:00:37",
        //         "interval_back": "16:00:04",
        //         "output": "18:29:40"
        //     },
        //     {
        //         "id": 50,
        //         "user_code": "9999",
        //         "name": "Roberta Nunes",
        //         "entry": "09:02:29",
        //         "interval_start": "11:01:39",
        //         "interval_back": "13:05:39",
        //         "output": "18:31:58"
        //     },
        //     {
        //         "id": 12,
        //         "user_code": "7090",
        //         "name": "Brunna Andrade",
        //         "entry": "09:02:46",
        //         "interval_start": "13:03:50",
        //         "interval_back": "15:01:50",
        //         "output": "18:34:28"
        //     },
        //     {
        //         "id": 51,
        //         "user_code": "7092",
        //         "name": "Suellen Shana",
        //         "entry": "09:03:03",
        //         "interval_start": "11:08:27",
        //         "interval_back": "11:08:27",
        //         "output": "11:08:27"
        //     }
        // ]

        // localStorage.setItem("@ponto#2023-06-22T03:00:00.000Z", JSON.stringify(param));
        // }, 5000);

        Object.keys(localStorage).forEach((key) => {               
            if(key.includes("@ponto") && key !== "@ponto#" + day) {
                const stringDay = key.split('#')[1];                              
                sendStorageToServer(JSON.parse(localStorage.getItem(key)), stringDay, key);                
            }                      
        })
    }, [sendStorageToServer, isnow]);
    
    React.useEffect(() => {
        async function load() {  
            //getUser()
            checkStorage();
            const today = isnow();
            const result = JSON.parse(localStorage.getItem("@ponto#" + today)); 
            setTimeRecords(result)

            // const result = await ajax({url: "/ponto/day" });
            // if(result.error) return toast.error("Servidor offline");
            // if(result.success && result.timerecord.rows.length > 0) {
            //     setTimeRecords(result.timerecord.rows);
            // }     
        }

        load();
    }, [isnow, checkStorage]);

    return(
        <Container>
            <Content>
                
                <Row>
                    <Column col={12}>
                        <LogoContainer>
                            <img src={Logo} alt="Logo chequer"/>
                        </LogoContainer>                        
                    </Column>
                    <Column col={12}>
                        <ContainerLabel>
                            <Label>                                
                                <Input type="text" name="usuario" inputMode="numeric" placeholder="Usuário.." registerField={registerField}/>
                                <button onClick={handleleNext}>{buttonText}</button>                                
                            </Label>
                        </ContainerLabel>
                    </Column>                    
                </Row>                  
            </Content>  
            <TimeSheetList>               
                <ul className="header">
                    <li>Usuário</li>
                    <li>Entrada</li>
                    <li>A/S</li>
                    <li>A/V</li>
                    <li>Saida</li>
                </ul>                
                {timerecords && timerecords.map((item) => (                       
                        <ul key={item.user_code}>                            
                            <li>{item.name.split(" ")[0]}</li>
                            <li>{item.entry}</li>
                            <li>{item.interval_start ? item.interval_start : "''"}</li>
                            <li>{item.interval_back ? item.interval_back : "''"}</li>
                            <li>{item.output ? item.output : "''"}</li>
                        </ul>
                    ))                    
                }                              
            </TimeSheetList>          
        </Container>
    )
}

const TimeSheetList = styled.div`
    position: fixed;
    right: 2rem;
    top: 2rem;
    width: 23rem;
    max-height: 15rem;
    background-color: #fff;
    border-radius: 3px;
    overflow: auto;
    scrollbar-color: #777 #9f9f9f;
    scrollbar-width: thin;   
    
    @media only screen and (max-width: 50rem) {
        width: 98%;        
        right: inherit;
        top: 2%;
    }

    @media only screen and (max-width: 37rem) {
        width: 98%;
        bottom: 2rem;
        right: inherit;
        top: inherit;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #777;
    }    

    ul {
        display: flex;
        overflow: hidden;
        padding: 5px 0;

        &.header {
            background-color: #ffcb00;
            font-weight: bold;
            color: #555;            
        }

        li {
            width: 2.5rem;
            margin: 0 1rem;
            font-size: 0.7rem;

            @media only screen and (max-width: 50rem) {
                width: 100%;
            }

            @media only screen and (max-width: 37rem) {
                width: 100%;
            }
        }
    }
`;

const Input = styled(({className, name, text, type, value, registerField, ...rest}) => {
    
    const inputRef = React.useRef();

    React.useEffect(()=> {
        if(inputRef.current && name) {           
            registerField(name, inputRef.current);
        }
    }, [name, registerField]);

    return(
        <input ref={inputRef} className={className} name={name} type={!type ? "text" : type} value={value && value} {...rest} />
    )
})`
    border: none;
    outline: none;
    font-size: 2rem;
    padding: 7px;        
    font-weight: bold;
    color: #666;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 4px;

    &:focus {
        outline: 1px solid #5ec62f;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,91,255,1) 100%);
`;

const Content = styled.div`
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    /* height: 50%; */
    border-radius: 5px;

    @media only screen and (max-width: 37rem) {
        position: absolute;
        top: 4rem;
        width: 98%;
    }
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Column = styled.div`
    width: calc(100% / 12 * ${props => props["col"]});
    @media only screen and (min-width: 75em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }   

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
    }
`;

const LogoContainer = styled.figure`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    img {
        width: 300px;

        @media only screen and (max-width: 37rem) {
            width: 200px;
        }
    }
`;

const ContainerLabel = styled.div`
    width: 100%;
    display: flex;    
    flex-wrap: wrap;  
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 37rem) {
        display: block;
    }
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    
    @media only screen and (max-width: 37rem) {
        flex-wrap: initial;
    }

    span {
        color: #fff;
        font-size: 1.6rem
    } 

    button {
        border: none;
        outline: none;
        padding: 7px;  

        display: flex; 
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
        color: #666;
        border-radius: 4px;
        background-color: #ffe300;
        color: #a43131;

        &:active {
            background-color: #f9ec31;
        }

        svg {
            margin-right: 5px;
        }
    }
`;