import React from 'react';
import Content from '../../components/Content';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormInput } from '../../components/FormRef/Form';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';
//**Icons */
import { FaProductHunt } from "react-icons/fa";
// import { FiFileText } from 'react-icons/fi'; //FiFileText
import { FormContext } from '../../components/FormRef/FormContext'
// import { GlobalContext } from '../../contexts/global';
import { ajax, serialize } from '../../api/chequer';
import { toast } from 'react-toastify';

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {
  const { fields } = React.useContext(FormContext);
  // const { roleScan } = React.useContext(GlobalContext);
  const [stateButton, setStateButton] = React.useState(false); 

  const handleReset = React.useCallback((e)=> {
    e!== undefined ? e.preventDefault() : e = window.event;    
    fields.name.value = "";    
  }, [fields])

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);

    const params = [      
      { name: 'name', value: fields.name.value },      
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/product/category/store', method: 'post', body, json: true });      
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message);
      handleReset()

    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, handleReset]);  

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}>
        <RowTitle text="REGISTRAR CATEGORIA">
          <FaProductHunt size={25} />
        </RowTitle>
        <Panel>          
          <FormInput
            required={true}
            text="Descrição.."
            col={12}
            name="name"
            //readOnly={checkOnly()}
          />          

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />
            <ButtonAction src={buttonReset} alt="mini" disabled={stateButton} onClick={handleReset} />
          </Row>
          
        </Panel>
      </form>
    </Container>
  );
}