import React from 'react';

import Content from '../../components/Content';

import { Container, Column, Row } from '../../components/Form';
import { Flex, Input, FilterLabel, Select, ButtonRow } from '../../components/FormRef/Form';

import { FiFilter, FiXSquare } from 'react-icons/fi';

import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';
import useList from './useList';
import styled from 'styled-components';
import { FormContext } from '../../components/FormRef/FormContext';
import { GlobalContext } from '../../contexts/global';
import Moment from '../../services/Moment';
import Mask from '../../services/mask';
import { toast } from 'react-toastify';
import { ajax } from '../../api/chequer';

export default function PedidoList() {
  const { todayAddDay, unFormat } = Moment();
  const { lastQuery } = React.useContext(GlobalContext);
  const { fields } = React.useContext(FormContext);
  const [limit, setLimit] = React.useState(10);
  const [today, setToday] = React.useState(null);
  const [lido, setLido] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  // const {loading, TableRender, PaginateRender, setConditions, setSetQuery} = useList("/pedidos", limit, lastQuery());
  const { loading, TableRender, PaginateRender, configList, reload } = useList();

  function handleChangeLimit(e) {
    e.preventDefault();
    // setLimit(fields["limit"].value);
  }

  function handleResetFilter() {
    localStorage.removeItem('@query');
  }

  const handleSetFilter = React.useCallback(() => {
    let queryStorage = [];
    queryStorage.push({ name: '&limit', value: fields['limit'].value });
    let search = '';
    const dataPedido = unFormat({ date: fields['data_pedido'].value });
    const inputs = document.querySelectorAll('.filter-inputs input:checked');
    inputs.forEach((item) => {
      if (item.name === 'date') {
        if (item.value === 'true') {
          queryStorage.push({ name: '&day', value: dataPedido });
        }
        return;
      }
      queryStorage.push({ name: `&${item.name}`, value: item.value });
    });
    lastQuery(queryStorage);
    queryStorage.forEach((item) => {
      search += `${item.name}=${item.value}`;
    });
    configList({ url: '/pedidos', limit, search });
    // cbSetConditions(search);

    const filter = document.getElementById('filter');
    filter.classList.toggle('open');
  }, [unFormat, configList, limit, lastQuery, fields]);

  const handleSearch = React.useCallback(() => {
    handleResetFilter();

    if (fields['search'].value.trim() !== '') {
      console.log(fields['search'].value);
      configList({ url: '/pedidos', limit: 1, search: `&pedido=${fields['search'].value}` });
    } else {
      configList({ url: '/pedidos', limit });
    }
  }, [fields, configList, limit]);

  const handleFilter = React.useCallback(() => {
    const filter = document.getElementById('filter');
    filter.classList.toggle('open');
  }, []);

  React.useMemo(() => {
    const localStorageQuery = JSON.parse(localStorage.getItem('@query'));
    if (localStorageQuery) {
      localStorageQuery.forEach((item) => {
        if (item.name === '&limit') {
          setLimit(item.value);
          return;
        }

        if (item.name === '&day') setToday(item.value);
        if (item.name === '&visualized') setLido(item.value);
        if (item.name === '&tipo') setTipo(item.value);
      });
    }
  }, []);

  async function handlePedidoDestroy(e) {
    e.preventDefault();
    const response = await ajax({ url: '/pedidos/' + e.target.dataset.id + '/destroy', method: 'delete' });
    if (response.success === true) {
      reload();
      return toast.success(response.msg);
    }

    return toast.success('Ops erro ao remover o pedido...');
  }

  React.useEffect(() => {
    if (fields.data_pedido !== undefined) {
      Mask.data(fields.data_pedido);
    }
  }, [fields]);

  if (loading) return <div></div>;

  return (
    <Content>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
        <div>
          <Container className="height-auto">
            <Flex className="shadow">
              <Input col={4} xs={9} text="Pedido.." name="search" />
              <Column col={4} xs={3}>
                <Row>
                  <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleSearch} />
                  <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter} />
                </Row>
              </Column>
            </Flex>
          </Container>
        </div>
        <div style={{ flex: 1, overflow: 'auto', scrollbarWidth: 'thin', padding: '0.5rem' }}>
          <TableRender handlePedidoDestroy={handlePedidoDestroy} />
        </div>
        <PaginateRender />
        <Filter>
          <header>
            <span>
              <FiFilter size={22} /> Filtros
            </span>
            <FiXSquare size={30} onClick={handleFilter} />
          </header>
          <div style={{ marginTop: '1rem' }}>
            <Input col={12} defaultValue={todayAddDay({ date: today, days: 0, format: 'dd/mm/yyyy' })} text="Data pedidos" name="data_pedido" />
            <Select label="Itens por pagina.." col={12} name="limit" onChange={handleChangeLimit}>
              <option value={limit} style={{ display: 'none' }}>
                {limit}
              </option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={80}>50</option>
            </Select>
          </div>
          <main className="filter-inputs">
            <GroupList id="row-filter-data">
              <h2>Filtrar por data..</h2>
              <FilterLabel type="radio" name="date" value={true} text="Sim" defaultChecked={today && true} />
              <FilterLabel type="radio" name="date" value={false} text="Não" defaultChecked={!today && true} />
            </GroupList>
            <GroupList>
              <h2>Visualizado..</h2>
              <FilterLabel type="radio" name="visualized" value={true} text="Sim" defaultChecked={lido === 'true' && true} />
              <FilterLabel type="radio" name="visualized" value={false} text="Não" defaultChecked={lido === 'false' && true} />
            </GroupList>
            <label>
              <input type="radio" name="tipo" value={`entrega%20transporte`} defaultChecked={tipo === 'entrega%20transporte' && true} />
              Filtrar Entrega Transporte..
            </label>
            <label>
              <input type="radio" name="tipo" value={`entrega%20manual`} defaultChecked={tipo === 'entrega%20manual' && true} />
              Filtrar Entrega Manual..
            </label>
            <label>
              <input type="radio" name="tipo" value={`assistência`} defaultChecked={tipo === 'assistência' && true} />
              Filtrar Assistência..
            </label>
            {/* <label className="column">Buscar por..<input id="filter-search" type="text" placeholder="Digite..." name="search"/></label> */}
            <button onClick={handleSetFilter}>
              <FiFilter size={22} />
              <span>Aplicar filtro</span>
            </button>
            <button onClick={handleResetFilter}>
              <FiFilter size={22} />
              <span>Resetar filtro</span>
            </button>
          </main>
        </Filter>
      </div>
    </Content>
  );
}

const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    color: #7483be;
  }
  label {
    width: auto !important;
  }
`;

const Filter = styled(({ children, ...props }) => (
  <div id="filter" className={props.className} onClick={props.onClick}>
    {children}
  </div>
))`
  position: absolute;
  top: 8px;
  bottom: 8px;
  width: 22rem;
  background-color: #fff;
  box-shadow: -1px 2px 10px rgba(0, 0, 100, 0.3);
  border-radius: 8px;
  right: -30rem;
  transition: right 0.4s ease-in-out;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #7f808a;
    color: #fff;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.2rem;
    user-select: none;
    & span {
      display: flex;
      align-items: center;
    }
    & svg {
      margin-right: 1rem;
    }
  }

  main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    overflow: auto;

    & label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 5px 1rem;
      cursor: pointer;
      font-size: 0.9rem;
      user-select: none;

      &.column {
        flex-direction: column;
        align-items: flex-start;
      }

      & input[type='text'] {
        width: 100%;
        height: 2.5rem;
        border-radius: 4px;
        border: 1px solid #cecece;
        padding: 0 1rem;
        font-size: 1.1rem;
        font-family: inherit;
      }

      & input[type='radio'] {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      & span {
        margin-left: 5px;
      }
    }

    & button {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      color: #959595;

      & span {
        flex: 1;
      }
    }
  }

  &.open {
    right: 8px;
  }
`;
