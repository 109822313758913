/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
// import '../stylesheets/pages/navbar.css';

import AuthContext from '../contexts/auth';
import { GlobalContext } from '../contexts/global';
import handleToggle from '../services/toggle';

//**Icons */
import { FaProductHunt } from "react-icons/fa";
import { FiUsers, FiPower, FiSettings} from 'react-icons/fi'; //FiClipboard, FiFileText
import { FaClipboardList } from "react-icons/fa";
//import { BsCurrencyExchange, BsCalendar2Range } from 'react-icons/bs';';

import Avatar from '../assets/images/profilePic.jpeg';
import styled from 'styled-components';

export default function Nav() {
  const { roleScan } = React.useContext(GlobalContext);
  const { logout, user } = React.useContext(AuthContext);
  const [activeNavigate, setActiveNavigate] = React.useState('');
  const [activeNavigateLink, setActiveNavigateLink] = React.useState('');

  function Svg(props) {
    var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
    return <svg className={props.className} dangerouslySetInnerHTML={{ __html: useTag }} />;
  }

  function handleClick(e) {
    const el = e.target;
    const dataName = el.dataset.name;
    if (el.matches('.item')) {
      if (el.classList.contains('open')) {
        el.classList.remove('open');
        return false;
      }
      const menuItem = document.querySelectorAll('.item');
      [...menuItem].forEach((item) => item.classList.remove('open'));
      setTimeout(() => {
        localStorage.setItem('@navigate', dataName);
        el.classList.add('open');
      }, 200);
    }
  }

  function setNavigate(text) {
    return activeNavigate === text ? 'item open' : 'item';
  }

  function setNavigateLink(text) {
    return activeNavigateLink === text ? 'link active' : 'link';
  }

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan])

  React.useEffect(() => {    
    function loadNavigation() {
      const navigate = localStorage.getItem('@navigate');
      const navigateLink = localStorage.getItem('@navigateLink');
      setActiveNavigate(navigate);
      setActiveNavigateLink(navigateLink);
    }
    loadNavigation();
  }, []);

  // const memoUsers = React.useMemo(() => roleScan(['cr_users', 'up_users']), [roleScan]);

  return (
    <Navigate>
      <Dashboard svg={Svg} link={Link} />
        <Figure src={!user.avatar ? Avatar : user.avatar} title={user.name.split(' ')[0]} subTitle={user.user_function} />
      <Divider />   

      {/* USUARIOS */}
      {(scanPermission(['in_users', 'up_users'])) && (
        <NavigateItem arrow={true} className={setNavigate('usuarios')} title="Usuários" svg={FiUsers} dataName="usuarios" onClick={handleClick}>
          <NavigationSub>
          {scanPermission(['in_users']) && (
              <SubItem
                svg={Svg}
                dataLink="@userstore"
                onClick={handleToggle}
                linkClass={setNavigateLink('@userstore')}
                href="/users/register"
                linkText="Inserir Usuário"
              />
            )}
            {scanPermission(['up_users']) && (
              <SubItem
                svg={Svg}
                dataLink="@userlist"
                onClick={handleToggle}
                linkClass={setNavigateLink('@userlist')}
                href="/users"
                linkText="Relação de usuários"
              />
            )}            
          </NavigationSub>
        </NavigateItem>
      )}   

      {/* PRODUTOS */}
      {scanPermission(['in_products', 'up_products']) && (
        <NavigateItem arrow={true} className={setNavigate('products')} title="Produtos" svg={FaProductHunt} dataName="products" onClick={handleClick}>
        <NavigationSub>            
            {scanPermission(['in_products']) && (
              <>
                <SubItem
                  svg={Svg}
                  dataLink="@productStore"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@productStore')}
                  href="/product/registrar"
                  linkText="Inserir Produto"
                />
                {scanPermission(['up_products']) && (
                  <SubItem
                    svg={Svg}
                    dataLink="@productList"
                    onClick={handleToggle}
                    linkClass={setNavigateLink('@productList')}
                    href="/product/"
                    linkText="Relação de Produtos"
                  />   
                )}  
                <SubItem
                  svg={Svg}
                  dataLink="@productCategoryStore"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@productCategoryStore')}
                  href="/product/category/registrar"
                  linkText="Inserir Categoria"
                />
                <SubItem
                  svg={Svg}
                  dataLink="@productCategoryList"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@productCategoryList')}
                  href="/product/category/"
                  linkText="Relação de Categoria"
                />
              </>
            )}
                                        
        </NavigationSub>
      </NavigateItem> 
      )}

      {/* PROJETOS */}
      {(scanPermission(['in_project', 'ver_projetos_inseridos'])) && (
        <NavigateItem arrow={true} className={setNavigate('projects')} title="Projetos" svg={FaClipboardList} dataName="projects" onClick={handleClick}>
        <NavigationSub>            
            {scanPermission(['in_project']) && (
              <SubItem
              svg={Svg}
              dataLink="@projectStore"
              onClick={handleToggle}
              linkClass={setNavigateLink('@projectStore')}
              href="/project/registrar"
              linkText="Inserir Projeto"
            />
            )}
            {scanPermission(['ver_projetos_inseridos']) && (
              <SubItem
              svg={Svg}
              dataLink="@productList"
              onClick={handleToggle}
              linkClass={setNavigateLink('@projectList')}
              href="/project"
              linkText="Relação de Projetos"
            />   
            )}                              
        </NavigationSub>
      </NavigateItem> 
      )}
           
      
      <NavigateItem arrow={true} className={setNavigate('configurações')} title="Configurações" svg={FiSettings} dataName="configurações" onClick={handleClick}>
        <NavigationSub>
          <SubItem svg={Svg} dataLink="profile" onClick={handleToggle} linkClass={setNavigateLink('profile')} href="/profile" linkText="Profile" />          
        </NavigationSub>
      </NavigateItem>

      <NavigateItem className={setNavigate('configurações')} title="Sair" svg={FiPower} onClick={logout} />
    </Navigate>
  );
}

const Dashboard = styled(({ svg: Svg, link: Link, ...props }) => (
  <li className={props.className} onClick={props.onClick}>
    <Svg icon="icon-home-dash" />
    <Link to="/">Dashboard</Link>
  </li>
))`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  padding: 8px;
  cursor: pointer;
  background-color: #f1f5f8;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  color: #1e1e1e;

  svg {
    grid-column: 1;
    max-width: 1.6rem;
    max-height: 1.6rem;
    width: 100%;
    fill: #fff;
    pointer-events: none;
    fill: currentColor;
  }

  a,
  a:link,
  a:visited {
    grid-column: 2;
    text-decoration: none;
    font-size: 1.1rem;
    color: currentColor;
    font-weight: 500;
    transform: translateY(2px);

    h6 {
      color: #f1f5f8;
    }

    &:focus {
      outline: none;
    }
  }
`;

//**Divider
const Divider = styled(({ ...props }) => (
  <li className={props.className}>
    <span style={{ margin: 0 }}></span>
  </li>
))`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  // padding: 1rem 2rem 1rem 1rem;
  padding: 8px;
  cursor: pointer;
  span {
    grid-column: 1 / -1;
    height: 2px;
    background-color: #577edf;
    margin-top: 1rem;
  }
`;
//**Avatar item*/
const Figure = styled(({ ...props }) => (
  <li className={props.className}>
    <figure>
      <div>
        <img src={props.src} alt={props.title} />
      </div>
      <div>
        <h3>{props.title}</h3>
        <span>{props.subTitle}</span>
      </div>
    </figure>
  </li>
))`
  figure {
    position: relative;
    padding: 0;
    margin: 0;
    color: #fff;
    height: 5rem;
    display: flex;
    align-items: center;

    img {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      // -webkit-filter: drop-shadow(2px 3px 6px #1d2aec);
      // filter: drop-shadow(2px 3px 6px #b5b5b5);
      object-fit: cover;
      border: 3px solid #b5b5b5;
    }

    div:last-child {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      h3,
      span {
        margin-left: 5px;
      }

      h3 {
        color: #fff;
        font-size: 1rem;
        width: 100%;
      }

      span {
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 7rem;
      }
    }
  }
`;

const NavigateStyle = ({ className, children }) => (
  <nav className={className}>
    <ul>{children}</ul>
  </nav>
);

const Navigate = styled(NavigateStyle)`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 1.5rem 0 1rem 1rem;
  border-radius: 8px;
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  @media only screen and (max-width: 62.5em) {
    box-shadow: 6px 0px 10px 4px rgba(#185ee0, 0.15);
  }
`;

const NavItemStyle = ({ children, svg: Svg, ...props }) => (
  <li className={props.className} data-name={props.dataName} onClick={props.onClick}>
    <Svg size="22" color="#fff" />
    <h3>{props.title}</h3>
    {props.arrow ? <span className="arrow"></span> : ''}
    {children}
  </li>
);

const NavigateItem = styled(NavItemStyle)`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  // padding: 1rem 2rem 1rem 1rem;
  padding: 8px;
  cursor: pointer;
  color: #fff;

  svg {
    grid-column: 1;
    max-width: 1.6rem;
    max-height: 1.6rem;
    width: 100%;
    pointer-events: none;

    use {
      pointer-events: none;
    }
  }

  h3 {
    pointer-events: none;
    grid-column: 2;
    text-decoration: none;
    font-size: 0.9rem;
    color: #f1f5f8;
    font-weight: 500;

    @media only screen and (max-width: 50em) {
      font-size: 1.1rem;
    }
  }

  &.actived {
    background-color: #1a389f;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 18px 2px 2px rgba(5, 250, 250, 0.7);
  }

  &.open {
    animation: pureScale 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  .arrow {
    grid-column: 3;
    position: relative;
    display: grid;
    align-items: center;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #f1f5f8;
      width: 0.7rem;
      height: 2px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      left: 0.4rem;
    }
  }

  &.open .arrow::before {
    transform: rotate(-45deg);
  }

  &.open .arrow::after {
    transform: rotate(45deg);
  }
`;

const SubNavigateStyle = ({ className, children }) => <ul className={className}>{children}</ul>;
// #4f5260 | 1a389f
const NavigationSub = styled(SubNavigateStyle)`
  list-style: none;
  grid-column: 1 / -1;
  background-color: #4f5260; 
  border-radius: 6px;
  overflow-y: auto;
  scrollbar-width: none;
  max-height: 0;
  opacity: 0;
  transition: all 0.8s linear;

  ${NavigateItem}.open && {
    max-height: 500px;
    opacity: 1;
    padding: 2px 0;
    margin-top: 5px;
  }
`;

const SubItemStyle = ({ svg: Svg, ...props }) => (
  <li className={props.className} data-link={props.dataLink} onClick={props.onClick}>
    <Svg icon="icon-sub" />
    <Link className={props.linkClass} data-link={props.dataLink} to={props.href}>
      {props.linkText}
    </Link>
  </li>
);

const SubItem = styled(SubItemStyle)`
  display: grid;
  grid-template-columns: 3rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 2px;
  padding: 3px;

  svg {
    grid-column: 1;
    max-width: 1.4rem;
    max-height: 1.4rem;
    width: 100%;
    fill: #fff;
  }

  a {
    grid-column: 2;
    text-decoration: none;
    font-size: 0.8rem;
    color: #fff;

    @media only screen and (max-width: 50em) {
      font-size: 1rem;
    }

    h6 {
      color: #f1f5f8;
    }

    &.active {
      color: #ffd439;
    }
  }
`;
