import React from 'react';
import { Link } from 'react-router-dom';
import { ajax } from '../../api/chequer';
import usePaginate from '../../hooks/usePaginate';

import { Table, Thead, Tbody, Paginate, Flex } from '../../components/Table';

import { FiPrinter, FiEdit2, FiTrash2 } from 'react-icons/fi';
import { MdPictureAsPdf } from 'react-icons/md';
import { HiOutlineCalendar } from 'react-icons/hi';

import { formatDataRegex } from '../../services/utils';
import { GlobalContext } from '../../contexts/global';

// export default function useList(url, limit, query) {
export default function useList() {
  const baseURL = process.env.REACT_APP_API_KEY;
  const { can } = React.useContext(GlobalContext);
  const [users, setUsers] = React.useState([]);
  const [min, setMin] = React.useState(1);
  const [max, setMax] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const { currentPage, cbCurrentPage } = usePaginate();
  const [limitCount, setLimitCount] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [url, setUrl] = React.useState('/pedidos');
  // const [setQuery, setSetQuery] = React.useState([]);
  // const limitCount = Math.ceil(paginate / limit);

  const handlePaginate = React.useCallback(
    (e) => {
      const el = e.target;
      if (el.matches('.prev')) return currentPage >= 2 ? cbCurrentPage(currentPage - 1) : cbCurrentPage(1);
      if (el.matches('.next')) return currentPage <= limitCount - 1 ? cbCurrentPage(currentPage + 1) : cbCurrentPage(limitCount);
      const spanActive = document.querySelector('.content span.active');
      if (spanActive && spanActive.classList.contains('active')) spanActive.classList.remove('active');
      el.classList.add('active');
      cbCurrentPage(parseInt(el.innerText, 10));
    },
    [currentPage, limitCount, cbCurrentPage],
  );

  // const canPlanilha = React.useMemo(() => can(['planilha', 'superadmin']), [can]);
  const canPedidoDestroy = React.useMemo(() => can(['pedido_destroy', 'superadmin']), [can]);

  const memoPaginate = React.useMemo(() => {
    const array = [];
    for (let i = 0; i < limitCount + 1; i++) {
      array.push(i);
    }

    if (currentPage + 1 > max) {
      setMax(max + 4);
      setMin(min + 4);
      return array.splice(min, min).map((item) => (
        <span className={currentPage === item ? 'active' : ''} key={item}>
          {item}
        </span>
      ));
    }

    if (currentPage < min) {
      setMin(min - 4);
      setMax(max - 4);
    }
    return array.splice(min, 5).map((item) => (
      <span className={currentPage === item ? 'active' : ''} key={item}>
        {item}
      </span>
    ));
  }, [limitCount, currentPage, min, max]);

  const TableRender = React.useCallback(
    ({ handlePedidoDestroy }) => {
      return (
        <Table>
          <Thead>
            <tr className="">
              <th scope="col">Pedido</th>
              <th scope="col">Ficha</th>
              <th scope="col">Cliente</th>
              <th scope="col" className="center">
                Tipo
              </th>
              <th scope="col" className="center">
                Lido
              </th>
              <th scope="col" className="center">
                Horário
              </th>
              <th scope="col" className="center">
                Created
              </th>
              <th scope="col">Action</th>
            </tr>
          </Thead>
          <Tbody>
            {users.rows.map((item) => (
              <tr data-id={item.id} key={item.id} className="">
                <td data-label="cliente">
                  <span className="mob">Pedido</span>
                  <p>{item.pedido}</p>
                </td>
                <td data-label="Assunto">
                  <span className="mob">Ficha</span>
                  {item.client_id}
                </td>
                <td data-label="Cliente">
                  <span className="mob">Cliente</span>
                  {item.client_name}
                </td>
                <td data-label="Tipo" className="center">
                  <span className="mob">Tipo</span>
                  <span className="badge" style={{ backgroundColor: '#556677' }}>
                    {item.status}
                  </span>
                </td>
                <td data-label="Lido" className="center">
                  <span className="mob">Lido</span>
                  <span className="badge" style={{ backgroundColor: !item.visualized ? '#556677' : '#5cb85c' }}>
                    {!item.visualized ? 'Não' : 'Sim'}
                  </span>
                </td>
                <td className="center" data-label="Horário">
                  <span className="mob">Horário</span>
                  {item.time}
                </td>
                <td data-label="Cadastrado" className="center">
                  <span className="mob">Criado</span>
                  <Flex className="center xs-left">
                    <HiOutlineCalendar size={22} />
                    {formatDataRegex(item.createdAt)}
                  </Flex>
                </td>
                <td data-label="#" className="nowrap">
                  <span className="mob">Action</span>
                  {/* {canPlanilha && (
                  <Link to={`/planilhas/${item['id']}`} className="action" style={{ backgroundColor: '#3583f6' }}>
                    <FiFileText color="#fff" size={17} />
                  </Link>
                )} */}

                  {canPedidoDestroy && (
                    <Link to="#" data-id={item['id']} className="action" style={{ backgroundColor: 'rgb(242, 78, 70)' }} onClick={handlePedidoDestroy}>
                      <FiTrash2 color="#fff" size={17} />
                    </Link>
                  )}
                  <Link to={`/requests/show/${item['id']}`} className="action" style={{ backgroundColor: '#3583f6' }}>
                    <FiPrinter color="#fff" size={17} />
                  </Link>
                  <Link to={`/requests/${item['id']}/edit`} className="action" style={{ backgroundColor: '#f6a935' }}>
                    <FiEdit2 color="#fff" size={17} />
                  </Link>
                  {item.anexo && (
                    <a
                      href={`${baseURL}/docs/${item.anexo}`}
                      target="_blank"
                      className="action"
                      style={{ backgroundColor: 'rgb(86, 196, 170)' }}
                      rel="noreferrer"
                    >
                      <MdPictureAsPdf color="#fff" size={20} />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </Tbody>
        </Table>
      );
    },
    [users, canPedidoDestroy, baseURL],
  );

  const PaginateRender = React.useCallback(() => {
    return (
      limitCount > 1 && (
        <Paginate onClick={handlePaginate}>
          <div className="first active">
            <span className="firstpage">1</span>
            <span className="prev">Prev</span>
          </div>
          <div className="content">{memoPaginate}</div>
          <div className="last active">
            <span className="next">Next</span>
            <span className="lastpage">{limitCount}</span>
          </div>
        </Paginate>
      )
    );
  }, [handlePaginate, memoPaginate, limitCount]);

  const queryMemo = React.useMemo(() => {
    const localStorageQuery = JSON.parse(localStorage.getItem('@query'));
    return new Promise((resolve) => {
      if (localStorageQuery) {
        let search = '';
        localStorageQuery.forEach((item) => {
          if (item.name === '&limit') {
            setLimit(item.value);
            return;
          }
          search += `${item.name}=${item.value}`;
        });
        setQuery(search);
        resolve(search);
      } else {
        resolve(null);
      }
    });
  }, []);

  const configList = React.useCallback((params) => {
    setUrl(params.url);
    setQuery(params.search);
    setLimit(params.limit);
  }, []);

  const URIload = React.useCallback(async () => {
    let response;

    if (query) {
      response = await ajax({ url: `${url}/?page=${currentPage}&limit=${limit}${query}` });
    } else {
      response = await ajax({ url: `${url}?page=${currentPage}&limit=${limit}}` });
    }

    setUsers(response);
    setLimitCount(Math.ceil(+response.count / limit));
    setLoading(false);
  }, [url, currentPage, limit, query]);

  function reload() {
    URIload();
  }

  React.useEffect(() => {
    async function loadMemo() {
      await queryMemo;
      URIload();
    }
    loadMemo();
  }, [URIload, queryMemo, url, limit, currentPage]);

  return {
    TableRender,
    PaginateRender,
    configList,
    loading,
    reload,
  };
}
