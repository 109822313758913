import React from "react";
import styled from "styled-components";

const TableStyle = ({className, children}) => (
    <table className={className}>
        <>
        {children}
        </>
    </table>
);

const TheadStyle = ({className, children}) => (
    <thead className={className}>
        <>
        {children}
        </>
    </thead>
);

const TbodyStyle = ({children, ...props}) => (
    <tbody className={props.className} onDoubleClick={props.onDoubleClick}>
        <>
        {children}
        </>
    </tbody>
);

const StyleFlex = ({className, children}) => (
    <div className={className}>
        {children}
    </div>
)

const StyleGroup = ({...props}) => (
    <div className={props.className ? `control-group ${props.className}` : "control-group"}>
        <input id={props.id} inputMode={props.inputMode} mask={props.mask} type={props.type} name={props.name} value={props.value} className={props.inputClass}  placeholder=" " onChange={props.onChange}/>                       
        <span className="control-group__highlight"/>
        <span className="control-group__bar"/>
        <label className="control-group__label">{props.text}</label>
    </div> 
)
const Group = styled(StyleGroup)`
position: relative;
display: flex;
flex-direction: column;
margin-bottom: 1.7rem;
justify-content: flex-end;
padding: 0 .5rem;
overflow: unset;

@media only screen and (max-width: 75em) {
    padding: 0 5px;
}


input[type=email],
input[type=password],
input[type=text] {
    background-color: unset;
    font-size: .9rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;     
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (max-width: 37.5em) {
        font-size: 1.2rem;
    }

    &:focus {
        outline: none;
        border: none;
    }
}

label {
    color: #999;
    font-size: 1rem;       
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 7px;
    overflow: unset;    
}

input[type=email]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=text]::-ms-input-placeholder {
    color: transparent;
}

input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=text]::placeholder {
    color: transparent;
}

input[type=email]:not(:placeholder-shown) ~ label,
input[type=password]:not(:placeholder-shown) ~ label,
input[type=text]:not(:placeholder-shown) ~ label {
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;

    @media only screen and (max-width: 37.5em) {
        bottom: 1.6rem;  
        left: 0.6rem;
    }   
}

.control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;
    // top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.15;
}

&:last-child .control-group__highlight {
    // height: calc(100% - 1.7rem);
    @media only screen and (max-width: 37.5em) {
        height: 100%;
    }
}

/* active state */
input[type=email]:focus ~ .control-group__highlight,
input[type=password]:focus ~ .control-group__highlight,
input[type=text]:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
}

.control-group__bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
}

.control-group__bar:before,
.control-group__bar:after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.control-group__bar:before {
    left: 50%;
}
.control-group__bar:after {
    right: 50%;
}

/* active state */
input[type=text]:focus ~ .control-group__bar {
    border-bottom: none;
}

input[type=text]:focus ~ .control-group__bar:before,
input[type=text]:focus ~ .control-group__bar:after {
    width: 50%;
}


`;

const StyleButtonRow = ({...props}) => (
    <div className={`${props.className} ${props.cols}`}>
        <button onClick={props.onClick}>
            <img src={props.src} alt={props.alt}/>
        </button>
    </div>
)
const ButtonRow = styled(StyleButtonRow)`
display:flex;
button {
    max-width: 7rem;
    border: none;
    background-color: transparent;
    transform: translateY(-1rem);
    transition: transform 0.1s ease-in-out; 
    margin-right: 5px;
    &:active {
        transform-origin: center center;
        transform: translateY(-1rem) scale(1.2);
    }
}

&.mini button {
    max-width: 2.5rem;
}
`;

const Table = styled(TableStyle)`
width: 100%;
border-collapse: collapse;
background-color: #fff;
overflow: hidden;      
border-radius: 5px;
// table-layout: fixed;
// border-spacing: 0 5px;
/* margin-bottom: 4rem; */


&.shadow {
    box-shadow: 1px 1px 15px #00000052;
}
`;

const Thead = styled(TheadStyle)`
display: table-header-group;
    @media only screen and (max-width: 37.5em) {
        display: block;
    }

    & tr {
        display: table-row; 
        background-color: #ececec;
        animation-name: moveInRight;
        animation-duration: 1s;
        animation-timing-function: ease-in-out; 
        
        @media only screen and (max-width: 37.5em) {
            display: block; 
            /* border-bottom: 4px solid #c6c6c6; */
        }
        
        &.center{
            & th,
            & td {
                text-align: center; 
            }    
        }

        & th,
        & td {
            text-align: left; 
            &.center {
                text-align: center; 
            } 
        }            
    }

    box-shadow: 1px 1px 15px rgba(rgb(0, 31, 131), 0.2); 
    // box-shadow: 1px 1px 10px rgb(0 0 255 / 20%);

    & th {
        padding: 1rem 1rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.9rem;
        font-weight: 600;
        color: #718096;
        border-bottom: 1px solid #f2f2f2;

        @media only screen and (max-width: 37.5em) {
            display: none; 
            padding: 1rem;
            border-bottom: 1px solid #f2f2f2;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
`;

const Tbody = styled(TbodyStyle)`
display: table-header-group;

    @media only screen and (max-width: 37.5em) {
        display: block;        
    }

    & tr {
        display: table-row; 
        cursor: pointer;
        @media only screen and (max-width: 37.5em) {
            display: block;    
            border: 2px solid #f2f2f2 !important;         
        }
        
        &.center{
            & th,
            & td {
                text-align: center; 
            }    
        }

        & th,
        & td {
            text-align: left;              
            &.center {
                text-align: center; 
            } 
        }            
    }

    & tr {
            &:nth-child(even) {
                background-color: #f8f8f8;
                animation-name: moveInRight;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
            }

            &:nth-child(odd) {                
                animation-name: moveInLeft;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #eee;
                @media only screen and (max-width: 37.5em) {
                    border: none;
                    margin-bottom: 2rem;
                }                       
            }
        }
    & td {
        display: table-cell;
        padding: 1rem;
        color: rgb(128, 128, 128);
        font-size: calc(10px + 0.5vmin);            
        vertical-align: middle;

        /* overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */

        @media only screen and (max-width: 37.5em) {
            font-size: calc(14px + 0.5vmin);            
        }

        /* overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */

        &.center {
            text-align: center;
        }

        &.nowrap {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & .badge {
            background-color: #83bf02;
            color: #fff;
            padding: 5px 1rem;
            border-radius: 5px;
            box-shadow: 1px 1px 10px #00000052;
            font-size: 0.8rem;
            white-space: nowrap;
        }

        & .action {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 0;
            height: 1.7rem;
            width: 1.7rem;
            margin-right: 5px;
            border-radius: 3px;
            box-shadow: 1px 1px 10px #00000052;             
            transition: ease-in 0.1s;

            svg {
                pointer-events: none;
            }

            &:hover {
                transform-origin: center;
                transform: scale(1.05);
            }

            &:active {
                transform: scale(0.9);
            }
        }

        & .mob {
            display: none;
        }

        @media only screen and (max-width: 37.5em) {
            display: block;
            text-align: left !important;
            padding: 5px;
            border-bottom: 1px solid #dfe4fc;

            & .mob {
                display: block;
                margin-bottom: 3px;  
                text-transform: uppercase; 
                color: burlywood;  
                font-size: 0.6rem;               
            }

            &:last-child {
                border-bottom: 0;
            }
            
        }
    }
`;

const Flex = styled(StyleFlex)`
display: flex;
align-items: center;

width: calc(100% / 12 * ${props => props["col"]});
@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {   
    width: calc(100% / 12 * ${props => props["xs"]});

    &.width99 {
        width: 99.7% !important;
    }    
}

&.center {
    justify-content: center;
}

&.wrap {
   flex-wrap: wrap;
}

svg{
    margin-right: 5px;
}

&.shadow {
    padding: 2rem 0 0 0;
    box-shadow: 0 0 10px #436d932e;
    border-radius: 5px;
}

@media only screen and (max-width: 37.5em) {
    &.xs-left {
        justify-content: flex-start;
    }  
}
`;

const Paginate = styled(({children, ...props})=>(
    <div className={props.className} onClick={props.onClick}>
        {children}
    </div>
))`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 1rem;
    color: #fff;
    // box-shadow: -1px -1px 25px #1c3faa33;
    span{
        width: 3rem;
        height: 2.5rem;
        background-color: #f2f2f2;        
        /* border-radius: 50%;  */
        font-size: 0.8rem;  
        font-weight: 500; 
        color: #999;    

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px #1666ff;
        cursor: pointer;
        user-select: none;

        &.active {
            background-color: #1c3faa;
            color: #fff;
        }

        @media only screen and (max-width: 37.5em) {                    
            width: 2.5rem;
            height: 2rem;
        }
    }
    .first {
        display: none;
        align-items: center;       
        &.active {
            display: flex;
        }

        & .firstpage {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .last {
        display: none;
        align-items: center;   

        &.active {
            display: flex;
        }

        & .lastpage {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }       
    }

    .content {
        display: flex;
        align-items: center;
    }
`;

export {Table, Thead, Tbody, Paginate, Flex, ButtonRow, Group}