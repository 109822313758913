import React from "react";
import {Link} from 'react-router-dom';
// import '../stylesheets/pages/header.css';
import ToggleWrap from './Toggle';
import Logo from '../assets/images/gruposbv.png';
import handleToggle from '../services/toggle';

import style from 'styled-components';

export default function Header(){  
    return(
        <header className="root-header">
            <Col1>
                <figure>
                    <Link to="/" onClick={handleToggle}>
                        <img src={Logo} alt="Logo empresa"/>
                    </Link>
                </figure>
            </Col1>
            <Col2>
                <Sub1></Sub1>
                <Sub2>
                    <ToggleWrap state="active"/>
                </Sub2>
            </Col2>
        </header>
    )
}

const Col1 = style.div`
    width: calc(17rem + 8px);
    display: flex;
    align-items: center;
    justify-content: center; 

    @media only screen and (max-width: 37.5em) {  
        width: 100%;      
        justify-content: flex-start;
        padding: 0 1rem;
    }

    figure {
        display: block;
        width: 5rem;

        @media only screen and (max-width: 37.5em) {        
            width: 4rem;
        }
    }
`;

const Col2 = style.div`
    flex: 1;  
    display: flex;
    align-items: center;  
    padding: 0 1rem;
`;

const Sub1 = style.div`
    flex: 1;
    @media only screen and (max-width: 37.5em) {        
        display: none;
    }
`;

const Sub2 = style.div`
   
`;