import React from "react";
import Content from '../../components/Content';
//import {useParams} from 'react-router-dom';

import {Container, Panel, Row, RowTitle, ButtonAction} from '../../components/Form';
import {FiFileText} from 'react-icons/fi';

//import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

import buttonSend from '../../assets/images/buttons/send.button.svg';
//import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize } from "../../api/chequer";

import {Input, Select} from '../../components/FormRef/Form';
import {FormContext} from "../../components/FormRef/FormContext";
import { toast } from "react-toastify";
import Mask from "../../services/mask";

import AuthContext from "../../contexts/auth";
import Moment from "../../services/Moment";


// import { GlobalContext } from "../../contexts/global";

export default function VendaStore() {  
    const {todayAddDay} = Moment();
    const {user} = React.useContext(AuthContext);
    const {fields} = React.useContext(FormContext);    
    const [loading, setLoading] = React.useState(true);    
    const [buttonStatus, setButtonStatus] = React.useState(false);    

    async function handleSubmitForm(e) {
        e.preventDefault();
        setButtonStatus(true);
        // if(fields['imposto'].value === "" || fields['month'].value === "") return toast.warning("Selecione um imposto e o Mês.")
        const params = [
            {name: "user_created", value: user.name.split(" ")[0]},
            {name: "company_id", value: fields['empresa'].value},
            {name: "data_venda", value: fields['data_venda'].value},            
            {name: "price", value: fields['price'].value.replace(".", "").replace(",", ".")},           
        ];

        const body = await serialize(params);
        const response = await ajax({url: "/vendas", method: "post", body, json: true});
        if(response.success && response.message) {
            console.log(response)
            toast.success(response.message);
            setButtonStatus(false);
            resetForm();
            return false;
        }   
        
        setButtonStatus(false);
        if(!response.success && response.message) return toast.warning(response.message);  
    }

    function handleReset(e) {
        e.preventDefault();
    }

    function resetForm(){
        fields["price"].value = '';        
    }
    

    React.useEffect(() => {
        async function loadPedido() {           
            setLoading(false);
           
        }
        loadPedido();
        // if(id && fields.nome !== undefined) {loadPedido()}else{setLoading(false)};  
        if(fields['price'] !== undefined) {
            Mask.money(fields['price']);            
        };       
            
    }, [loading, fields]);

    if(loading) return(<div></div>)

    return(
        <Content>            
            <Container> 
                <form onSubmit={handleSubmitForm}>                    
                    <Panel>       
                        <RowTitle text="Registrar venda"><FiFileText size={25}/></RowTitle> 
                        <Row> 
                            <Select label="Empresa.." col={4} name="empresa">
                                <option value={1}>Chequer Megastore</option>                                
                            </Select> 
                            <Input type="date" col={4} defaultValue={todayAddDay({format: "yyyy/mm/dd"})} text="Dia da venda" name="data_venda"/>                             
                            <Input col={4}  text="Valor da venda.." name="price" inputMode="numeric"/>                            
                        </Row> 
                                            
                        <Row>                    
                            <ButtonAction type="submit" src={buttonSend} alt="button send" disabled={buttonStatus}/>
                            <ButtonAction src={buttonReset} alt="button print" onClick={handleReset}/>                       
                        </Row>
                    </Panel>
                </form>                
            </Container>
        </Content>       
    )
}


