import React from 'react';
import Content from '../../components/Content';
import { useParams } from 'react-router-dom';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormSelect, FormInput } from '../../components/FormRef/Form';

//**Icons */
import buttonSend from '../../assets/images/buttons/button-send.svg';
import { FaProductHunt } from "react-icons/fa";
//import { FiFileText } from 'react-icons/fi'; //FiFileText
import { FormContext } from '../../components/FormRef/FormContext'
// import { GlobalContext } from '../../contexts/global';
import { ajax, serialize } from '../../api/chequer';
import { toast } from 'react-toastify';
import Mask from '../../services/mask';

//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
//const MySwal = withReactContent(Swal);

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent />
    </Content>
  );
}

function ProdileContent() {
  const { id } = useParams();
  const { fields } = React.useContext(FormContext);
  // const { roleScan } = React.useContext(GlobalContext);
  const [stateButton, setStateButton] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  //**Permissões */ 
  // const upProduct = React.useMemo(() => roleScan(['up_products']), [roleScan]);
 
  // function checkOnly() {
  //   if(aDmin) return false;
  //   // if(id && upUser) return false;
  //   return true
  // }

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    setStateButton(true);

    const params = [
      { name: 'codigo', value: fields.codigo.value },
      { name: 'name', value: fields.name.value },
      { name: 'price', value: fields.price.value },
      {name: 'unit_of_measurement', value: fields.unit_of_measurement.value},
      // { name: 'retention', value: fields.retention.value },
      // { name: 'no_retention', value: fields.no_retention.value },
      { name: 'status', value: fields.status.value },
      {name: 'product_category_id', value: fields.product_category_id.value},
    ];

    const body = await serialize(params);
    const response = await ajax({ url: '/product/' + id, method: 'put', body, json: true });      
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message); 
      //MySwal.fire("Você não tem permissão para atualizar usuários!");    

    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, id]);

  React.useEffect(() => {
    if(fields.price !== undefined) {
      Mask.money(fields['price']);
      //Mask.money(fields['retention']);
      //Mask.money(fields['no_retention']);
    }

    async function loadProduct() {
      return new Promise(async (resolve) => {
        const response = await ajax({ url: '/product/' + id });  
        fields.name.value = response.name;
        fields.codigo.value = response.codigo;      
        fields.price.value = Mask.setMoney(response.price);
        fields.unit_of_measurement.value = response.unit_of_measurement;
        // fields.retention.value = Mask.setMoney(response.retention);
        // fields.no_retention.value = Mask.setMoney(response.no_retention);
        fields.status.value = response.status;
        fields.product_category_id.value = response.product_category_id;
        resolve(true);
      })
      
    }
    async function fetchProductCategory() {   
      return new Promise(async (resolve)=> {
        const response = await ajax({ url: `/product/category` });      
        const options = [];
        response.rows.map((item) => options.push({value: item.id, name: item.name}))
        setCategoryOptions(options);
        resolve(true);
      });               
    }
    
    (async function() {
      if (id && fields.name !== undefined) {
        await fetchProductCategory();
        await loadProduct();      
      }
    })();   

  }, [fields, id]);

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}>
        <RowTitle text="ATUALIZAR PRODUTO">
          <FaProductHunt size={25} />
        </RowTitle>
        <Panel>
          <FormInput
            required={true}
            inputMode="numeric"
            text="Código.."
            col={4}
            name="codigo"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Descrição.."
            col={8}
            name="name"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Preço.."
            col={3}
            name="price"
            value="0,00"
            //readOnly={checkOnly()}
          /> 
          <FormInput
            required={true}
            text="Unidade de medida.."
            col={3}
            name="unit_of_measurement"
            //readOnly={checkOnly()}
          />
          <FormSelect
            label="Categoria.."
            options={categoryOptions}
            col={6}
            name="product_category_id"
            //onChange={handleChange}
          />
          <FormSelect
            label="Status.."
            options={[{ name: 'Ativo', value: 'true' }, { name: 'Inativo', value: 'false' },]}
            col={3}
            name="status"
            //onChange={handleChange}
          />           

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />             
          </Row>
          
        </Panel>
      </form>
    </Container>
  );
}